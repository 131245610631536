import { authProvider } from "./authProvider";
import { UserPermissions } from "../interfaces/userPermissions";
import { CanParams, CanReturnType } from "@refinedev/core";


export const accessControlProvider = {
    can: async ({
        action,
        params,
    }: CanParams): Promise<CanReturnType> => {
        if (authProvider.getPermissions) {
            const permissions = await authProvider.getPermissions() as UserPermissions;
            if (!permissions.CrmAccessPermitted) {
                return { can: false };
            }

            if (params?.resource?.meta?.permissions && params?.resource?.meta?.permissions[action]) {
                if (Object.hasOwn(permissions, params?.resource?.meta?.permissions[action])) {
                    return { can: true };
                }
            }

            if (params?.resource?.meta?.permission) {
                if (Object.hasOwn(permissions, params.resource.meta.permission)) {
                    return { can: true };
                }
            }
        }

        return { can: false };
    },
    options: {
        buttons: {
            enableAccessControl: true,
            hideIfUnauthorized: true,
        },
    },
};