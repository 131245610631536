import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import {
    Show,
    TextField,
} from "@refinedev/antd";
import { Col,Row } from "antd";
import { AccountGroup } from "../../interfaces";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { BooleanField } from "../../components/fields";

const AccountGroupInfo: React.FC<{ accountGroup: AccountGroup }> = ({ accountGroup }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'name',
                    label: translate("accountGroup.fields.name"),
                    children: <TextField value={accountGroup.name} />,
                },
                {
                    key: 'description',
                    label: translate("accountGroup.fields.description"),
                    children: <TextField value={accountGroup.description} />,
                },
                {
                    key: 'isDefaultGroup',
                    label: translate("accountGroup.fields.isDefaultGroup"),
                    children: <BooleanField value={accountGroup.isDefaultGroup} />,
                },
                {
                    key: 'hasNBP',
                    label: translate("accountGroup.fields.hasNBP"),
                    children: <BooleanField value={accountGroup.hasNBP} />,
                },
                {
                    key: 'marginCallPolicy',
                    label: translate("accountGroup.fields.marginCallPolicy"),
                    children: <TextField value={accountGroup.marginCallPolicy?.description} />,
                },
                {
                    key: 'token',
                    label: translate("accountGroup.fields.token"),
                    children: <TextField value={accountGroup.token} />,
                },
            ]
        }
    ];
    
    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const AccountGroupShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<AccountGroup>();
    const { data, isLoading } = queryResult;

    const accountGroup = data?.data;

    return (
        <Show isLoading={isLoading}>
            {accountGroup !== undefined && <AccountGroupInfo accountGroup={accountGroup}></AccountGroupInfo>}
        </Show>
    );
};
