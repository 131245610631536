import type { ShowProps } from "@refinedev/antd";
import {
  DeleteButton,
  EditButton,
  ListButton,
  Show as RefinedShow
} from "@refinedev/antd";
import { useResource } from "@refinedev/core";
import React from "react";
import { useRefresh } from "../../hooks/useAutoRefresh";

type ShowPropsExt = ShowProps & {
  extraButtons?: any;
  autorefresh?: boolean;
};

/**
 * `<Show>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/basic-views/show} for more details.
 */
export const Show: React.FC<ShowPropsExt> = (props) => {
  const { resource, action, id: idFromParams, identifier } = useResource(props.resource);

  const id = props.recordItemId ?? idFromParams;
  const hasList = resource?.list || !props.recordItemId;

  const headerButtonsRefresh = useRefresh({ resource: props.resource ? props.resource : identifier, autorefresh: props.autorefresh, id: id });
  
  const isDeleteButtonVisible =
    props.canDelete ?? resource?.meta?.canDelete ?? resource?.canDelete;
  const isEditButtonVisible = props.canEdit ?? resource?.canEdit ?? !!resource?.edit;
  
  const headerButtons = (headerProps: any) => {
    return (
      <>
        {hasList && <ListButton {...headerProps.listButtonProps} />}
        {isEditButtonVisible && <EditButton {...headerProps.editButtonProps} />}
        {isDeleteButtonVisible && <DeleteButton {...headerProps.deleteButtonProps} />}
        {props.extraButtons ? props.extraButtons(headerProps) : null}
        {headerButtonsRefresh(props)}
      </>
    )
  }

  return <RefinedShow {...props} headerButtons={headerButtons} />
};
