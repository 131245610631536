
import { Link } from "react-router-dom";
import { Account } from "../../interfaces";
import { IdentityField } from "./IdentityField";


type AccountFieldProps = {
    value?: Account;
    link?: string;
    showCurrency?: boolean;
    showIdentity?: boolean;
}

export const AccountField: React.FC<AccountFieldProps> = ({ value, link, showCurrency, showIdentity }) => {
    if (!value) {
        return <></>
    }
    if (value.identity && showIdentity === undefined || showIdentity === true) {
        return <IdentityField value={value.identity} />
    }

    if (!link) {
        link = 'details';
    }
    if (showCurrency === undefined) {
        showCurrency = false;
    }

    return (
        <Link to={`/account/show/${value.id}/${link}`} className="inline-flex">{showCurrency && <span>{value?.denominatedInAssetId}:</span>}<span>{value?.token}</span></Link>
    );
};
