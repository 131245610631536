import { useState } from "react";

import {
    useApiUrl,
    useCustom
} from "@refinedev/core";
import { Spin } from "antd";
import React from "react";
import { AggregationType, ExposureReport, ExposureType } from "../../interfaces";
import ExposurePane from "./exposure/exposurePane";
import ExposureHistoryPane from "./exposure/exposureHistoryPane";


export const DashboardPage: React.FC = () => {
    const [assetType, setAssetType] = useState<AggregationType>(AggregationType.Asset);
    const [activeStat, setActiveStat] = useState<ExposureType>(ExposureType.TotalValue);

    const apiUrl = useApiUrl();

    const { data: exposureData, isLoading } = useCustom<ExposureReport>({
        url: `${apiUrl}/exposure/by${assetType}`,
        method: 'get'
    });

    return (
        <Spin spinning={isLoading}>
            {exposureData?.data && <>
                <ExposurePane activeStat={activeStat} setActiveStat={setActiveStat} isLoading={isLoading} assetType={assetType} setAssetType={setAssetType} data={exposureData.data!} denominatedInAssetId={exposureData?.data?.denominatedInAssetId}></ExposurePane>
                <ExposureHistoryPane activeStat={activeStat} assetType={assetType} displayDecimals={exposureData?.data?.exposureDecimals} denominatedInAssetId={exposureData?.data?.denominatedInAssetId} />
            </>
            }
        </Spin>
    )
};
