import { InfoCircleOutlined } from "@ant-design/icons";
import {
    DeleteButton,
    EditButton,
    SaveButton,
    TextField,
    useModalForm
} from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useList,
    useTranslate
} from "@refinedev/core";
import { Button, Form, Input, Modal, Popover, Select, Space, Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import { List } from "../../components/crud/list";
import { NumberField } from "../../components/fields";
import { AccountAssetClassOverride, AccountData, AccountGroup, AccountGroupAssetClassOverride, AccountGroupPairOverride, AccountPairOverride, AssetClass, FeeChargeTiming, Override, Pair } from "../../interfaces";
import { dataToFilter, getEnumFilter, getEnumOptions, getFixedLengthNumber } from "../../utils";
import { OverrideForm } from "../overrides/form";
import { OverrideShowInternal } from "../overrides/show";
import { FeeOverride, OverrideType, renderContext, useFeeProps } from "./useFeeProps";

const FeeListItemInfo: React.FC<{ item: Override, maxFeeIntegerValueLength: number }> = ({ item, maxFeeIntegerValueLength }) => {
    const translate = useTranslate();
    if (!item) {
        return <></>
    }
    return (
        <table className="internal-table">
            <colgroup>
                <col width="90px" />
                <col width="90px" />
                <col width="90px" />
                <col width="90px" />
                <col width="110px" />
                <col width="110px" />
            </colgroup>
            <tbody>
                <tr>
                    <th>{translate("override.fields.sell")}</th>
                    <td><NumberField value={item.orderFeeFloatingSell} /></td>
                    <td><pre className="formatted-cell">{getFixedLengthNumber(item.orderFeeFixedSell, maxFeeIntegerValueLength)}</pre></td>
                    <td><NumberField value={item.marginRatioLong} digits={2} /></td>
                    <td><NumberField value={item.quoteSpreadAffectBid * 100} digits={2} /></td>
                    <td rowSpan={2}>{translate(`override.chargeTiming.${item.chargeTiming}`)}</td>
                </tr>
                <tr>
                    <th>{translate("override.fields.buy")}</th>
                    <td><NumberField value={item.orderFeeFloatingBuy} /></td>
                    <td><pre className="formatted-cell">{getFixedLengthNumber(item.orderFeeFixedBuy, maxFeeIntegerValueLength)}</pre></td>
                    <td><NumberField value={item.marginRatioShort} digits={2} /></td>
                    <td><NumberField value={item.quoteSpreadAffectAsk * 100} digits={2} /></td>
                </tr>
            </tbody>
        </table>
    )
}

let maxFeeIntegerValueLength = 0;

export const FeeList: React.FC<IResourceComponentsProps> = React.memo(() => {
    const translate = useTranslate();

    const {
        createButtonProps,
        form,
        isEditing,
        renderAssetData,
        changeOverrideType,
        renderUsersData,
        saveButtonProps,
        cancelEditClick,
        editButtonClick,
        deleteButtonProps,
        data,
        setData,
    } = useFeeProps();

    const meta = { query: { overrideFeeId: '_ISNOTNULL' } };

    const { data: overrideData, isLoading: isOverrideLoading, isError: isOverrideError } = useList<Override>({ resource: "override", pagination: { mode: 'off' } });

    const { data: accountPairOverrideData, isLoading: isAccountPairOverrideLoading, isError: isAccountPairOverrideError } = useList<AccountPairOverride>({ resource: "accountPairOverride", pagination: { mode: 'off' } });
    const { data: accountAssetClassOverrideData, isLoading: isAccountAssetClassOverrideLoading, isError: isAccountAssetClassOverrideError } = useList<AccountAssetClassOverride>({ resource: "accountAssetClassOverride", pagination: { mode: 'off' } });
    const { data: accountGroupAssetClassOverrideData, isLoading: isAccountGroupAssetClassOverrideLoading, isError: isAccountGroupAssetClassOverrideError } = useList<AccountGroupAssetClassOverride>({ resource: "accountGroupAssetClassOverride", pagination: { mode: 'off' } });
    const { data: accountGroupPairOverrideData, isLoading: isAccountGroupPairOverrideLoading, isError: isAccountGroupPairOverrideError } = useList<AccountGroupPairOverride>({ resource: "accountGroupPairOverride", pagination: { mode: 'off' } });

    const { data: accountData, isLoading: isAccountLoading, isError: isAccountError } = useList<AccountData>({ resource: "account", pagination: { mode: 'off' } });
    const { data: pairData, isLoading: isPairLoading, isError: isPairError } = useList<Pair>({ resource: "pair", pagination: { mode: 'off' }, meta });
    const { data: assetClassData, isLoading: isAssetClassLoading, isError: isAssetClassError } = useList<AssetClass>({ resource: "assetClass", pagination: { mode: 'off' } });
    const { data: accountGroupData, isLoading: isAccountGroupLoading, isError: isAccountGroupError } = useList<AccountGroup>({ resource: "accountGroup", pagination: { mode: 'off' } });

    const [allDataLoading, setAllDataLoading] = useState(true);

    const isLoading = isOverrideLoading || isAccountPairOverrideLoading ||
        isAccountAssetClassOverrideLoading || isAccountGroupAssetClassOverrideLoading ||
        isAccountGroupPairOverrideLoading || isAccountLoading ||
        isPairLoading || isAssetClassLoading || allDataLoading || isAccountGroupLoading;

    const feesMap: Map<number, Override> = new Map<number, Override>();
    overrideData?.data.forEach((o: Override) => {
        feesMap.set(o.id, o);
    })
    const allData = {
        [OverrideType.AccountPair]: accountPairOverrideData,
        [OverrideType.AccountAssetClass]: accountAssetClassOverrideData,
        [OverrideType.AccountGroupAssetClass]: accountGroupAssetClassOverrideData,
        [OverrideType.AccountGroupPair]: accountGroupPairOverrideData,
        [OverrideType.Account]: accountData,
        [OverrideType.Pair]: pairData,
        [OverrideType.AssetClass]: assetClassData,
    }
    const chargeTimeFilter = getEnumFilter(FeeChargeTiming, 'FeeChargeTiming', translate, [FeeChargeTiming.Unknown]);

    const assetFilter =
        [
            {
                text: translate('enums.OverrideType.AssetClass'),
                value: OverrideType.AssetClass,
                children: dataToFilter(assetClassData?.data, OverrideType.AssetClass)
            },
            {
                text: translate('enums.OverrideType.Pair'),
                value: OverrideType.Pair,
                children: dataToFilter(pairData?.data, OverrideType.Pair, 'id', 'id')
            },
        ];


    const accountFilter =
        [
            {
                text: translate('account.accounts'),
                value: OverrideType.Account,
                children: dataToFilter(accountData?.data, OverrideType.Account, 'id', 'displayName')
            },
            {
                text: translate('accountGroup.accountGroups'),
                value: 'AccountGroup',
                children: dataToFilter(accountGroupData?.data, 'AccountGroup', 'id', 'name')
            },
        ];


    useEffect(() => {
        // Aggreagate all the possible overrides.
        const dataSource: FeeOverride[] = [];
        Object.keys(allData).forEach((key) => {
            const data = allData[key as keyof typeof allData];
            data?.data.forEach((o: any) => {
                if (o.overrideFeeId) {
                    const fee = feesMap.get(o.overrideFeeId)!;
                    if (fee) {
                        dataSource.push(new FeeOverride({
                            overrideFee: fee,
                            overrideFeeId: fee.id,
                            type: key as OverrideType,
                            data: o
                        }));
                    }
                }
            });
        });
        overrideData?.data?.map((item: Override) => {
            if (Math.round(item.orderFeeFixedBuy).toString().length > maxFeeIntegerValueLength) {
                maxFeeIntegerValueLength = Math.round(item.orderFeeFixedBuy).toString().length;
            }
            if (Math.round(item.orderFeeFixedSell).toString().length > maxFeeIntegerValueLength) {
                maxFeeIntegerValueLength = Math.round(item.orderFeeFixedSell).toString().length;
            }
        })

        renderContext.init(overrideData?.data, accountData?.data, pairData?.data, accountGroupData?.data, translate);

        setData(dataSource);
        setAllDataLoading(false);
    }, [...Object.values(allData), overrideData]);

    const overrideTypeOptions = getEnumOptions(OverrideType, 'OverrideType', translate, [OverrideType.Unknown]);
    const overridesOptions = overrideData?.data.map((item: Override) => { return { value: item.id, label: item.description } });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        formLoading,
    } = useModalForm<Override>({
        resource: "override",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });

    const changeFee = (value: string, record: FeeOverride) => {
        form.setFieldsValue({
            'overrideFeeId': value,
        });
        record.updated = new Date();
    }

    const shouldCellUpdateRecord = (index: number) => {
        const func = (record: FeeOverride, prevRecord: FeeOverride) => {
            if (record.edit) {
                return true;
            }
            if (record.cache.length && record.cache.some(item => item)) {
                record.cache[index] = false;
                return true;
            }
            return record !== prevRecord;
        }

        return func;
    }

    const [search, setSearch] = useState('');
    const handleSearch = (value: string) => {
        setSearch(value);
    }

    const [filteredData, setFilterData] = useState<FeeOverride[]>(data);

    useEffect(() => {
        if (!search) {
            setFilterData([...data]);
            return;
        }
        const lSearch = search.toLowerCase();

        setFilterData(data.filter(item => {
            return item.search(lSearch) || item.overrideFee.description?.toLowerCase().includes(lSearch);
        }));
    }, [data, search]);

    const onRow = (record: FeeOverride, rowIndex?: number) => {
        return {
            onClick: (event: any) => {
                if (isEditing(record.id)) {
                    return;
                }
                const target = (event.target as HTMLElement);
                const isLink = target.tagName === "A" || target.closest("a") || target.tagName === "BUTTON" || target.closest("button");
                if (!isLink) {
                    editButtonClick(record);
                }
            },
        };
    }

    return (
        <>
            <List createButtonProps={createButtonProps()} resource="fee">
                <Form form={form} component={false}>
                    <Input type="text" placeholder={translate("search")} name="search" value={search} onChange={(event) => handleSearch(event.target.value)} />
                    <Table<FeeOverride>
                        dataSource={filteredData}
                        rowKey="id"
                        pagination={false}
                        loading={isLoading}
                        size="small"
                        onRow={onRow}
                        rowClassName={(record: FeeOverride, index: number) => `clickable-row default-action-edit`}
                    >
                        <Table.Column<FeeOverride>
                            dataIndex={["type"]}
                            width={200}
                            sorter={(a: FeeOverride, b: FeeOverride, sortOrder?: SortOrder) => a.type > b.type ? 1 : -1}
                            shouldCellUpdate={shouldCellUpdateRecord(0)}
                            title={translate("fee.fields.type")}
                            onFilter={(value: boolean | React.Key, record: FeeOverride) => record.type == value || record.id == "new"}
                            filters={overrideTypeOptions.map((item) => { return { text: item.label, value: item.value } })}
                            render={(value: OverrideType, record: FeeOverride) => {
                                if (isEditing(record.id)) {
                                    return (
                                        <Form.Item name="type" style={{ margin: 0 }} rules={[{ required: true, },]} >
                                            <Select size="small" options={overrideTypeOptions} onChange={(value) => changeOverrideType(value, record)} />
                                        </Form.Item>
                                    );
                                }
                                return <TextField value={translate(`enums.OverrideType.${value}`)} />;
                            }}
                        />
                        <Table.Column<FeeOverride>
                            width={200}
                            shouldCellUpdate={shouldCellUpdateRecord(1)}
                            title={translate("fee.fields.assets")}
                            filters={assetFilter}
                            filterMode={"tree"}
                            filterSearch={true}
                            onFilter={(value: boolean | React.Key, record: FeeOverride) => FeeOverride.assetFilter(record.type, record, value as string) || record.id == "new"}
                            render={(_: any, record: FeeOverride) => renderAssetData(record, renderContext)}
                        />
                        <Table.Column<FeeOverride>
                            width={300}
                            shouldCellUpdate={shouldCellUpdateRecord(2)}
                            title={translate("fee.fields.users")}
                            filters={accountFilter}
                            filterMode={"tree"}
                            filterSearch={true}
                            onFilter={(value: boolean | React.Key, record: FeeOverride) => FeeOverride.accountFilter(record.type, record, value as string) || record.id == "new"}
                            render={(_: string, record: FeeOverride) => renderUsersData(record, renderContext)}
                        />
                        <Table.Column<FeeOverride>
                            width={350}
                            dataIndex={["overrideFee"]}
                            title={translate("fee.fields.overrideFee")}
                            shouldCellUpdate={shouldCellUpdateRecord(3)}
                            render={(value: Override, record: FeeOverride) => {
                                if (isEditing(record.id)) {
                                    return (
                                        <Form.Item name="overrideFeeId" style={{ margin: 0 }} rules={[{ required: true, },]} >
                                            <Select size="small" options={overridesOptions} onChange={(value) => changeFee(value, record)} />
                                        </Form.Item>
                                    );
                                }
                                if (!value) {
                                    return <></>
                                }
                                const content = <OverrideShowInternal record={value} />
                                return <Space>
                                    <Popover content={content} placement="rightBottom" style={{ width: 100 }}>
                                        <InfoCircleOutlined />
                                    </Popover>
                                    {value.description}
                                    <EditButton className="hover-action"
                                        resource="override"
                                        recordItemId={record.overrideFeeId}
                                        hideText
                                        size="small"
                                        onClick={() => {
                                            editModalShow(value.id);
                                        }}
                                    />
                                </Space>
                            }}
                        />
                        <Table.Column<FeeOverride>
                            className="no-v-padding"
                            width={470}
                            title={<table>
                                <colgroup>
                                    <col width="90px" />
                                    <col width="90px" />
                                    <col width="90px" />
                                    <col width="90px" />
                                    <col width="90px" />
                                </colgroup>
                                <thead>
                                    {/* <tr>
                                        <th colSpan={5}>{translate("fee.fields.overrideFeeData")}</th>
                                    </tr> */}
                                    <tr>
                                        <td>&nbsp;</td>
                                        <th className="text-left">{translate("override.fields.orderFeeFloating")}</th>
                                        <th className="text-left">{translate("override.fields.orderFeeFixed")}</th>
                                        <th className="text-left">{translate("override.fields.marginRatio")}</th>
                                        <th className="text-left">{translate("override.fields.sreadAffect")}</th>
                                        <th className="text-left">{translate("override.fields.chargeTiming")}</th>
                                    </tr>
                                </thead>
                            </table>}
                            shouldCellUpdate={shouldCellUpdateRecord(4)}
                            onFilter={(value: boolean | React.Key, record: FeeOverride) => record.overrideFee.chargeTiming == value || record.id == "new"}
                            filters={chargeTimeFilter}
                            render={(_: unknown, record: FeeOverride) => {
                                if (isEditing(record.id)) {
                                    const override = renderContext.getOverride(form.getFieldValue("overrideFeeId"));
                                    return override ? <FeeListItemInfo item={override} maxFeeIntegerValueLength={maxFeeIntegerValueLength} /> : <></>
                                }
                                if (!record) {
                                    return <></>
                                }
                                return <FeeListItemInfo item={record.overrideFee} maxFeeIntegerValueLength={maxFeeIntegerValueLength} />
                            }}
                        />

                        <Table.Column<FeeOverride>
                            shouldCellUpdate={shouldCellUpdateRecord(5)}
                            width={150}
                            align="right"
                            render={(_: unknown, record: FeeOverride) => {
                                if (isEditing(record.id)) {
                                    return (
                                        <Space>
                                            <SaveButton
                                                {...saveButtonProps(record)}
                                                size="small"
                                            />
                                            <Button
                                                onClick={() => cancelEditClick(record)}
                                                size="small"
                                            >
                                                Cancel
                                            </Button>
                                        </Space>
                                    );
                                }
                                return (
                                    <Space>
                                        <EditButton
                                            onClick={() => editButtonClick(record)}
                                            hideText
                                            accessControl={{ enabled: false }}
                                            size="small"
                                        />
                                        <DeleteButton
                                            {...deleteButtonProps(record)}
                                            hideText
                                            accessControl={{ enabled: false }}
                                            size="small"
                                        />
                                    </Space>
                                );
                            }}
                        />
                    </Table>
                </Form>
            </List>
            <Modal {...editModalProps}>
                <OverrideForm formProps={editFormProps} />
            </Modal>
        </>
    );
});
