import { Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


export const useMonteCarloOptions = (): [JSX.Element, number, number] => {
    const { t } = useTranslation();
    const numberOfPathsOptions = [1000, 5000, 10000, 25000, 50000, 1000000].map(item => ({ value: item, label: item.toString() }));
    const horizonOptions = [1, 2, 3, 5, 7, 10, 30].map(item => ({ value: item, label: item.toString() }));

    const [numberOfPaths, setNumberOfPaths] = useState<number>(10000);
    const [horizon, setHorizon] = useState<number>(1);

    const component = <>
        <div>
            <label htmlFor="numberOfPaths">{t('var.fields.numberOfPaths')}</label>
            <Select
                style={{ width: '100%' }}
                options={numberOfPathsOptions}
                value={numberOfPaths}
                onChange={setNumberOfPaths}
            />
        </div>
        <div>
            <label htmlFor="horizon">{t('var.fields.horizon')}</label>
            <Select
                style={{ width: '100%' }}
                options={horizonOptions}
                value={horizon}
                onChange={setHorizon}
            />
        </div>
    </>

    return [component, numberOfPaths, horizon];
};