import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Show, NumberField, TextField, DateField } from "@refinedev/antd";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { Lending } from "../../interfaces";
import { EnumField } from "../../components/fields/EnumField";
import dayjs from "dayjs";
import { formatNumberTrim } from "../../utils";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";


export const LendingShowInternal: React.FC<{ record: Lending }> = ({ record }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'assetId',
                    label: translate("lending.fields.asset"),
                    children: <TextField value={record?.assetId} />,
                },
                {
                    key: 'effectiveFrom',
                    label: translate("lending.fields.effectiveFrom"),
                    children: <DateField value={dayjs.unix(record.effectiveFrom)} />,
                },
                {
                    key: 'lendingType',
                    label: translate("lending.fields.lendingType"),
                    children: <EnumField enum="LendingType" value={record.lendingType} translate={translate} />,
                },
            ]
        }
    ];

    const groupTwo = [
        {
            items: [
                {
                    key: 'basicRate',
                    label: translate("lending.fields.basicRate"),
                    children: <NumberField value={record.basicRate} />,
                },
                
                {
                    key: 'fromAmount1',
                    label: translate("lending.fields.fromAmount", {amount: formatNumberTrim(record.fromAmount1)}),
                    children: <NumberField value={record.rate1} />,
                },
        
                {
                    key: 'fromAmount2',
                    label: translate("lending.fields.fromAmount", {amount: formatNumberTrim(record.fromAmount2)}),
                    children: <NumberField value={record.rate2} />,
                },
                {
                    key: 'fromAmount',
                    label: translate("lending.fields.fromAmount", {amount: formatNumberTrim(record.fromAmount3)}),
                    children: <NumberField value={record.rate3} />,
                },
            ]
        }
    ];


    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const LendingShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { assetId, lendingId } = useParams();

    const { queryResult } = useShow<Lending>({id: lendingId});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>            
            {record && <LendingShowInternal record={record} />}
        </Show>
    );
};
