import {
    EditButton, getDefaultSortOrder, ShowButton, useTable
} from "@refinedev/antd";
import {
    BaseRecord, getDefaultFilter, IResourceComponentsProps,
    LogicalFilter, useGetToPath,
    useOne,
    useResource,
    useTranslate
} from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { List } from "../../components/crud/list";
import { useExposureColumns } from "../../components/exposureLimits";
import { BooleanField, NumberField } from "../../components/fields";
import { LogoAssetClassField, LogoAssetField } from "../../components/fields/Logo";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { Asset, AssetClassesEnum, LendingDTO } from "../../interfaces";
import { getBooleanTableFilter, getEnumFilter } from "../../utils";
import { LendingListTableEmbeded } from "../lendings";

const LendingListItemInfo: React.FC<{ asset: Asset, items: LendingDTO[] }> = ({ asset, items }) => {
    const getToPath = useGetToPath();
    const { select: resourceSelect } = useResource();
    const { resource } = resourceSelect('lending');
    if (!items.length) {
        return <></>
    }

    const newTo = getToPath({
        resource,
        action: 'list',
        meta: {
            id: asset.id,
        },
    });
    return <Link to={newTo as string} className="hover-border"><LendingListTableEmbeded items={items} withHeader={false} /> </Link>

    // const content = <LendingListTableEmbeded items={items} withHeader={true} />
    // return (
    //     <Popover content={content} placement="bottom">
    //         <div>
    //             <Link to={newTo as string}><LendingListTableEmbeded items={items} withHeader={false} /> </Link>
    //         </div>
    //     </Popover>
    // )
}

export const AssetList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { search, listProps } = useSearchList();

    const query: Record<string, string> = {};
    if (search) {
        query.nameLIKE = search;
        query.descriptionLIKE = search;
        query.symbolLIKE = search;
    }

    const { tableProps, filters, sorters } = useTable<Asset>({
        syncWithLocation: true,
        filters: {
            initial: [{
                field: 'assetClassIdBITWISEANDANY',
                operator: "in",
                value: [AssetClassesEnum.FX.toString()],
            } as LogicalFilter]
        },
        sorters: {
            initial: [
                {
                    field: "id",
                    order: "asc",
                },
            ],
        },
        initialPageSize: defaultPageSize,
        meta: { query }
    });
    const yesNo = getBooleanTableFilter(translate);
    const assetClassFilter = getEnumFilter(AssetClassesEnum, 'AssetClassesEnum', translate, [AssetClassesEnum.Unknown]);

    const { data: lendingData, isLoading: lendingIsLoading } = useOne({
        resource: "activelendings",
        meta: {
            endpoint: 'specialized/activelendings?' + tableProps?.dataSource?.map((item) => 'assetId=' + item?.id).join('&')
        },
        queryOptions: {
            enabled: !!tableProps?.dataSource,
        },
    });

    const limitColumns = useExposureColumns();
    const clickTableProps = useTableClick({ resource: 'asset' });

    return (
        <List {...listProps}>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex="id"
                    width={100}
                    title={translate("asset.fields.name")}
                    sorter={true}
                    defaultSortOrder={getDefaultSortOrder("id", sorters)}
                    render={(value: string, record: Asset) => <LogoAssetField assetId={value} name={record.name} />}
                />

                <Table.Column
                    dataIndex="symbol"
                    title={translate("asset.fields.symbol")}
                    sorter={true}
                />
                <Table.Column
                    dataIndex="assetClassIdBITWISEANDANY"
                    title={translate("asset.fields.assetClass")}
                    filters={assetClassFilter}
                    defaultFilteredValue={getDefaultFilter("assetClassIdBITWISEANDANY", filters, "in")}
                    sorter={true}
                    render={(value: unknown, record: Asset) => <LogoAssetClassField token={record.assetClassId} />}
                />
                {/* <Table.Column
                    dataIndex="altName"
                    title={translate("asset.fields.altName")}
                /> */}

                <Table.Column
                    className="ellipsis"
                    dataIndex="description"
                    title={translate("asset.fields.description")}
                    render={(value: string) => <span>{value}</span>}
                />
                <Table.Column
                    dataIndex="decimals"
                    title={translate("asset.fields.decimals")}
                    render={(value: any) => <NumberField value={value} />}
                    align="right"
                />
                <Table.Column
                    dataIndex="displayDecimals"
                    title={translate("asset.fields.displayDecimals")}
                    render={(value: any) => <NumberField value={value} />}
                    align="right"
                />
                <Table.Column
                    dataIndex="hedgePosition"
                    title={translate("asset.fields.hedgePosition")}
                    render={(value: any) => <NumberField value={value} />}
                    align="right"
                />
                <Table.Column
                    dataIndex={["enabled"]}
                    title={translate("asset.fields.enabled")}
                    render={(value: any) => <BooleanField value={value} />}
                    filters={yesNo}
                    sorter={true}
                />
                {limitColumns}

                <Table.Column
                    dataIndex={["id"]}
                    width={600}
                    title={<table className="">
                        <colgroup>
                            <col width="100px" />
                            <col width="70px" />
                            <col width="70px" />
                            <col width="70px" />
                            <col width="70px" />
                            <col width="70px" />
                            <col />
                        </colgroup>
                        <thead>
                            <tr><th colSpan={6} align="center">{translate('asset.fields.lendings')}</th></tr>
                            <tr>
                                <th>{translate('asset.fields.lendingsType')}</th>
                                <th className="text-right">{translate('asset.fields.lendingsEffective')}</th>
                                <th className="text-right">{translate('asset.fields.lendingsBasic')}</th>
                                <th className="text-right">{translate('asset.fields.lendingsThreshhold', { num: 1 })}</th>
                                <th className="text-right">{translate('asset.fields.lendingsThreshhold', { num: 2 })}</th>
                                <th className="text-right">{translate('asset.fields.lendingsThreshhold', { num: 3 })}</th>
                            </tr>
                        </thead>
                    </table>}
                    render={(value: any, record: Asset) => <LendingListItemInfo asset={record} items={lendingData?.data[value] || []} />}
                />
                <Table.Column
                    dataIndex="actions"
                    align="right"
                    width={100}
                    render={(_: any, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                meta={{ tab: 'details' }}
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};