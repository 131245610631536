import { useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Edit } from "../../components/crud/edit";
import { useTitleData } from "../../contexts/title";
import { Position } from "../../interfaces";
import { PositionForm } from "./form";

export const PositionEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm<Position>();
    useTitleData({ ...formProps.initialValues });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <PositionForm formProps={formProps} />
        </Edit>
    );
};
