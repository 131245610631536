import {
    DateField,
    DeleteButton,
    EditButton,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import {
    BaseRecord,
    IResourceComponentsProps,
    getDefaultFilter,
    useGo,
    useMany,
    useOne,
    useTranslate
} from "@refinedev/core";
import { Space, Table } from "antd";
import { ColumnFilterItem, FilterDropdownProps } from "antd/lib/table/interface";
import dayjs from "dayjs";
import type * as React from 'react';
import { useParams } from "react-router-dom";
import { FilterDateDropdown } from "../../components/FilterDateDropdown";
import { List } from "../../components/crud/list";
import { LogoAssetField, NumberField } from "../../components/fields";
import { EnumField } from "../../components/fields/EnumField";
import { defaultPageSize } from "../../constants";
import { useTableClick } from "../../hooks/useTableClick";
import { Lending, LendingDTO, LendingType } from "../../interfaces";
import { formatNumberFix, formatNumberTrim, getEnumFilter } from "../../utils";


export const LendingListTableEmbeded: React.FC<{ items: LendingDTO[], withHeader: boolean }> = ({ items, withHeader }) => {
    const translate = useTranslate();
    const digits = 2;
    const amount1 = formatNumberTrim(items[0].fromAmount1);
    const amount2 = formatNumberTrim(items[0].fromAmount2);
    const amount3 = formatNumberTrim(items[0].fromAmount3);

    return (
        <div className="ant-table-container">
            <div className="ant-table-content">
                <table className="internal-table" width={900}>
                    <colgroup>
                        <col width="100px" />
                        <col width="70px" />
                        <col width="70px" />
                        <col width="70px" />
                        <col width="70px" />
                        <col width="70px" />
                    </colgroup>
                    {withHeader && <thead className="ant-table-thead">
                        <tr>
                            <th className="ant-table-cell" align="left">{translate("lending.fields.lendingType")}</th>
                            <th className="text-right ant-table-cell">{translate("lending.fields.effectiveFrom")}</th>
                            <th className="text-right ant-table-cell">{translate("lending.fields.basicRate")}</th>
                            <th className="text-right ant-table-cell">{translate("lending.fields.fromAmount", { amount: amount1 })}</th>
                            <th className="text-right ant-table-cell">{translate("lending.fields.fromAmount", { amount: amount2 })}</th>
                            <th className="text-right ant-table-cell">{translate("lending.fields.fromAmount", { amount: amount3 })}</th>
                        </tr>
                    </thead>}
                    <tbody className="ant-table-tbody">
                        {items.map((item, index) =>
                            <tr key={index} className="ant-table-row">
                                <td className="ant-table-cell"><EnumField value={item.lendingType} enum="LendingType" translate={translate} /> </td>
                                <td className="ant-table-cell text-right text-number">{dayjs.unix(item.effectiveFrom).format('L')}</td>
                                <td className="ant-table-cell text-right text-number">{formatNumberFix(item.basicRate * 100, digits)} %</td>
                                <td className="ant-table-cell text-right text-number">{formatNumberFix(item.rate1 * 100, digits)} %</td>
                                <td className="ant-table-cell text-right text-number">{formatNumberFix(item.rate2 * 100, digits)} %</td>
                                <td className="ant-table-cell text-right text-number">{formatNumberFix(item.rate3 * 100, digits)} %</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export const LendingTable: React.FC<IResourceComponentsProps & { id: string, effectiveFrom: number }> = ({ id, effectiveFrom }) => {
    const translate = useTranslate();
    const { tableProps, filters } = useTable({
        resource: "lending",
        meta: {
            query: {
                'assetId': id,
            }
        },
        filters: {
            initial: [
                {
                    field: 'effectiveFrom',
                    value: effectiveFrom,
                    operator: 'gte',
                }
            ]
        },
        syncWithLocation: true,
        pagination: {
            pageSize: defaultPageSize,
        }
    });

    const effectiveDefaultFilter = getDefaultFilter("effectiveFrom", filters, "gte")

    const { data: assetData, isLoading: assetIsLoading } =
        useMany({
            resource: "asset",
            ids: []
        });

    const lendingTypeFilter = getEnumFilter(LendingType, 'LendingType', translate, ["Unknown"]);
    const go = useGo();
    const headerProps = {
        onBack: () => {
            go({
                to: {
                    resource: "asset",
                    action: "list",
                }
            });
        }
    };

    const getRowClassName = (record: LendingDTO): string => {
        return 'clickable-row default-action-edit ' + (record.effectiveFrom >= effectiveFrom ? 'future' : 'past');
    }

    const getRecordId = (record: LendingDTO): string => {
        return [record.assetId, record.lendingType, record.effectiveFrom].join(',')
    }

    const clickTableProps = useTableClick({ resource: 'lending', meta: { assetId: id } });

    return (
        <List
            createButtonProps={{ meta: { assetId: id } }}
            headerProps={headerProps}
        >
            <Table
                {...tableProps}
                rowKey={(record: BaseRecord) => getRecordId(record as never as LendingDTO)}
                {...clickTableProps}
                rowClassName={(record: BaseRecord) => getRowClassName(record as never as LendingDTO)}
            >
                <Table.Column
                    dataIndex={["assetId"]}
                    title={translate("lending.fields.asset")}
                    sorter={true}
                    filterSearch={true}
                    filters={assetData?.data?.map((item) => { return { text: item.name, value: item.id } as ColumnFilterItem })}
                    render={(value: string, record: LendingDTO) => <LogoAssetField assetId={value} name={record.assetId} />}
                />
                <Table.Column
                    dataIndex={["effectiveFrom"]}
                    className="date"
                    title={translate("lending.fields.effectiveFrom")}
                    sorter={true}
                    key={"effectiveFrom"}
                    filtered={!!effectiveDefaultFilter}
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDateDropdown
                            mapValue={(value) => value ? dayjs.isDayjs(value) ? value : dayjs.unix(value as never) : null}
                            {...props}
                        />
                    )}
                    defaultFilteredValue={effectiveDefaultFilter}
                    render={(value: number) => <DateField value={dayjs.unix(value)} />}
                />
                <Table.Column
                    dataIndex="lendingType"
                    sorter={true}
                    title={translate("lending.fields.lendingType")}
                    filters={lendingTypeFilter}
                    render={(value: LendingType) => <EnumField enum='LendingType' translate={translate} value={value} />}
                />
                <Table.Column
                    dataIndex="basicRate"
                    title={translate("lending.fields.basicRate")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="fromAmount1"
                    title={translate("lending.fields.fromAmount1")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="rate1"
                    title={translate("lending.fields.rate1")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="fromAmount2"
                    title={translate("lending.fields.fromAmount2")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="rate2"
                    title={translate("lending.fields.rate2")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="fromAmount3"
                    title={translate("lending.fields.fromAmount3")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="rate3"
                    title={translate("lending.fields.rate3")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="actions"
                    align="right"
                    render={(_: unknown, record: LendingDTO) => (
                        <Space>
                            <EditButton
                                hideText
                                disabled={dayjs().isAfter(dayjs.unix(record.effectiveFrom))}
                                size="small"
                                meta={{ assetId: id }}
                                resource="lending"
                                recordItemId={record.id as never}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                meta={{ assetId: id }}
                                resource="lending"
                                recordItemId={record.id as never}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                meta={{ assetId: id }}
                                resource="lending"
                                recordItemId={record.id as never}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List >
    );
}


export const LendingList: React.FC<IResourceComponentsProps> = () => {

    const { id } = useParams();

    const { data: lendingData, isLoading: lendingIsLoading } = useOne({
        resource: "activelendings",
        meta: {
            endpoint: 'specialized/activelendings?assetId=' + id
        },
        id: id,
    });

    const dates = lendingData?.data[id!].map((item: Lending) => item.effectiveFrom) || [0];
    const effectiveFrom = Math.min(...dates);

    if (lendingIsLoading) {
        return <>Loading</>
    }

    return <LendingTable id={id!} effectiveFrom={effectiveFrom} />
};

