import React from "react";
import {
    IResourceComponentsProps
} from "@refinedev/core";
import {
    useTable,
} from "@refinedev/antd";
import { TransactionsTable } from "./table";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { List } from "../../components/crud/list";

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
    const { search, listProps } = useSearchList();

    const query: Record<string, string> = {};
    if (search) {
        query.tokenLIKE = search;
        query.descriptionLIKE = search;
    }
    const { tableProps, sorters, filters } = useTable({
        initialPageSize: defaultPageSize,
        resource: 'transaction',
        meta: { query },
        sorters: {
            initial: [
              {
                field: "createdAt",
                order: "desc",
              },
            ],
          }
    });

    return (
        <List {...listProps}>
            <TransactionsTable tableProps={tableProps} withIdentity={true} sorters={sorters} filters={filters} />
        </List>
    );
};
