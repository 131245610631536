import React from 'react';

import { useTable } from '@refinedev/antd';
import { useOutletContext } from 'react-router-dom';
import { ConnectionInfo, UserData } from '../../../interfaces';
import { SessionsTable } from '../../sessions/table';

export const UserSessions: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>();
    const { tableProps } = useTable<ConnectionInfo>({
        resource: 'UserSessions',
        syncWithLocation: false,
        meta: {
            query: { userId: user.id },
            endpoint: `UserActions/${user.id}/currentsessions`
        }
    });

    return (
        <SessionsTable tableProps={tableProps} />
    );
};
