import { Account } from "./account";
import { LyraSequential } from "./base";
import { UserGroup } from "./userGroup";

export enum DocIdTypeEnum {
    Unknown = "Unknown",
    Passport = "Passport",
    ResidencePermit = "ResidencePermit",
    DriverLicence = "DriverLicence",
    Visa = "Visa",
}

export enum TaxIdTypeEnum {
    Unknown = "Unknown",
    Other = "Other",
    CountryTaxId = "CountryTaxId",
    VatId = "VatId"
}

export enum UserStatus {
    Unknown = "Unknown",
    Registered = "Registered",
    Verified = "Verified",
    Frozen = "Frozen",
    Virtual = "Virtual",
    Staff = "Staff",
    Closed = "Closed",
    VirtualClosed = "Virtual Closed"
}

export enum StatementFrequency {
    None = "None",
    Monthly = "Monthly",
    Daily = "Daily"
}

export enum UserRole {
    TRADER = 'TRADER',
    CRM_ADMIN = 'CRM_ADMIN',
    CRM_TRADER_MANAGER = 'CRM_TRADER_MANAGER',
    CRM_MARKET_MANAGER = 'CRM_MARKET_MANAGER',
    CRM_ROLE_MANAGER = 'CRM_ROLE_MANAGER',
    CRM_RISK_MANAGER = 'CRM_RISK_MANAGER',
}

export interface User extends LyraSequential {

    userGroup?: UserGroup;
    accounts: Account[];
    documents: Document[];

    identityUserId: string;
    culture: string;
    timezone: string;
    userName: string;
    normalizedUserName: string;
    email: string;
    normalizedEmail: string;
    emailConfirmed: boolean;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    twoFactorEnabled: boolean;
    lockoutEnd: null | Date;
    lockoutEnabled: boolean;
    accessFailedCount: number;
    docId: string;
    docIdType: DocIdTypeEnum;
    docIdAuthority: null | string;
    taxId: null | string;
    taxIdType: TaxIdTypeEnum;
    taxIdAuthority: null | string;
    newPassword: null | string;
    nameFirst: string;
    nameMiddle: null | string;
    nameLast: string;
    crmUserId: null | string;
    lyraRegCode: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    status: UserStatus;
    stmtFreq: StatementFrequency;
}

export class UserData implements User {

    id: number = 0;
    token: string = '';

    userGroup?: UserGroup;
    accounts: Account[] = [];
    documents: Document[] = [];

    identityUserId: string = '';
    culture: string = '';
    timezone: string = '';
    userName: string = '';
    normalizedUserName: string = '';
    email: string = '';
    normalizedEmail: string = '';
    emailConfirmed: boolean = false;
    phoneNumber: string = '';
    phoneNumberConfirmed: boolean = false;
    twoFactorEnabled: boolean = false;
    lockoutEnd: null | Date = null;
    lockoutEnabled: boolean = false;
    accessFailedCount: number = 0;
    docId: string = '';
    docIdType: DocIdTypeEnum = DocIdTypeEnum.Unknown;
    docIdAuthority: null | string = null;
    taxId: null | string = null;
    taxIdType: TaxIdTypeEnum = TaxIdTypeEnum.Unknown;
    taxIdAuthority: null | string = null;
    newPassword: null | string = null;
    nameFirst: string = '';
    nameMiddle: null | string = null;
    nameLast: string = '';
    crmUserId: null | string = null;
    lyraRegCode: string = '';
    streetAddress1: string = '';
    streetAddress2: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    country: string = '';
    status: UserStatus = UserStatus.Unknown;
    stmtFreq: StatementFrequency = StatementFrequency.None;

    constructor(userObject: Partial<User>) {
        Object.assign(this, userObject);
    }

    get fullName(): string {
        const ret: string[] = [];
        if (this.nameFirst) {
            ret.push(this.nameFirst);
        }
        if (this.nameMiddle) {
            ret.push(this.nameMiddle);
        }
        if (this.nameLast) {
            ret.push(this.nameLast);
        }
        return ret.join(' ');
    }

    get fullAddress(): string {
        const addressFields: string[] = ['country', 'state', 'city', 'streetAddress1', 'streetAddress2', 'zip'];
        return addressFields.map(field => getValue<UserData, keyof UserData>(this, field as never)).filter(item => item).join(', ');
    }
}

function getValue<T, K extends keyof T>(data: T, key: K) {
    return data[key];
}