import { TextField } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { BooleanField, DateField, EnumField, IdentityField, NumberField } from "../../components/fields";
import { Position, PositionStatus } from "../../interfaces";

type PositionShowProps = {
    position: Position | null | undefined;
}

const OpenPositionInfo: React.FC<{ position: Position }> = ({ position }) => {
    const translate = useTranslate();
    const accountDigits = 2;
    const groupOne = [
        {
            items: [
                {
                    key: 'pairId',
                    label: translate("position.fields.pair"),
                    children: <TextField value={position.pairId} />,
                },
                {
                    key: 'bridgeForward',
                    label: translate("position.fields.bridgeForward"),
                    children: <BooleanField value={position.bridgeForward} />,
                },
                {
                    key: 'bridgeOpening',
                    label: translate("position.fields.bridgeOpening"),
                    children: <NumberField value={position.bridgeOpening} digits={accountDigits} />,
                },
                {
                    key: 'status',
                    label: translate("position.fields.status"),
                    children: <EnumField enum="PositionStatus" value={position.status} translate={translate} />,
                },
                {
                    key: 'notional',
                    label: translate("position.fields.notional"),
                    children: <NumberField value={position.notional} />,
                },
                {
                    key: 'entranceCost',
                    label: translate("position.fields.entranceCost"),
                    children: <NumberField value={position.entranceCost} digits={accountDigits} />,
                },
                {
                    key: 'side',
                    label: translate("position.fields.side"),
                    children: <EnumField enum="PositionSide" value={position.side} translate={translate} />,

                },
                {
                    key: 'marginRatio',
                    label: translate("position.fields.marginRatio"),
                    children: <NumberField value={position.marginRatio} digits={2} />,
                },
            ]
        }
    ];
    const groupTwo = [
        {
            items: [
                {
                    key: 'currentMargin',
                    label: translate("position.fields.currentMargin"),
                    children: <NumberField value={position.currentMargin} digits={accountDigits} />,
                },
                {
                    key: 'currentValue',
                    label: translate("position.fields.currentValue"),
                    children: <NumberField value={position.currentValue} digits={accountDigits} />,
                },
                {
                    key: 'unrealizedPnL',
                    label: translate("position.fields.unrealizedPnL"),
                    children: <NumberField value={position.unrealizedPnL} digits={accountDigits} />,
                },
                {
                    key: 'beingClosed',
                    label: translate("position.fields.beingClosed"),
                    children: <BooleanField value={position.beingClosed} />,
                },
                {
                    key: 'identity',
                    label: translate("position.fields.account"),
                    children: <IdentityField value={position?.identity} />,
                },
                {
                    key: 'createdAt',
                    label: translate("position.fields.createdAt"),
                    children: <DateField value={position.createdAt} />,
                },
                {
                    key: 'lastUpdatedAt',
                    label: translate("position.fields.lastUpdatedAt"),
                    children: <DateField value={position.lastUpdatedAt} />,
                },
                {
                    key: 'token',
                    label: translate("position.fields.token"),
                    children: <TextField value={position.token} />,
                },
                {
                    key: 'id',
                    label: translate("position.fields.id"),
                    children: <NumberField value={position.id} />,
                },

                
                {
                    key: 'method',
                    label: translate("position.fields.method"),
                    children: <EnumField enum="ExecutionMethod" value={position.method} translate={translate} />,
                },
                {
                    key: 'connectorId',
                    label: translate("position.fields.connectorId"),
                    children: <>{position.connectorId}</>,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

const ClosedPositionInfo: React.FC<{ position: Position }> = ({ position }) => {
    const translate = useTranslate();
    const accountDigits = 2;
    const groupOne = [
        {
            items: [
                {
                    key: 'pairId',
                    label: translate("position.fields.pair"),
                    children: <TextField value={position.pairId} />,
                },
                {
                    key: 'status',
                    label: translate("position.fields.status"),
                    children: <EnumField enum="PositionStatus" value={position.status} translate={translate} />,
                },
                {
                    key: 'notional',
                    label: translate("position.fields.notional"),
                    children: <NumberField value={position.notional} digits={accountDigits} />,
                },
                {
                    key: 'entranceCost',
                    label: translate("position.fields.entranceCost"),
                    children: <NumberField value={position.entranceCost} digits={accountDigits} />,
                },
                {
                    key: 'side',
                    label: translate("position.fields.side"),
                    children: <EnumField enum="PositionSide" value={position.side} translate={translate} />,
                },
                {
                    key: 'marginRatio',
                    label: translate("position.fields.marginRatio"),
                    children: <NumberField value={position.marginRatio} digits={2} />,
                },
            ]
        }
    ];
    const groupTwo = [
        {
            items: [
                {
                    key: 'realizedPnl',
                    label: translate("position.fields.realizedPnl"),
                    children: <NumberField value={position.realizedPnl} digits={accountDigits} />,
                },
                {
                    key: 'identity',
                    label: translate("position.fields.account"),
                    children: <IdentityField value={position?.identity} />,
                },
                {
                    key: 'createdAt',
                    label: translate("position.fields.createdAt"),
                    children: <DateField value={position.createdAt} />,
                },
                {
                    key: 'lastUpdatedAt',
                    label: translate("position.fields.lastUpdatedAt"),
                    children: <DateField value={position.lastUpdatedAt} />,
                },
                {
                    key: 'token',
                    label: translate("position.fields.token"),
                    children: <TextField value={position.token} />,
                },
                {
                    key: 'id',
                    label: translate("position.fields.id"),
                    children: <NumberField value={position.id} />,
                },

                {
                    key: 'method',
                    label: translate("position.fields.method"),
                    children: <EnumField enum="ExecutionMethod" value={position.method} translate={translate} />,
                },
                {
                    key: 'connectorId',
                    label: translate("position.fields.connectorId"),
                    children: <>{position.connectorId}</>,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const PositionShowModal: React.FC<PositionShowProps> = ({ position }) => {
    if (!position) {
        return <></>
    }

    if (position.status == PositionStatus.Open) {
        return <OpenPositionInfo position={position} />
    } else {
        return <ClosedPositionInfo position={position} />
    }
};
