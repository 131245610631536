import { getDefaultSortOrder } from "@refinedev/antd";
import { CrudSort, useTranslate } from "@refinedev/core";
import { Button, Modal, Table } from "antd";
import React, { useState } from "react";
import ReactJson from "react-json-view";
import { Link } from "react-router-dom";
import { DateField, EnumField } from "../../components/fields";
import { useReferenceFilter } from "../../hooks/useReferenceFilter";
import { useTableClick } from "../../hooks/useTableClick";
import { Account, UserData } from "../../interfaces";
import { LogCategory, LogRecord, LogSeverity } from "../../interfaces/logRecord";
import { getEnumFilter } from "../../utils";

type LoginLogDetailsProps = {
    tableProps: any;
    sorters: CrudSort[];
    showUser?: boolean;
    showAccount?: boolean;
};

export const LogTable: React.FC<LoginLogDetailsProps> = ({ tableProps, sorters, showUser, showAccount }) => {
    const translate = useTranslate();
    const categoryFilters = getEnumFilter(LogCategory, 'LogCategory', translate, [LogCategory.Unknown]);
    const severityFilters = getEnumFilter(LogSeverity, 'LogSeverity', translate, [LogSeverity.Unknown]);

    const { filters: userFilters, hash: usersHash } = useReferenceFilter<UserData>({ enabled: showUser !== false, resource: 'user', search: { resource: 'reference', meta: { endpoint: 'log/reference/userId' } }, labelName: 'token' })
    const { filters: accountFilters, hash: accountsHash } = useReferenceFilter<Account>({ enabled: showAccount !== false, resource: 'account', search: { resource: 'reference', meta: { endpoint: 'log/reference/accountId' } }, labelName: 'token' })

    const [activeRecord, setActiveRecord] = useState<object | string | null>(null);

    const showModal = (record: LogRecord) => {
        let ret = {};
        try {
            ret = JSON.parse(record.message);
        } catch (e) {
            ret = record.message;
        }
        setActiveRecord(ret);
    };

    const handleOk = () => {
        setActiveRecord(null);
    };

    const handleCancel = () => {
        setActiveRecord(null);
    };

    const clickTableProps = useTableClick({ onClick: showModal, resource: 'log', action: 'show' });

    return (
        <>
            <Modal open={!!activeRecord} onOk={handleOk} onCancel={handleCancel} width={1000}
                footer={
                    <Button type="primary" onClick={handleCancel}>
                        {translate('buttons.close')}
                    </Button>
                }
            >
                {typeof activeRecord === 'object' && activeRecord && <ReactJson theme="brewer" src={activeRecord} displayDataTypes={false} />}
                {typeof activeRecord === 'string' && activeRecord && <p>{activeRecord}</p>}
            </Modal>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                {showUser !== false && <Table.Column
                    dataIndex="userId"
                    title={translate("log.fields.user")}
                    filters={userFilters}
                    render={(value: number) => <>{value > 0 && <Link to={`/user/show/${value}/details`} className="inline-flex"><span>{usersHash?.get(value)?.token}</span></Link>}</>}
                />}
                {showAccount !== false && <Table.Column
                    dataIndex="accountId"
                    title={translate("log.fields.account")}
                    filters={accountFilters}
                    render={(value: number) => <>{value > 0 && <Link to={`/account/show/${value}/info`} className="inline-flex"><span>{accountsHash?.get(value)?.token}</span></Link>}</>}
                />}
                <Table.Column
                    dataIndex="category"
                    title={translate("log.fields.category")}
                    filters={categoryFilters}
                    sorter={true}
                    render={(value: LogCategory) => <EnumField enum="LogCategory" value={value} translate={translate} />}
                />
                <Table.Column
                    dataIndex="severity"
                    title={translate("log.fields.severity")}
                    filters={severityFilters}
                    sorter={true}
                    render={(value: LogSeverity) => <EnumField enum="LogSeverity" value={value} translate={translate} />}
                />
                <Table.Column
                    dataIndex={["message"]}
                    title={translate("log.fields.message")}
                    width={250}
                    sorter={true}
                    className="ellipsis"
                    ellipsis={{ showTitle: false }}
                    render={(value) => <span>{value}</span>}
                />
                <Table.Column
                    dataIndex="disposition"
                    sorter={true}
                    title={translate("log.fields.disposition")}
                />
                <Table.Column
                    dataIndex={["timestamp"]}
                    sorter={true}
                    defaultSortOrder={getDefaultSortOrder("timestamp", sorters)}
                    title={translate("log.fields.timestamp")}
                    render={(value: string) => <DateField value={value} format="LLL" />}
                />
            </Table>
        </>
    );
};