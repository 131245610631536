import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import React, { useState } from 'react';

import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTitleData } from '../../contexts/title';
import { Account } from '../../interfaces/account';
import { Show } from "../../components/crud/show";

export const AccountShow: React.FC<IResourceComponentsProps> = React.memo(() => {
    const { id } = useParams<{ id: string, tab: string }>();
    const tab = useLocation().pathname.split("/").pop();

    const [activeTab, setActiveTab] = useState(tab);

    const { queryResult } = useShow<Account>({ resource: 'account', id });
    const { data, isLoading } = queryResult;
    const translate = useTranslate();
    const navigate = useNavigate();

    const account = data?.data;

    useTitleData({ ...account, tab: activeTab });

    if (isLoading || !account) {
        return <div>Loading...</div>;
    }

    const items: TabsProps['items'] = [
        {
            key: 'info',
            label: translate("account.show.info"),
        },
        {
            key: 'open_positions',
            label: translate("account.show.open_positions"),
        },
        {
            key: 'positions',
            label: translate("account.show.positions"),
        },
        {
            key: 'open_orders',
            label: translate("account.show.open_orders"),
        },
        {
            key: 'orders',
            label: translate("account.show.orders"),
        },
        {
            key: 'transactions',
            label: translate("account.show.transactions"),
        },
        {
            key: 'active_alerts',
            label: translate("account.show.active_alerts"),
        },
        {
            key: 'alerts',
            label: translate("account.show.alerts"),
        },
        {
            key: 'log',
            label: translate("account.show.log"),
        },
    ];

    const tabsProps = {
        info: {
            resource: 'account',
            recordItemId: account.id,
            canEdit: true,
        },
        open_positions: {
            resource: 'OpenPosition',
            recordItemId: undefined,
            canEdit: false,
        },
        positions: {
            resource: 'position',
            recordItemId: undefined,
            canEdit: false,
        },
        open_orders: {
            resource: 'OpenOrders',
            recordItemId: undefined,
            canEdit: false,
        },
        orders: {
            resource: 'order',
            recordItemId: undefined,
            canEdit: false,
        },
        transactions: {
            resource: 'transaction',
            recordItemId: undefined,
            canEdit: false,
        },
        active_alerts: {
            resource: 'active_alert',
            recordItemId: undefined,
            canEdit: false,
        },
        alerts: {
            resource: 'alert',
            recordItemId: undefined,
            canEdit: false,
        },
        log: {
            resource: 'log',
            recordItemId: undefined,
            canEdit: false,
        },
    }

    const tabProps = tabsProps[activeTab as keyof typeof tabsProps];

    const onChange = (key: string) => {
        setTimeout(() => {
            navigate(`/account/show/${account.id}/${key}`, { replace: true });
            // window.history.replaceState({}, '', `/account/show/${id}/${key}`);
        }, 10);
        setActiveTab(key);
    };

    return (
        <Show
            {...tabProps}
            isLoading={isLoading}
            title={translate(`account.titles.show`, { ...account, tab: activeTab })}
        >
            <div>
                <Tabs items={items} onChange={onChange} defaultActiveKey={activeTab} />
                {/* {items.map((item) => (
                    <Link key={item.key} to={`/account/show/${account.id}/${item.key}`}>
                        {item.label}
                    </Link>
                ))} */}
            </div>
            <Outlet context={{ account: account }} />
        </Show>
    );
});