
export interface BrokerVarInfo {
    vaR: number;
    cVaR: number;
    volatility: number;
    totalValue: number;
}

export interface BrokerRatioInfo {
    sharpe: number;
    sortino: number;
}

export enum VaRMethod {
    HistoricalSimulation = 'HistoricalSimulation',
    VarianceCovariance = 'VarianceCovariance',
    MonteCarlo = 'MonteCarlo',
}


export enum MetricPeriodicity {
    // None = 0,
    Intraday = 1,
    Daily = 2,
    Monthly = 4
}

export interface RateItem {
    symbol: string;
    country: string;
    yield: number;
}

export interface DenominatedIn {
    accountingCurrency: string;
    displayDecimals: number;
    exposureDecimals: number;
}

export interface ExposureEntryMetrics {
    snapshot: string;
    vaR: number;
    cVaR: number;
    volatility: number;
    sharpeRatio: number;
    sortinoRatio: number;

    varMethod?: VaRMethod;
    varWindow?: number;
    varConfidence?: number;
    riskFreeRate?: number;

    positionCount: number;
    totalValue: number;
    internalizedValue: number;
    accCurrNetPosition: number;
    denominatedIn: DenominatedIn;
}

export interface ExposureEntryMetricsReport {
    denominatedIn: DenominatedIn;
    history: ExposureEntryMetrics[];
}