import { EnumToObject } from "../utils";
import { Asset } from "./asset";

export enum LendingType {
    Unknown = 'Unknown',
    SwapInterest = 'SwapInterest',
    MarginInterest = 'MarginInterest',
    StockBorrowInterest = 'StockBorrowInterest',
    IdleCash = 'IdleCash'
}

export interface Lending {
    id: string;
    assetId: string;
    asset?: Asset;
    effectiveFrom: number;
    lendingType?: LendingType;
    basicRate: number;
    fromAmount1: number;
    rate1: number;
    fromAmount2: number;
    rate2: number;
    fromAmount3: number;
    rate3: number;
}

export class LendingDTO implements Lending {
    constructor(data: Partial<Lending> = {}) {
        this.assetId = data.assetId || '';
        this.asset = data.asset;
        this.effectiveFrom = data.effectiveFrom || 0;
        this.lendingType = data.lendingType!;
        this.basicRate = data.basicRate || 0;
        this.fromAmount1 = data.fromAmount1 || 0;
        this.rate1 = data.rate1 || 0;
        this.fromAmount2 = data.fromAmount2 || 0;
        this.rate2 = data.rate2 || 0;
        this.fromAmount3 = data.fromAmount3 || 0;
        this.rate3 = data.rate3 || 0;
    }

    get id() {
        return [this.assetId, this.lendingType, this.effectiveFrom] as never;
    }

    assetId: string;
    asset?: Asset;
    effectiveFrom: number;
    lendingType: LendingType;
    basicRate: number;
    fromAmount1: number;
    rate1: number;
    fromAmount2: number;
    rate2: number;
    fromAmount3: number;
    rate3: number;
}
