import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { PairCategoryForm } from "./form";
import { PairCategory } from "../../interfaces";
import { Edit } from "../../components/crud/edit";

export const PairCategoryEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm<PairCategory>();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <PairCategoryForm formProps={formProps}></PairCategoryForm>
        </Edit>
    );
};
