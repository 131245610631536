import { EditButton, useTable } from '@refinedev/antd';
import { useOne, useTranslate } from '@refinedev/core';
import { Card, Radio, Space, Table } from 'antd';
import React, { useState } from 'react';
import { List } from "../../components/crud/list";
import { LogoField, NumberField, UnitValueField } from '../../components/fields';
import { CurrentExposure, ExposureAccountingCurrency } from '../../interfaces';

type AggrType = "assetClass" | "asset" | "pair";

const UtilizationPage: React.FC = () => {
    const translate = useTranslate();
    const [aggrType, setAggrType] = useState<AggrType>('assetClass');
    const [includeUnlimited, setIncludeUnlimited] = useState<boolean>(false);

    const query = {
        includeUnlimited: includeUnlimited,
    }

    const { data: crminfo } = useOne<ExposureAccountingCurrency>({ resource: "crminfo", id: '', });
    const digits = crminfo?.data?.exposureDecimals || 0;

    const endpoint = `exposure/By${aggrType}/utilization`;

    const { tableProps } = useTable<CurrentExposure>({
        resource: 'utilization',
        pagination: { mode: 'off' },
        meta: {
            endpoint,
            query
        }
    });

    return (
        <List
            resource='utilization'
            title={translate('exposure.utilization.title')}
        >
            <Card size="small">
                <Space direction="horizontal">
                    <Radio.Group onChange={(e) => setAggrType(e.target.value)} value={aggrType}>
                        <Radio.Button value="pair">{translate('exposure.utilization.tabs.ByPair')}</Radio.Button>
                        <Radio.Button value="asset">{translate('exposure.utilization.tabs.ByAsset')}</Radio.Button>
                        <Radio.Button value="assetClass">{translate('exposure.utilization.tabs.ByAssetClass')}</Radio.Button>
                    </Radio.Group>

                    <Radio.Group onChange={(e) => setIncludeUnlimited(e.target.value)} value={includeUnlimited}>
                        <Radio.Button value={true}>{translate('exposure.utilization.tabs.includeUnlimited')}</Radio.Button>
                        <Radio.Button value={false}>{translate('exposure.utilization.tabs.excludeUnlimited')}</Radio.Button>
                    </Radio.Group>
                </Space>
            </Card>
            <Table {...tableProps} >
                <Table.Column
                    dataIndex={["underlying", "id"]}
                    sorter={(a: CurrentExposure, b: CurrentExposure) => a.underlying.id.localeCompare(b.underlying.id)}
                    title={translate("exposure.utilization.fields.id")}
                    render={(value) => <LogoField type={aggrType} id={value} name={value} />}
                />
                <Table.ColumnGroup title={translate("exposure.utilization.fields.short")}>
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.short - b.short}
                        title={translate("exposure.utilization.fields.exposure")}
                        dataIndex={["short"]}
                        render={(value: number) => !!value && <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.underlying.expLimShortT1 - b.underlying.expLimShortT1}
                        title={translate("exposure.utilization.fields.limit")}
                        dataIndex={["underlying", "expLimShortT1"]}
                        render={(value: number) => !!value && <UnitValueField value={value} digits={digits} />}
                    />
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.shortUtilization - b.shortUtilization}
                        title={translate("exposure.utilization.fields.utilization")}
                        dataIndex={["shortUtilization"]}
                        render={(value: number) => !!value && <UnitValueField value={value * 100} unit='%' digits={2} className={value < 1 ? 'color-green' : 'color-red'} />}
                    />
                </Table.ColumnGroup>

                <Table.ColumnGroup title={translate("exposure.utilization.fields.long")}>
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.long - b.long}
                        title={translate("exposure.utilization.fields.exposure")}
                        dataIndex={["long"]}
                        render={(value: number) => !!value && <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.underlying.expLimLongT1 - b.underlying.expLimLongT1}
                        title={translate("exposure.utilization.fields.limit")}
                        dataIndex={["underlying", "expLimLongT1"]}
                        render={(value: number) => !!value && <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.longUtilization - b.longUtilization}
                        title={translate("exposure.utilization.fields.utilization")}
                        dataIndex={["longUtilization"]}
                        render={(value: number) => !!value && <UnitValueField value={value * 100} unit='%' digits={2} className={value < 1 ? 'color-green' : 'color-red'} />}
                    />
                </Table.ColumnGroup>

                <Table.ColumnGroup title={translate("exposure.utilization.fields.netted")}>
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.netted - b.netted}
                        title={translate("exposure.utilization.fields.exposure")}
                        dataIndex={["netted"]}
                        render={(value: number) => !!value && <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.underlying.expLimNettedAbsT1 - b.underlying.expLimNettedAbsT1}
                        title={translate("exposure.utilization.fields.limit")}
                        dataIndex={["underlying", "expLimNettedAbsT1"]}
                        render={(value: number) => !!value && <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        align='right'
                        sorter={(a: CurrentExposure, b: CurrentExposure) => a.nettedUtilization - b.nettedUtilization}
                        title={translate("exposure.utilization.fields.utilization")}
                        dataIndex={["nettedUtilization"]}
                        render={(value: number) => !!value && <UnitValueField value={value * 100} unit='%' digits={2} className={value < 1 ? 'color-green' : 'color-red'} />}
                    />
                </Table.ColumnGroup>

                <Table.Column
                    width={70}
                    align="right"
                    dataIndex="actions"
                    render={(_, record: CurrentExposure) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                resource={aggrType}
                                recordItemId={record.underlying.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};

export default UtilizationPage;