import React from 'react';
import {
    useTable
} from "@refinedev/antd";
import { Account } from '../../../interfaces/account';
import { Order } from '../../../interfaces';
import { OrdersTable } from '../../orders/closedOrdersTable';
import { defaultPageSize } from '../../../constants';
import { useOutletContext } from 'react-router-dom';

export const AccountOrders: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();
    const { tableProps, sorters, filters } = useTable<Order>({
        resource: 'order',
        initialPageSize: defaultPageSize,
        syncWithLocation: false,
        meta: { query: { accountId: account.id } },
        sorters: {
            initial: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
        }
    });


    return (
        <OrdersTable {...tableProps} withIdentity={false} sorters={sorters} filters={filters} />
    );

};
