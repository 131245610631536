import { UserManager, User, WebStorageStateStore, SigninSilentArgs, Log } from "oidc-client-ts";
import { ApplicationPaths, ApplicationName } from "./ApiAuthorizationConstants";
import { Mutex } from "async-mutex";

type AuthResult = {
  status: string;
  message?: string;
  state?: object;
}

export class AuthorizeService {
  _callbacks: any[] = [];
  _nextSubscriptionId = 0;
  _user?: User | null;
  _isAuthenticated = false;
  userManager: UserManager | undefined = undefined;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;
  _mutex = new Mutex();

  async isAuthenticated() {
    const user = await this.getUser();
    return !!user;
  }

  async getUser() {
    const release = await this._mutex.acquire();
    try {
      if (this._user && this._user.profile) {
        return this._user.profile;
      }

      await this.ensureUserManagerInitialized();
      const user = await this.userManager?.getUser();
      return user && !user.expired && user.profile;
    } finally {
      release();
    }
  }

  async getAccessToken() {
    await this.ensureUserManagerInitialized();
    const user = await this.userManager?.getUser();
    return user && !user.expired && user.access_token;
  }

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  async signIn(state: object): Promise<AuthResult> {
    await this.ensureUserManagerInitialized();
    try {
      const silentUser = await this.userManager?.signinSilent(
        this.createArguments(state)
      );
      this.updateState(silentUser ? silentUser : null);
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      console.log("Silent authentication error: ", silentError);
      try {
        await this.userManager?.signinRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectError) {
        const errorMessage = redirectError as Error;

        console.log("Redirect authentication error: ", redirectError);
        return this.error(errorMessage.message);
      }
    }
  }

  async completeSignIn(url: string): Promise<AuthResult> {
    try {
      await this.ensureUserManagerInitialized();
      const user = await this.userManager?.signinCallback(url)!;
      this.updateState(user ? user : null);
      return this.success(user ? user.state as object : {});
    } catch (error) {
      console.log("There was an error signing in: ", error);
      return this.error("There was an error signing in.");
    }
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state: object): Promise<AuthResult> {
    await this.ensureUserManagerInitialized();
    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it."
        );
      }

      await this.userManager?.signoutPopup(this.createArguments(state));
      this.updateState(null);
      return this.success(state);
    } catch (popupSignOutError) {
      console.log("Popup signout error: ", popupSignOutError);
      try {
        await this.userManager?.signoutRedirect(this.createArguments(state));
        return this.redirect();
      } catch (redirectSignOutError) {
        const errorMessage = redirectSignOutError as string;
        console.log("Redirect signout error: ", redirectSignOutError);
        return this.error(errorMessage);
      }
    }
  }

  async completeSignOut(url: string): Promise<AuthResult> {
    await this.ensureUserManagerInitialized();
    try {
      await this.userManager?.signoutCallback(url);
      this.updateState(null);
      return this.success({});
    } catch (error) {
      const errorMessage = error as string;
      console.log(`There was an error trying to log out '${errorMessage}'.`);
      return this.error(errorMessage);
    }
  }

  updateState(user: User | null) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  subscribe(callback: CallableFunction) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++,
    });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId: number) {
    const subscriptionIndex = this._callbacks
      .findIndex(element => element.subscription === subscriptionId)
    if (subscriptionIndex === -1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionId}`
      );
    }

    this._callbacks.splice(subscriptionIndex, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state: object): SigninSilentArgs {
    return { state: state };
  }

  error(message: string): AuthResult {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state: object): AuthResult {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect(): AuthResult {
    return { status: AuthenticationResultStatus.Redirect };
  }

  async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    let response = await fetch(
      ApplicationPaths.ApiAuthorizationClientConfigurationUrl
    );
    if (!response.ok) {
      throw new Error(`Could not load settings for '${ApplicationName}'`);
    }

    let settings = await response.json();
    settings.automaticSilentRenew = true;
    settings.includeIdTokenInSilentRenew = true;
    settings.loadUserInfo = true;
    settings.userStore = new WebStorageStateStore({
      prefix: ApplicationName,
    });

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager?.removeUser();
      this.updateState(null);
    });
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
