import React from "react";

import { EditButton, ShowButton, useModalForm } from "@refinedev/antd";
import { Modal, Space, Table } from "antd";
import {
    BaseRecord,
    useTranslate,
} from "@refinedev/core";
import { Alert } from "../../interfaces";
import { getEnumFilter } from "../../utils";
import { DateField, LogoPairField, UnitValueField } from "../../components/fields";
import { OrderSide } from "../../interfaces/triggerable";
import { GoodUntilDateField } from "../../components/common/GoodUntilDateField";
import { useTableClick } from "../../hooks/useTableClick";
import { AlertForm } from "./form";
import { useReferenceData } from "../../hooks/useReferenceFilter";


export const ActiveAlertsTable: React.FC<any> = (props) => {
    const translate = useTranslate();
    const sideFilter = getEnumFilter(OrderSide, 'OrderSide', translate, ["Unknown"]);
    const clickTableProps = useTableClick({ resource: 'alert', onClick: (record: BaseRecord) => editModalShow(record.id) });

    const pairsHash = useReferenceData({ resource: 'pair/info', ids: props.dataSource?.map((item: Alert) => item.pairId) });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        formLoading,
        open: isEditModalOpen,
    } = useModalForm<Alert>({
        resource: "alert",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });

    return <><Table {...props} rowKey="id" {...clickTableProps}>
        <Table.Column
            dataIndex="token"
            title={translate("alert.fields.token")}
        />
        <Table.Column
            dataIndex={["pairId"]}
            title={translate("order.fields.pairId")}
            render={(value) => <LogoPairField pairId={value} />}
        />
        <Table.Column
            dataIndex="side"
            title={translate("alert.fields.side")}
            filters={sideFilter}
            onFilter={(value, record: Alert) => record.side == value}
        />
        <Table.Column
            dataIndex="triggerPrice"
            align="right"
            title={translate("alert.fields.triggerPrice")}
            render={(value: any, record: Alert) => <>{value != 0 && <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}</>}
        />
        <Table.Column
            dataIndex={["goodUntil"]}
            title={translate("alert.fields.goodUntil")}
            render={(value: any) => <GoodUntilDateField value={value} />}
        />
        <Table.Column
            dataIndex={["createdAt"]}
            title={translate("alert.fields.createdAt")}
            render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
            align="end"
            width={100}
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
                <Space>
                    <EditButton
                        hideText
                        size="small"
                        resource="alert"
                        recordItemId={record.id}
                        onClick={() => {
                            editModalShow(record.id);
                        }} />
                    <ShowButton
                        hideText
                        size="small"
                        resource="alert"
                        recordItemId={record.id}
                    />
                </Space>
            )}
        />
    </Table>
        {isEditModalOpen && <Modal {...editModalProps}>
            <AlertForm formProps={editFormProps} />
        </Modal>}
    </>
};
