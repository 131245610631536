import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import {
    Show, TextField,
} from "@refinedev/antd";
import { Col, Row } from "antd";
import { Exchange } from "../../interfaces";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { BooleanField, NumberField } from "../../components/fields";

const ExchangeInfo: React.FC<{ exchange: Exchange }> = ({ exchange }) => {
    const translate = useTranslate();
    
    const groupOne = [
        {
            items: [{
                key: 'name',
                label: translate("exchange.fields.name"),
                children: <TextField value={exchange.name} />,
            },
            {
                key: 'description',
                label: translate("exchange.fields.description"),
                children: <TextField value={exchange.description} />,
            },
            {
                key: 'legalEntity',
                label: translate("exchange.fields.legalEntity"),
                children: <TextField value={exchange.legalEntity} />,
            }]
        },
        {    
            items: [{
                key: 'micCode',
                label: translate("exchange.fields.micCode"),
                children: <TextField value={exchange.micCode} />,
            },
            {
                key: 'opMic',
                label: translate("exchange.fields.opMic"),
                children: <TextField value={exchange.opMic} />,
            },
            {
                key: 'lei',
                label: translate("exchange.fields.lei"),
                children: <TextField value={exchange.lei} />,
            },
            {
                key: 'mcc',
                label: translate("exchange.fields.mcc"),
                children: <TextField value={exchange.mcc} />,
            },
            {
                key: 'acro',
                label: translate("exchange.fields.acro"),
                children: <TextField value={exchange.acro} />,
            }]
        }
    ];

    const groupTwo = [
        {
            items: [{
                key: 'countryName',
                label: translate("exchange.fields.countryName"),
                children: <TextField value={exchange.countryName} />,
            },
            {
                key: 'isoCountry',
                label: translate("exchange.fields.isoCountry"),
                children: <TextField value={exchange.isoCountry} />,
            },
            {
                key: 'city',
                label: translate("exchange.fields.city"),
                children: <TextField value={exchange.city} />,
            },
            {
                key: 'site',
                label: translate("exchange.fields.site"),
                children: <TextField value={exchange.site} />,
            },
            {
                key: 'comments',
                label: translate("exchange.fields.comments"),
                children: <TextField value={exchange.comments} />,
            },
            {
                key: 'timeZone',
                label: translate("exchange.fields.timeZone"),
                children: <TextField value={exchange.timeZone} />,
            },
            {
                key: 'timeOffset',
                label: translate("exchange.fields.timeOffset"),
                children: <NumberField value={exchange.timeOffset || ''} />,
            }]
        }
    ];

    const groupThree = [
        {
            items: [{
                key: 'stock',
                label: translate("exchange.fields.stock"),
                children: <BooleanField value={exchange.stock} />,
            },
            {
                key: 'etf',
                label: translate("exchange.fields.etf"),
                children: <BooleanField value={exchange.etf} />,
            },
            {
                key: 'index',
                label: translate("exchange.fields.index"),
                children: <BooleanField value={exchange.index} />,
            },
            {
                key: 'fx',
                label: translate("exchange.fields.fx"),
                children: <BooleanField value={exchange.fx} />,
            },{
                key: 'crypto',
                label: translate("exchange.fields.crypto"),
                children: <BooleanField value={exchange.crypto} />,
            }]
        }
    ];

    return <Row>
            <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>            
            <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                <DescriptionGroups groups={groupThree} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
}


export const ExchangeShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Exchange>();
    const { data, isLoading } = queryResult;
    const translate = useTranslate();
    const exchange = data?.data;

    return (
        <Show isLoading={isLoading} title={translate('exchange.titles.show')}>
            {exchange !== undefined && <ExchangeInfo exchange={exchange}></ExchangeInfo>}
        </Show>
    )
};     
           
        