import React from "react";
import { useTranslate } from "@refinedev/core";
import { Table, TableProps } from "antd";
import { Link } from "react-router-dom";
import { DateField } from "../../components/fields";
import { IPField } from "../../components/fields/IPField";
import { ConnectionInfo } from "../../interfaces";

type SessionTableProps = {
    tableProps: TableProps<ConnectionInfo>;
};

export const SessionsTable: React.FC<SessionTableProps> = ({ tableProps }) => {
    const translate = useTranslate();

    return (
        <Table {...tableProps} rowKey="id">
            <Table.Column
                dataIndex="userToken"
                title={translate("sessions.fields.userToken")}
                render={(value: string, record: ConnectionInfo) => <Link to={`/user/show/${record.userId}/details`} className="inline-flex"><span>{value}</span></Link>}
            />

            <Table.Column
                dataIndex="accountToken"
                title={translate("sessions.fields.accountToken")}
                render={(value: string, record: ConnectionInfo) => <Link to={`/account/show/${record.accountId}/details`} className="inline-flex"><span>{value}</span></Link>}
            />

            <Table.Column
                dataIndex="ipAddressString"
                title={translate("sessions.fields.ipAddress")}
                render={(value: string) => <IPField value={value} />}
            />
            <Table.Column
                dataIndex="connectionEstablished"
                title={translate("sessions.fields.connectionEstablished")}
                render={(value: string) => <DateField value={value} format="LLL" />}
            />
        </Table>
    );
};