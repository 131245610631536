import { useForm, useSelect } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Checkbox, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";
import { useTitleData } from "../../contexts/title";
import { useEnumNumericOptions, useEnumOptions } from "../../hooks/useEnumOptions";
import { Account, AccountGroup, TradingModeNamed } from "../../interfaces";

export const AccountEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm<Account>();

    const { queryResult: queryResultAG } = useSelect<AccountGroup>({ resource: "accountGroup", });

    const options = queryResultAG.data?.data.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    useTitleData({ ...formProps.initialValues });

    const tradingModeOptions = useEnumOptions(TradingModeNamed, "TradingMode");

    return (
        <Edit saveButtonProps={saveButtonProps} title={translate(`account.titles.edit`, { ...formProps.initialValues })}>
            <Form
                {...formProps}
                {...defaultFormLayout}
                style={{ maxWidth: 300 }}
            >
                <Form.Item
                    label={translate("account.fields.accountGroup")}
                    name={"accountGroupId"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={options}
                    />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    name={["enabled"]}
                >
                    <Checkbox>{translate("account.fields.enabled")}</Checkbox>
                </Form.Item>

                <Form.Item
                    label={translate("account.fields.mode")}
                    name={["mode"]}
                >
                    <Select options={tradingModeOptions} />
                </Form.Item>
            </Form>
        </Edit>
    );
};
