import { Button, Modal, Row, Space, Table } from 'antd';
import React from 'react';

import { PlusSquareOutlined } from '@ant-design/icons';
import { DeleteButton, EditButton, ShowButton, useModalForm } from '@refinedev/antd';
import {
    useTranslate
} from "@refinedev/core";
import { AccountType, BankAccount, UserData } from '../../interfaces';
import { BankAccountForm } from './form';


export const BankAccountsTable: React.FC<{ user: UserData, tableQueryResult: any }> = ({ user, tableQueryResult }) => {
    const translate = useTranslate();
    const userId = user.id;

    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
        open: createModalOpen,
    }
        = useModalForm<BankAccount>({
            resource: 'bankAccount',
            action: "create",
        });

    createFormProps.initialValues = {
        denominatedInAssetId: 'EUR',
        accountType: AccountType.Card,
        ownerId: userId
    };
    return (<><Row justify={"end"} className='mb-8'>
        <Button type="primary" onClick={() => createModalShow()}><PlusSquareOutlined /> {translate('buttons.create')}</Button>
    </Row>

        <Table dataSource={tableQueryResult.data?.data} rowKey="id">
            <Table.Column
                dataIndex="accountType"
                title={translate("bankAccount.fields.accountType")}
            />
            <Table.Column
                dataIndex="denominatedInAssetId"
                title={translate("bankAccount.fields.denominatedInAssetId")}
            />
            <Table.Column
                dataIndex="nameOnTheAccount"
                title={translate("bankAccount.fields.nameOnTheAccount")}
            />
            <Table.Column
                dataIndex="bankName"
                title={translate("bankAccount.fields.bankName")}
            />
            <Table.Column
                dataIndex="bankAddress"
                title={translate("bankAccount.fields.bankAddress")}
            />
            <Table.Column
                dataIndex="bankCity"
                title={translate("bankAccount.fields.bankCity")}
            />
            <Table.Column
                dataIndex="bankState"
                title={translate("bankAccount.fields.bankState")}
            />
            <Table.Column
                dataIndex="bankCountry"
                title={translate("bankAccount.fields.bankCountry")}
            />
            <Table.Column
                dataIndex="accountNumber"
                title={translate("bankAccount.fields.accountNumber")}
            />

            <Table.Column
                dataIndex="actions"
                align="right"
                width={100}
                render={(_, record: BankAccount) => (
                    <Space>
                        <EditButton
                            hideText
                            size="small"
                            resource='bankAccount'
                            meta={{ userId }}
                            recordItemId={record.id}
                        />
                        <ShowButton
                            hideText
                            size="small"
                            resource='bankAccount'
                            meta={{ userId }}
                            recordItemId={record.id}
                        />
                        <DeleteButton
                            hideText
                            resource='bankAccount'
                            size="small"
                            meta={{ userId }}
                            recordItemId={record.id}
                        />
                    </Space>
                )}
            />
        </Table>
        {createModalOpen && <Modal {...createModalProps}>
            <BankAccountForm formProps={createFormProps} />
        </Modal>}
    </>)
};
