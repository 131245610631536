import { NumberField, Show, TextField } from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate
} from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { useExposureDescriptions } from "../../components/exposureLimits";
import { useAssetClassData } from "../../hooks/data";
import { Pair } from "../../interfaces/pair";

const PairInfo: React.FC<{ pair: Pair }> = ({ pair }) => {
    const translate = useTranslate();
    const hash = useAssetClassData();

    const groupOne = [
        {
            title: translate('pair.fields.basic'),
            items: [{
                key: 'displayName',
                label: translate("pair.fields.displayName"),
                children: <TextField value={pair.displayName} />,
            },
            {
                key: 'altName',
                label: translate("pair.fields.altName"),
                children: <TextField value={pair.altName} />,
            },
            {
                key: 'assetClass',
                label: translate("pair.fields.assetClass"),
                children: <TextField value={hash.get(pair.assetClassId)?.name} />,
            },
            {
                key: 'subtype',
                label: translate("pair.fields.subtype"),
                children: <TextField value={pair.subtype} />,
            },
            {
                key: 'costMin',
                label: translate("pair.fields.costMin"),
                children: <NumberField value={pair.costMin} />,
            },
            {
                key: 'tickSize',
                label: translate("pair.fields.tickSize"),
                children: <NumberField value={pair.tickSize} />,
            },
            {
                key: 'exchangeStatus',
                label: translate("pair.fields.exchangeStatus"),
                children: <TextField value={pair.exchangeStatus} />,
            },
            {
                key: 'availability',
                label: translate("pair.fields.availability"),
                children: <TextField value={translate(`enums.TradeAvailability.${pair.availability}`)} />,
            }]
        }
    ];

    const groupTwo = [
        {
            title: translate('pair.fields.decimals'),
            items: [{
                key: 'costDecimals',
                label: translate("pair.fields.costDecimals"),
                children: <NumberField value={pair.costDecimals} />,
            },
            {
                key: 'pairDecimals',
                label: translate("pair.fields.pairDecimals"),
                children: <NumberField value={pair.pairDecimals} />,
            },
            {
                key: 'lotDecimals',
                label: translate("pair.fields.lotDecimals"),
                children: <NumberField value={pair.lotDecimals} />,
            }]
        },
        {
            title: translate('pair.fields.lot'),
            items: [{
                key: 'lotSize',
                label: translate("pair.fields.lotSize"),
                children: <NumberField value={pair.lotSize} />,
            },
            {
                key: 'lotUnitName',
                label: translate("pair.fields.lotUnitName"),
                children: <TextField value={pair.lotUnitName} />,
            }]
        },
        {
            title: translate('pair.fields.hedge'),
            items: [{
                key: 'hedgePosition',
                label: translate("pair.fields.hedgePosition"),
                children: <NumberField value={pair.hedgePosition} />,
            }]
        }
    ];

    const groupThree = [
        {
            title: translate('pair.fields.order'),
            items: [{
                key: 'orderMin',
                label: translate("pair.fields.orderMin"),
                children: <NumberField value={pair.orderMin} />,
            },
            {
                key: 'orderMax',
                label: translate("pair.fields.orderMax"),
                children: <NumberField value={pair.orderMax} />,
            }]
        },
        {
            title: translate('limits.limits'),
            items: useExposureDescriptions(translate, pair),
        }
    ];


    return <Row>
        <Col xs={{ span: 8 }} lg={{ span: 8 }}>
            <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
        </Col>
        <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
            <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
        </Col>
        <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
            <DescriptionGroups groups={groupThree} size="small" layout={'horizontal'} column={1} />
        </Col>
    </Row>
}


export const PairShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Pair>();
    const { data, isLoading } = queryResult;

    const pair = data?.data;

    return (
        <Show isLoading={isLoading}>
            {pair !== undefined && <PairInfo pair={pair}></PairInfo>}
        </Show>
    )
};
