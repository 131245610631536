import {
    Show,
    TextField,
} from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { BooleanField, EnumField } from "../../components/fields";
import { UserGroup } from "../../interfaces";

const UserGroupInfo: React.FC<{ userGroup: UserGroup }> = ({ userGroup }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'name',
                    label: translate("userGroup.fields.name"),
                    children: <TextField value={userGroup.name} />,
                },
                {
                    key: 'description',
                    label: translate("userGroup.fields.description"),
                    children: <TextField value={userGroup.description} />,
                },
                {
                    key: 'isDefaultGroup',
                    label: translate("userGroup.fields.isDefaultGroup"),
                    children: <BooleanField value={userGroup.isDefaultGroup} />,
                },
                {
                    key: 'category',
                    label: translate("userGroup.fields.category"),
                    children: <EnumField enum="UserCategory" value={userGroup.category} translate={translate} />,
                },
                {
                    key: 'token',
                    label: translate("userGroup.fields.token"),
                    children: <TextField value={userGroup.token} />,
                },
            ]
        }
    ];
    
    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const UserGroupShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<UserGroup>();
    const { data, isLoading } = queryResult;

    const userGroup = data?.data;

    return (
        <Show isLoading={isLoading}>
            {userGroup !== undefined && <UserGroupInfo userGroup={userGroup}></UserGroupInfo>}
        </Show>
    );
};
