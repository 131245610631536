import React from "react";
import { useTranslate } from "@refinedev/core";
import { Col, Form, FormProps, Input, Row, Select } from "antd";
import { getEnumNumericOptions } from "../../utils";
import { MarginPolicyCloseoutOptions, MarginPolicyCloseoutStrategy, MarginPolicyWorkflowType } from "../../interfaces";
import { defaultFormLayout } from "../../constants";

export const MarginCallPolicyForm: React.FC<
    { formProps: FormProps }
> = ({ formProps }) => {
    const translate = useTranslate();
    const closeoutOptions = getEnumNumericOptions(MarginPolicyCloseoutOptions, 'MarginPolicyCloseoutOptions', translate, [0]);
    const marginPolicyCloseoutStrategy = getEnumNumericOptions(MarginPolicyCloseoutStrategy, 'MarginPolicyCloseoutStrategy', translate, [0]);
    const marginPolicyWorkflowType = getEnumNumericOptions(MarginPolicyWorkflowType, 'MarginPolicyWorkflowType', translate, [0]);

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
            labelCol= {{ flex: '160px' }}
        >
            <Row>
                <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                    <Form.Item
                        label={translate("marginCallPolicy.fields.description")}
                        name={["description"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={translate("marginCallPolicy.fields.levelWarning")}
                        name={["levelWarning"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={translate("marginCallPolicy.fields.levelCritical")}
                        name={["levelCritical"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={translate("marginCallPolicy.fields.levelMC")}
                        name={["levelMC"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                    <Form.Item
                        label={translate(
                            "marginCallPolicy.fields.autoCloseoutDelay",
                        )}
                        name={["autoCloseoutDelay"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={translate("marginCallPolicy.fields.closeoutOptions")}
                        name={["closeoutOptions"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select options={closeoutOptions} />
                    </Form.Item>
                    <Form.Item
                        label={translate(
                            "marginCallPolicy.fields.closeoutStrategy",
                        )}
                        name={["closeoutStrategy"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select options={marginPolicyCloseoutStrategy} />
                    </Form.Item>
                    <Form.Item
                        label={translate("marginCallPolicy.fields.workflowType")}
                        name={["workflowType"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select options={marginPolicyWorkflowType} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
