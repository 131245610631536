import { AuthBindings } from "@refinedev/core";

import authService from '../components/api-authorization/AuthorizeService.js'
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants.js'
import { axiosInstance } from "./restDataProvider";

export const authProvider: AuthBindings = {
  login: async () => {
    return {
      success: true,
    };
  },
  logout: async () => {
    const returnUrl = window.location.origin;
    return {
      redirectTo: `${ApplicationPaths.LogOut}?returnUrl=${returnUrl}/`,
      authenticated: false,
      success: true,
    };
  },
  check: async () => {
    const isAuthenticated = await authService.isAuthenticated();

    if (isAuthenticated) {
      const accessToken = await authService.getAccessToken();

      axiosInstance.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`,
      };

      return {
        authenticated: true,
      };
    }
    return {
      authenticated: false,
      redirectTo: ApplicationPaths.Login,
    };
  },
  getPermissions: async () => {
    const user = await authService.getUser();
    return user;
  },
  getIdentity: async () => {
    const user = await authService.getUser();
    if (user) {
      return {
        id: user.id,
        name: user.userName,
        avatar: "https://i.pravatar.cc/300",
      };
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    if (error.statusCode === 401 || error.statusCode === 403) {
      return {
        logout: true,
        redirectTo: ApplicationPaths.LogOut,
        error,
      };
    }
    return {error};
  },
};
