import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import {
    Show,
    TextField,
    NumberField,
    DateField,
} from "@refinedev/antd";
import { Col,Row } from "antd";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { IdentityField } from "../../components/fields";
import { Transaction } from "../../interfaces";
import { useTitleData } from "../../contexts/title";


const TransactionInfo: React.FC<{ transaction: Transaction }> = ({ transaction }) => { 
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'accountChange',
                    label: translate("transaction.fields.accountChange"),
                    children: <NumberField value={transaction.accountChange} />,
                },
                {
                    key: 'reserveChange',
                    label: translate("transaction.fields.reserveChange"),
                    children: <NumberField value={transaction.reserveChange} />,
                },
                {
                    key: 'effectiveDate',
                    label: translate("transaction.fields.effectiveDate"),
                    children: <DateField value={transaction.effectiveDate} />,
                },
                {
                    key: 'type',
                    label: translate("transaction.fields.type"),
                    children: <TextField value={transaction.type} />,
                },  
                {
                    key: 'description',
                    label: translate("transaction.fields.description"),
                    children: <TextField value={transaction.description} />,
                },                             
            ]
        }
    ];

    const groupTwo = [
        {
            items: [
                {
                    key: 'account',
                    label: translate("transaction.fields.account"),
                    children: <IdentityField value={transaction.identity} />,
                },
                {
                    key: 'createdAt',
                    label: translate("transaction.fields.createdAt"),
                    children: <DateField value={transaction.createdAt} />,
                },
                {
                    key: 'lastUpdatedAt',
                    label: translate("transaction.fields.lastUpdatedAt"),
                    children: <DateField value={transaction.lastUpdatedAt} />,
                },
                {
                    key: 'token',
                    label: translate("transaction.fields.token"),
                    children: <TextField value={transaction.token} />,
                },                
            ]
        }
    ];
    
    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const TransactionShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Transaction>();
    const { data, isLoading } = queryResult;

    const transaction = data?.data;
    useTitleData({ ...transaction});
    
    return (
        <Show isLoading={isLoading}>
            {transaction !== undefined && <TransactionInfo transaction={transaction}></TransactionInfo>}
        </Show>
    );
};
