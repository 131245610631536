import React from "react";
import {
    IResourceComponentsProps
} from "@refinedev/core";
import {
    useTable,
} from "@refinedev/antd";
import { Account } from "../../interfaces";
import { AccountsTable } from "./table";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { List } from "../../components/crud/list";

export const AccountList: React.FC<IResourceComponentsProps> = () => {
    const { search, listProps } = useSearchList();

    const query: Record<string, string | string[]> = {
        _include: ['accountGroup', 'denominatedInAsset']
    };
    if (search) {
        query.tokenLIKE = search; // TODO: search
    }
    const { tableProps } = useTable<Account>({
        initialPageSize: defaultPageSize,
        syncWithLocation: true,
        resource: 'account',
        meta: { query }
    });

    return (
        <List {...listProps}>
            <AccountsTable {...tableProps} showOwner={true} />
        </List>
    );
};
