import React from "react";
import dayjs from "dayjs";
import { Tooltip, Typography } from "antd";

import { DateFieldProps } from "@refinedev/antd";

/**
 * This field is used to display dates. It uses {@link https://day.js.org/docs/en/display/format `Day.js`} to display date format.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/fields/date} for more details.
 */
export const DateField: React.FC<DateFieldProps> = ({
    value,
    locales,
    format: dateFormat = "LLL",
    ...rest
}) => {
    if (!value) {
        return <></>
    }

    const defaultLocale = dayjs.locale();

    const { Text } = Typography;
    const displayDate = dayjs(value).locale(locales || defaultLocale).format(dateFormat);
    const altDate = dayjs(value).locale(locales || defaultLocale).format('LTS');

    return (
        <Text {...rest}>
            <Tooltip title={altDate}>
                {displayDate}
            </Tooltip>
        </Text>
    );
};
