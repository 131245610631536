import type { ListProps } from "@refinedev/antd";
import {
  List as RefinedList
} from "@refinedev/antd";
import { useResource } from "@refinedev/core";
import React from "react";
import { useRefresh } from "../../hooks/useAutoRefresh";

type ListPropsExt = ListProps & {
  searchWidget?: any;
  resource?: string;
  autorefresh?: boolean;
  extraButtons?: any;
};

/**
 * `<List>` provides us a layout for displaying the page.
 * It does not contain any logic but adds extra functionalities like a refresh button.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/basic-views/list} for more details.
 */
export const List: React.FC<ListPropsExt> = (props) => {
  const { identifier } = useResource(props.resource);

  const headerButtonsRefresh = useRefresh({ resource: props.resource ? props.resource : identifier, autorefresh: props.autorefresh });

  const headerButtons = (headerProps: any) => (
    <>
      {props.extraButtons ? props.extraButtons(headerProps): null}
      {props.searchWidget?.(headerProps)}
      {headerButtonsRefresh(props)}
      {headerProps.defaultButtons}
    </>
  )

  return <RefinedList {...props} headerButtons={headerButtons} />
};
