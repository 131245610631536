import {AccountGroup} from "./accountGroup";
import { Asset } from "./asset";
import { LyraSequential, UserIdentity } from "./base";
import { Override } from "./override";
import {User} from "./user";

export enum TradingModeNamed
{
    None = 'None',
    Margin = 'Margin',
    Cash = 'Cash',
    Hybrid = 'Hybrid'
}


export interface Account extends LyraSequential {
    defaultAccount: boolean;
    enabled: boolean;
    currentBalance: number;
    reservedAmount: number;
    overrideFeeId: number;
    overrideFee?: Override;
    accountGroup?: AccountGroup;
    accountGroupId: number;
    denominatedInAssetId: string;
    denominatedInAsset?: Asset;
    ownerId: number;
    owner?: User;
    identity?: UserIdentity;
    mode: TradingModeNamed;
}

export class AccountData implements Account {
    id: number;
    token: string;
    defaultAccount: boolean;
    enabled: boolean;
    currentBalance: number;
    reservedAmount: number;
    overrideFeeId: number;
    overrideFee?: Override;
    accountGroup?: AccountGroup;
    accountGroupId: number;
    denominatedInAssetId: string;
    denominatedInAsset?: Asset;
    ownerId: number;
    owner?: User;
    identity?: UserIdentity;
    mode: TradingModeNamed;

    constructor(
        account: Account
    ) {
        this.id = account.id;
        this.token = account.token;
        this.defaultAccount = account.defaultAccount;
        this.enabled = account.enabled;
        this.currentBalance = account.currentBalance;
        this.reservedAmount = account.reservedAmount;
        this.overrideFeeId = account.overrideFeeId;
        this.overrideFee = account.overrideFee;
        this.accountGroup = account.accountGroup;
        this.accountGroupId = account.accountGroupId;
        this.denominatedInAssetId = account.denominatedInAssetId;
        this.denominatedInAsset = account.denominatedInAsset;
        this.ownerId = account.ownerId;
        this.owner = account.owner;
        this.identity = account.identity;
        this.mode = account.mode;
    }

    get displayName(): string {
        if (this.identity) {
            return `${this.identity.denominatedIn}: ${this.identity.fullName}`;
        } else {
            return `${this.token}: ${this.denominatedInAssetId}`;
        }
    }
}