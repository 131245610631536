import React from "react";
import { useTranslate } from "@refinedev/core";
import { useSelect } from "@refinedev/antd";
import { Form, Select, FormProps } from "antd";
import { User, UserGroup } from "../../interfaces";
import { defaultFormLayout } from "../../constants";
export const StaffUsersForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const { selectProps: userSelectProps } = useSelect<User>({
        resource: 'user',
        optionLabel: 'userName',
        meta: { query: {} }
    });

    const { selectProps: ugSelectProps } = useSelect<UserGroup>({
        resource: 'userGroup',
        optionLabel: 'name'
    });

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
            labelCol={{ flex: '160px' }}
        >
            <Form.Item
                label={translate("staffUser.fields.staffUser")}
                name={["staffUserId"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select {...userSelectProps} />
            </Form.Item>
            <Form.Item
                label={translate("staffUser.fields.managedUserGroup")}
                name={["managedUserGroupId"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select {...ugSelectProps} />
            </Form.Item>
        </Form>
    );
};
