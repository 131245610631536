import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Show, TagField, TextField, NumberField, EditButtonProps, ListButton, EditButton, DeleteButton, RefreshButton } from "@refinedev/antd";
import { Descriptions, Typography } from "antd";
import { DescriptionsProps } from "antd/lib";
import { AccountType, BankAccount } from "../../interfaces";
import { EnumField } from "../../components/fields";
import { useParams } from "react-router-dom";

const { Title } = Typography;

export const BankAccountShow: React.FC<IResourceComponentsProps> = () => {
    const { id: userId, bankAccountId } = useParams();
    const translate = useTranslate();
    const { queryResult } = useShow<BankAccount>({
        resource: "bankAccount",
        id: bankAccountId,
        meta: { userId: userId }
    });
    const { data, isLoading } = queryResult;

    const record = data?.data;

    if (!record) {
        return <></>
    }

    const items: DescriptionsProps['items'] = [
        {
            key: 'denominatedInAssetId',
            label: translate("bankAccount.fields.denominatedInAssetId"),
            children: <TextField value={record.denominatedInAssetId} />,
        },
        {
            key: 'accountType',
            label: translate("bankAccount.fields.accountType"),
            children: <EnumField enum={"AccountType"} value={record.accountType} translate={translate} />,
        },
    ]

    let accountInfo: DescriptionsProps['items'] = [];
    if (record.accountType == AccountType.BankAccount) {
        accountInfo = [
            {
                key: 'nameOnTheAccount',
                label: translate("bankAccount.fields.nameOnTheAccount"),
                children: <TextField value={record.nameOnTheAccount} />,
            },
            {
                key: 'bankName',
                label: translate("bankAccount.fields.bankName"),
                children: <TextField value={record.bankName} />,
            },
            {
                key: 'bankAddress',
                label: translate("bankAccount.fields.bankAddress"),
                children: <TextField value={record.bankAddress} />,
            },
            {
                key: 'bankCity',
                label: translate("bankAccount.fields.bankCity"),
                children: <TextField value={record.bankCity} />,
            },
            {
                key: 'bankState',
                label: translate("bankAccount.bankState.bankState"),
                children: <TextField value={record.nameOnTheAccount} />,
            },
            {
                key: 'bankZip',
                label: translate("bankAccount.fields.bankZip"),
                children: <TextField value={record.bankZip} />,
            },
            {
                key: 'bankCountry',
                label: translate("bankAccount.fields.bankCountry"),
                children: <TextField value={record.bankCountry} />,
            },
            {
                key: 'accountNumber',
                label: translate("bankAccount.fields.accountNumber"),
                children: <TextField value={record.accountNumber} />,
            },
            {
                key: 'routingNumber',
                label: translate("bankAccount.fields.routingNumber"),
                children: <TextField value={record.routingNumber} />,
            },
            {
                key: 'SWIFTCode',
                label: translate("bankAccount.fields.SWIFTCode"),
                children: <TextField value={record.SWIFTCode} />,
            },
        ];
    } else if (record.accountType == AccountType.Card) {
        accountInfo = [
            {
                key: 'cardNumber',
                label: translate("bankAccount.fields.cardNumber"),
                children: <TextField value={record.cardNumber} />,
            }
        ]
    } else if (record.accountType == AccountType.CryptoWallet) {
        accountInfo = [
            {
                key: 'networkName',
                label: translate("bankAccount.fields.networkName"),
                children: <TextField value={record.networkName} />,
            },
            {
                key: 'walletAddress',
                label: translate("bankAccount.fields.walletAddress"),
                children: <TextField value={record.walletAddress} />,
            }
        ]
    }

    return (
        <Show
            isLoading={isLoading}
            headerButtons={({
                deleteButtonProps,
                editButtonProps,
                listButtonProps,
                refreshButtonProps,
            }) => (
                
                <>
                    {listButtonProps && (
                        <ListButton
                            {...listButtonProps}
                            meta={{ userId }}
                        />
                    )}
                    {editButtonProps && (
                        <EditButton
                            {...editButtonProps}
                            recordItemId={bankAccountId}
                            meta={{ userId }}
                        />
                    )}
                    {deleteButtonProps && (
                        <DeleteButton
                            {...deleteButtonProps}
                            recordItemId={bankAccountId}
                            meta={{ userId }}
                        />
                    )}
                    <RefreshButton
                        {...refreshButtonProps}
                        recordItemId={bankAccountId}
                        meta={{ userId }}
                    />
                </>
            )}
        >
            <Descriptions items={items} />
            <Descriptions items={accountInfo} />
        </Show >
    );
};
