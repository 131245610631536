import { EnumToObject } from "../utils";
import { LyraUacObject } from "./base";
import { Order } from "./order";
import { Position } from "./position";

export enum StatementPeriod {
    Month = 'Month',
    Day = 'Day',
    Week = 'Week',
    Year = 'Year'
}

export enum TransactionType {
    Unknown = "Unknown",
    Order = "Order",
    Adjustment = "Adjustment",
    MarginInterest = "MarginInterest",
    SwapInterest = "SwapInterest",
    Fee = "Fee",
    PnL = "PnL",
    StockBorrowInterest = "StockBorrowInterest",
    IdleCash = "IdleCash",
    MarginAdjustment = "MarginAdjustment",
    Capital = "Capital",
    Markup = "Markup"
}

export enum TransactionVisibility {
    Visible = 0,
    Hidden = 1,
}

export const TransactionVisibilityNames = EnumToObject(TransactionVisibility);


export interface Transaction extends LyraUacObject {
    orderId: number | null;
    order: Order | null;
    positionId: number | null;
    position: Position | null;
    accountChange: number;
    reserveChange: number;
    effectiveDate: Date;
    type: TransactionType;
    description: string;
    hidden: TransactionVisibility;
}