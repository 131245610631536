import React from "react";
import { ShowButton, getDefaultSortOrder } from "@refinedev/antd";
import {
    BaseRecord,
    useTranslate,
} from "@refinedev/core";
import { Modal, Space, Table } from "antd";
import { DateField, EnumField, UnitValueField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { useModalShow } from "../../components/useModalShow";
import { useReferenceData } from "../../hooks/useReferenceFilter";
import { useTableClick } from "../../hooks/useTableClick";
import { Account, Order, Position, Transaction, TransactionType, TransactionVisibility, TransactionVisibilityNames } from "../../interfaces";
import { getEnumFilter } from "../../utils";
import { OrderShowModal } from "../orders/showModal";
import { PositionShowModal } from "../position/showModal";
import { TableProps } from "antd/lib";
import { CrudFilter, CrudSort } from "@refinedev/core/dist/contexts/data/types";

interface TransactionsTableProps {
    withIdentity: boolean;
    sorters: CrudSort[];
    filters: CrudFilter[];
    tableProps: TableProps;
}

export const TransactionsTable: React.FC<TransactionsTableProps> = (props) => {
    const translate = useTranslate();

    const typeFilter = getEnumFilter(TransactionType, 'TransactionType', translate, ["Unknown"]);
    const visibilityFilter = getEnumFilter(TransactionVisibility, 'TransactionVisibility', translate, ["Unknown"]);
    const accountsData = useReferenceData({ resource: 'account', ids: props.tableProps.dataSource?.map((item: Transaction) => item.accountId) });
    const accountAssetHash = useReferenceData({ resource: 'asset', ids: props.tableProps.dataSource?.map((item: Transaction) => item.identity?.denominatedIn).filter((id?: string) => id) as string[] });

    const { sorters } = props;

    const { show: posShowModal, modalProps: posModalProps, queryResult: posQueryResult, open: positionModalOpen } = useModalShow<Position>({
        resource: "position",
    });
    const { show: orderShowModal, modalProps: orderModalProps, queryResult: orderQueryResult, open: orderModalOpen } = useModalShow<Order>({
        resource: "order",
    });

    const clickTableProps = useTableClick({ resource: 'transaction', action: 'show' });
    const digits = 2;

    return <>
        <Table
            {...props.tableProps}
            rowKey="id"
            {...clickTableProps}
        >
            <Table.Column
                sorter={true}
                dataIndex="token"
                title={translate("transaction.fields.token")}
            />
            <Table.Column
                dataIndex={["orderId"]}
                sorter={true}
                title={translate("transaction.fields.order")}
                render={(value) => value && <ShowButton
                    hideText
                    size="small"
                    resource="order"
                    recordItemId={value}
                    onClick={() => {
                        orderShowModal(value);
                    }} />}
            />
            <Table.Column
                dataIndex={["positionId"]}
                sorter={true}
                title={translate("transaction.fields.position")}
                render={(value) => value && <ShowButton
                    hideText
                    size="small"
                    resource="position"
                    recordItemId={value}
                    onClick={() => {
                        posShowModal(value);
                    }}
                />}
            />
            <Table.Column
                dataIndex="accountChange"
                sorter={true}
                title={translate("transaction.fields.accountChange")}
                align="end"
                render={(value: number, record: Transaction) => <UnitValueField value={value} digits={digits} unit={record.identity?.denominatedIn ? accountAssetHash.get(record.identity?.denominatedIn)?.symbol : record.identity?.denominatedIn} />}
            />
            <Table.Column
                dataIndex="reserveChange"
                sorter={true}
                title={translate("transaction.fields.reserveChange")}
                align="end"
                render={(value: number, record: Transaction) => <>{value != 0 && <UnitValueField value={value} digits={digits} unit={record.identity?.denominatedIn ? accountAssetHash.get(record.identity?.denominatedIn)?.symbol : record.identity?.denominatedIn} />}</>}
            />
            <Table.Column
                dataIndex={["effectiveDate"]}
                sorter={true}
                title={translate("transaction.fields.effectiveDate")}
                align="end"
                render={(value: string) => <DateField value={value} />}
            />
            <Table.Column
                dataIndex="type"
                sorter={true}
                title={translate("transaction.fields.type")}
                filters={typeFilter}
                render={(value: TransactionType) => <EnumField enum="TransactionType" value={value} translate={translate} />}
            />
            <Table.Column
                dataIndex="description"
                title={translate("transaction.fields.description")}
                className="ellipsis"
                render={(value) => <span>{value}</span>}
            />
            <Table.Column
                dataIndex="hidden"
                filters={visibilityFilter}
                title={translate("transaction.fields.hidden")}
                render={(value: TransactionVisibility) => <EnumField enum="TransactionVisibility" value={TransactionVisibilityNames.get(value)!} translate={translate} />}
            />
            {props.withIdentity && <Table.Column
                dataIndex={["accountId"]}
                title={translate("transaction.fields.account")}
                render={(value) => <AccountField value={accountsData.get(value) as Account} showIdentity={false} link="transactions" />}
            />}
            <Table.Column
                dataIndex={["createdAt"]}
                sorter={true}
                defaultSortOrder={getDefaultSortOrder("createdAt", sorters)}
                title={translate("transaction.fields.createdAt")}
                render={(value: string) => <DateField value={value} />}
            />
            <Table.Column
                dataIndex={["lastUpdatedAt"]}
                sorter={true}
                title={translate("transaction.fields.lastUpdatedAt")}
                render={(value: string) => <DateField value={value} />}
            />

            <Table.Column
                align="end"
                width={100}
                dataIndex="actions"
                render={(_, record: BaseRecord) => (
                    <Space>
                        <ShowButton
                            hideText
                            size="small"
                            resource="transaction"
                            recordItemId={record.id}
                        />
                    </Space>
                )}
            />
        </Table>
        {positionModalOpen&& <Modal {...posModalProps}>
            <PositionShowModal position={posQueryResult?.data?.data} />
        </Modal>}
        {orderModalOpen && <Modal {...orderModalProps}>
            <OrderShowModal order={orderQueryResult?.data?.data} />
        </Modal>}
    </>
};

