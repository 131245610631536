import { CrudOperators } from "@refinedev/core";

export const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "ne":
        case "gt":
        case "gte":
        case "lte":
            return `${operator.toUpperCase()}`;
        case "contains":
            return "LIKE";
        case "eq":
        default:
            return "";
    }
};
