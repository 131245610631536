import React from 'react';
import { ExportOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useApiUrl, useCustom, useTranslate } from "@refinedev/core";
import { Descriptions, Popover, Spin } from "antd";
import { DescriptionsProps } from "antd/lib";
import { IPInfo } from "../../interfaces/ipinfo";

type IPFiledProps = {
    value?: number | string | null | undefined;
}

export const IPInfoComponent: React.FC<IPFiledProps> = ({ value }) => {
    const apiUrl = useApiUrl();
    const translate = useTranslate();
    const { data, isLoading } = useCustom<IPInfo>({
        url: `${apiUrl}/crminfo/ipinfo`,
        method: 'get',
        config: {
            query: {
                ip: value,
            }
        },
    });

    if (isLoading || !data) {
        return <Spin />
    }

    const items: DescriptionsProps['items'] = [
        {
            key: 'city',
            label: translate('fields.IPInfo.city'),
            children: data.data.city && <span>{data.data.city}</span>,
        },
        {
            key: 'country',
            label: translate('fields.IPInfo.country'),
            children: data.data.countryName && <span>{data.data.countryName}</span>,
        },
        {
            key: 'region',
            label: translate('fields.IPInfo.region'),
            children: data.data.countryName && <span>{data.data.regionName}</span>,
        },
        {
            key: 'timezone',
            label: translate('fields.IPInfo.timezone'),
            children: data.data.timezone && <span>{data.data.timezone}</span>,
        },
        {
            key: 'map',
            children: data.data.latitutde && <a href={`https://www.google.com/maps?ll=${data.data.latitutde},${data.data.longtitutde}`} target="_blank" rel="noreferrer">{translate('fields.IPInfo.map')} <ExportOutlined /></a>,
        },
    ];

    const visibleItems = items.filter((item) => !!item.children);

    return (<>
        {data && <>
            <Descriptions items={visibleItems} layout="horizontal" column={1} size="small" />
        </>}
    </>)
}

export const IPField: React.FC<IPFiledProps> = ({ value }) => {
    if (!value) {
        return <></>
    }

    const content = <IPInfoComponent value={value} />

    return (
        <><Popover content={content} title={`IP: ${value}`}><InfoCircleOutlined /> {value}</Popover></>
    );
};
