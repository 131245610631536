import React from "react";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Checkbox, Select, FormProps } from "antd";
import { UserCategory } from "../../interfaces";
import { getEnumOptions } from "../../utils";
import { defaultFormLayout } from "../../constants";

export const UserGroupsForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const categoryOptions = getEnumOptions(UserCategory, "UserCategory", translate, [UserCategory.Unknown]);

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Form.Item
                label={translate("userGroup.fields.name")}
                name={["name"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("userGroup.fields.description")}
                name={["description"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                valuePropName="checked"
                name={["isDefaultGroup"]}

            >
                <Checkbox>{translate("userGroup.fields.isDefaultGroup")}</Checkbox>
            </Form.Item>
            <Form.Item
                label={translate("userGroup.fields.category")}
                name={"category"}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select options={categoryOptions} />
            </Form.Item>
        </Form>
    );
};
