import { useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Edit } from "../../components/crud/edit";
import { useTitleData } from "../../contexts/title";
import { Order } from "../../interfaces";
import { OrderForm } from "./form";

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm<Order>();
    useTitleData({ ...formProps.initialValues });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <OrderForm formProps={formProps} />
        </Edit>
    );
};
