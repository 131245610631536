import {
    useTranslate
} from "@refinedev/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { BarChartOutlined, PieChartOutlined } from "@ant-design/icons";
import { Card, Col, Radio, Row } from "antd";
import { AggregationType, CategoryExposure, CategoryExposureTypes, ExposureReport, ExposureType } from "../../../interfaces";
import { ExposureBarView } from "./exposureBarView";
import { ExposureChartView } from "./exposureChartView";
import { ExposureTableView } from "./exposureTableView";
import { CategoryLabelFormatters } from "../../../utils";

export const MAX_CHART_ITEMS = 4;

export type ExposureChartViewProps = {
    data: ExposureReport,
    name: CategoryExposureTypes,
    activeStat: ExposureType,
    showHedges: boolean,
    isLoading: boolean,
    assetType: AggregationType,
    setSorter: CallableFunction,
    sorter: {
        order: string | null,
        field: string[]
    },
    denominatedInAssetId: string
};

type SelectionType = {
    long: string, short: string, netted: string
}

const barPiePerType = {
    totalNotional: {
        short: 'pie',
        long: 'pie',
        netted: 'pie',
    },
    totalValue: {
        short: 'pie',
        long: 'pie',
        netted: 'bar',
    },
    openPositionCount: {
        short: 'pie',
        long: 'pie',
        netted: 'pie',
    },
    cumulativeUnrealizedPnl: {
        short: 'bar',
        long: 'bar',
        netted: 'bar',
    },
}

export const ExposureCard: React.FC<ExposureChartViewProps> = (props: ExposureChartViewProps) => {

    const [viewMode, setViewMode] = useState(barPiePerType[props.activeStat][props.name]);


    useEffect(() => {
        setViewMode(barPiePerType[props.activeStat][props.name]);
    }, [props.activeStat, props.name])

    const translate = useTranslate();

    const [selection, setSelection] = useState<SelectionType>({ long: '', short: '', netted: '' });
    const [chartSelection, setChartSelection] = useState<SelectionType>({ long: '', short: '', netted: '' });

    const onSelectItem = useCallback((chartId: string, dataPointIndex: number) => {
        const chart = chartId.split('-')[0];
        const categoryId = dataPointIndex !== null ? labels[dataPointIndex] : '';
        setSelection(() => { return { ...selection, [chart]: categoryId } });
    }, []);

    const onSelectTableItem = useCallback((chartId: string, categoryId: string) => {
        setChartSelection(() => { return { ...chartSelection, [chartId]: categoryId } });
    }, []);

    const cardTitle = <Row justify={"space-between"} align="middle">
        <Col>{translate(props.activeStat === 'totalValue' && props.name === 'netted' ? 'dashboard.fields.total' : 'dashboard.fields.' + props.name)}</Col>
        <Col>
            <Radio.Group onChange={(e) => setViewMode(e.target.value)} value={viewMode}>
                <Radio.Button value="pie"><PieChartOutlined /> {translate('dashboard.chart.pie')}</Radio.Button>
                <Radio.Button value="bar"><BarChartOutlined /> {translate('dashboard.chart.bar')}</Radio.Button>
            </Radio.Group>
        </Col>
    </Row>

    // Prepare data for the charts.
    const { labels, series, othersData } = useMemo(() => {
        const retLabels = new Array<string>();
        const retSeries = { data: new Array<number>(), original: new Array<number>(), hedges: new Array<number>(), absTotal: 0, total: 0 };

        let sortedData = [...props.data.categories];

        // Prelimenary data sort to move others to the end.
        sortedData = sortedData.sort((a: CategoryExposure, b: CategoryExposure) => {
            const ae = a[props.name];
            const be = b[props.name];
            const res = Math.abs(ae[props.activeStat]) - Math.abs(be[props.activeStat]);
            return -res;
            // return props.sorter.order === 'ascend' ? res : -res;
        });

        // remove zero items
        sortedData = sortedData.filter((c: CategoryExposure) => c[props.name][props.activeStat] != 0);

        // extract others
        const maxItems = sortedData.length > MAX_CHART_ITEMS + 1 ? MAX_CHART_ITEMS : sortedData.length;
        const othersData = sortedData.slice(maxItems);

        const othersCategory: CategoryExposure = {
            categoryId: translate('dashboard.fields.others'),
            hedgeValue: 0,
            hedgePosition: 0,
            long: { totalNotional: 0, totalValue: 0, openPositionCount: 0, cumulativeUnrealizedPnl: 0 },
            short: { totalNotional: 0, totalValue: 0, openPositionCount: 0, cumulativeUnrealizedPnl: 0 },
            netted: { totalNotional: 0, totalValue: 0, openPositionCount: 0, cumulativeUnrealizedPnl: 0 },
        };

        // Create the others category
        othersData.forEach((category: CategoryExposure) => {
            othersCategory[props.name][props.activeStat] += Math.abs(category[props.name][props.activeStat]);
            othersCategory.hedgeValue += category.hedgeValue;
        });

        // Main data
        let selectedData = sortedData.slice(0, maxItems);

        // Sort main data
        selectedData = selectedData.sort((a: CategoryExposure, b: CategoryExposure) => {
            const ae = a[props.name];
            const be = b[props.name];
            const res = ae[props.activeStat] - be[props.activeStat];
            return -res;
        });

        // and place the others at the end
        if (othersData.length > 0) {
            selectedData.push(othersCategory);
        }

        // Prepare the data for the chart
        selectedData.forEach((category: CategoryExposure) => {
            retLabels.push(CategoryLabelFormatters[props.assetType](category.categoryId));

            const value = category[props.name][props.activeStat];
            const hedge = category.hedgeValue;
            // const hedge = value * Math.random() / 2;
            const hedgedValue = props.showHedges ? value + hedge : value;
            const absVal = viewMode === 'pie' ? Math.abs(hedgedValue) : hedgedValue;

            retSeries.data.push(absVal);
            retSeries.original.push(value);
            retSeries.hedges.push(hedge);
            retSeries.absTotal += Math.abs(hedgedValue);
            retSeries.total += hedgedValue;
        });

        return { labels: retLabels, series: retSeries, othersData: othersData };
    }, [props.showHedges, props.data, props.assetType, props.activeStat, viewMode, viewMode === 'bar' ? props.sorter : false]);

    return (
        <Card size="small" title={cardTitle}>
            {viewMode === 'pie' && <ExposureChartView {...props} displayDecimals={props.data.exposureDecimals} denominatedInAssetId={props.data.denominatedInAssetId} serie={series} labels={labels} selection={chartSelection[props.name]} onSelectItem={onSelectItem} othersData={othersData} />}
            {viewMode === 'bar' && <ExposureBarView {...props} displayDecimals={props.data.exposureDecimals} denominatedInAssetId={props.data.denominatedInAssetId} serie={series} labels={labels} selection={chartSelection[props.name]} onSelectItem={onSelectItem} othersData={othersData} />}
            <ExposureTableView {...props} displayDecimals={props.data.exposureDecimals} data={props.data.categories} selection={selection[props.name]} onSelectItem={onSelectTableItem} />
        </Card>
    )
}
