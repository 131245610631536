import React from "react";
import { Route } from "react-router-dom";
import { AccountGroupCreate, AccountGroupEdit, AccountGroupList, AccountGroupShow } from "./pages/accountgroups";
import { AccountingList } from "./pages/accounting/list";
import { AccountCreate, AccountEdit, AccountList, AccountShow } from "./pages/accounts";
import { AlertEdit, AlertList, AlertShow } from "./pages/alerts";
import { ClosedAlertList } from "./pages/alerts/closed_list";
import { AssetClassEdit, AssetClassList } from "./pages/assetClass";
import { AssetCreate, AssetEdit, AssetList, AssetShow } from "./pages/assets";
import { BankAccountEdit, BankAccountShow } from "./pages/bankaccounts";
import { DashboardPage } from "./pages/dashboard";
import { ExchangeCreate, ExchangeEdit, ExchangeList, ExchangeShow } from "./pages/exchanges";
import BreachesPage from "./pages/exposure/breaches";
import UtilizationPage from "./pages/exposure/utilization";
import { FeeList } from "./pages/fees/list";
import { LendingCreate, LendingEdit, LendingList, LendingShow } from "./pages/lendings";
import { MarginCallPolicyCreate, MarginCallPolicyEdit, MarginCallPolicyList, MarginCallPolicyShow } from "./pages/margincallpolicies";
import { MarginCallList } from "./pages/margincalls";
import { OrderCreate, OrderEdit, OrderList, OrderShow } from "./pages/orders";
import { ClosedOrderList } from "./pages/orders/closed_list";
import { OverrideCreate, OverrideEdit, OverrideList, OverrideShow } from "./pages/overrides";
import { PairCreate, PairEdit, PairList, PairShow } from "./pages/pair";
import { PairCategoryCreate, PairCategoryEdit, PairCategoryList, PairCategoryShow } from "./pages/pairCategory";
import { PositionEdit, PositionList, PositionShow } from "./pages/position";
import { ClosedPositionList } from "./pages/position/closedPositionList";
import { MarginManagementList } from "./pages/riskManagement/list";
import { StaffUserCreate, StaffUserEdit, StaffUserList, StaffUserShow, } from "./pages/staffUserAccessControl";
import { TransactionCreate, TransactionEdit, TransactionList, TransactionShow } from "./pages/transactions";
import { UserGroupCreate, UserGroupEdit, UserGroupList, UserGroupShow } from "./pages/userGroups";
import { UserCreate, UserEdit, UserList, UserShow } from "./pages/users";
import { SessionsList } from "./pages/sessions";
import { LoginHistoryList } from "./pages/loginhistory";
import { UserInfo } from "./pages/users/sub/userInfo";
import { UserDocuemnts } from "./pages/users/sub/userDocuments";
import { UserBankAccounts } from "./pages/users/sub/userBankAccounts";
import { UserAccounts } from "./pages/users/sub/userAccounts";
import { UserSessions } from "./pages/users/sub/userSessions";
import { UserLoginHistory } from "./pages/users/sub/userLoginHistory";
import { LogList } from "./pages/log";
import { UserLog } from "./pages/users/sub/userLog";
import { AccountInfo } from "./pages/accounts/sub/info";
import { AccountPositions } from "./pages/accounts/sub/positions";
import { AccountOpenOrders } from "./pages/accounts/sub/openOrders";
import { AccountOrders } from "./pages/accounts/sub/orders";
import { AccountTransactions } from "./pages/accounts/sub/transactions";
import { AccountAlerts } from "./pages/accounts/sub/alerts";
import { AccountActiveAlerts } from "./pages/accounts/sub/activeAlerts";
import { AccountLog } from "./pages/accounts/sub/accountLog";
import { AccountOpenPositions } from "./pages/accounts/sub/openPositions";
import { AssetClassCreate } from "./pages/assetClass/create";
import { RiskPage } from "./pages/dashboard/risk/riskPage";


export const resourceRoutes = <>
    <Route path="/" index element={<DashboardPage />} />
    <Route path="/exposure">
        <Route path="breaches" element={<BreachesPage />} />
        <Route path="utilization" element={<UtilizationPage />} />
        <Route path="risk" element={<RiskPage />} />
    </Route>
    <Route path="/override">
        <Route path="list" element={<OverrideList />} />
        <Route path="create" element={<OverrideCreate />} />
        <Route path="edit/:id" element={<OverrideEdit />} />
        <Route path="show/:id" element={<OverrideShow />} />
    </Route>
    <Route path="/fee">
        <Route path="list" element={<FeeList />} />
        <Route path="create" element={<OverrideCreate />} />
        <Route path="edit/:id" element={<OverrideEdit />} />
        <Route path="show/:id" element={<OverrideShow />} />
    </Route>
    <Route path="/asset">
        <Route path="" element={<AssetList />} />
        <Route path="create" element={<AssetCreate />} />
        <Route path=":id/edit" element={<AssetEdit />} />
        <Route path=":id/show" element={<AssetShow />} />
        <Route path=":id/show/:tab" element={<AssetShow />} />
        <Route path=":id/lending">
            <Route path="" element={<LendingList />} />
            <Route path="create" element={<LendingCreate />} />
            <Route path="edit/:lendingId" element={<LendingEdit />} />
            <Route path="show/:lendingId" element={<LendingShow />} />
        </Route>
    </Route>
    <Route path="/assetClass">
        <Route path="" element={<AssetClassList />} />
        <Route path="create" element={<AssetClassCreate />} />
        <Route path=":id/edit" element={<AssetClassEdit />} />
    </Route>
    <Route path="/user">
        <Route path="list" element={<UserList />} />
        <Route path="create" element={<UserCreate />} />
        <Route path="edit/:id" element={<UserEdit />} />
        <Route path="show/:id" element={<UserShow />}>
            <Route path="details" element={<UserInfo />} />
            <Route path="documents" element={<UserDocuemnts />} />
            <Route path="bankAccounts" element={<UserBankAccounts />} />
            <Route path="accounts" element={<UserAccounts />} />
            <Route path="sessions" element={<UserSessions />} />
            <Route path="loginHistory" element={<UserLoginHistory />} />
            <Route path="log" element={<UserLog />} />
        </Route>
        <Route path="show/:id/bankAccounts/edit/:bankAccountId" element={<BankAccountEdit />} />
        <Route path="show/:id/bankAccounts/show/:bankAccountId" element={<BankAccountShow />} />
    </Route>
    <Route path="/accountGroup">
        <Route path="list" element={<AccountGroupList />} />
        <Route path="create" element={<AccountGroupCreate />} />
        <Route path="edit/:id" element={<AccountGroupEdit />} />
        <Route path="show/:id" element={<AccountGroupShow />} />
    </Route>
    <Route path="/userGroup">
        <Route path="list" element={<UserGroupList />} />
        <Route path="create" element={<UserGroupCreate />} />
        <Route path="edit/:id" element={<UserGroupEdit />} />
        <Route path="show/:id" element={<UserGroupShow />} />
    </Route>
    <Route path="/staffUser">
        <Route path="list" element={<StaffUserList />} />
        <Route path="create" element={<StaffUserCreate />} />
        <Route path="edit/:id" element={<StaffUserEdit />} />
        <Route path="show/:id" element={<StaffUserShow />} />
    </Route>
    <Route path="/account">
        <Route path="list" element={<AccountList />} />
        <Route path="create" element={<AccountCreate />} />
        <Route path="edit/:id" element={<AccountEdit />} />
        <Route path="show/:id" element={<AccountShow />}>
            <Route path="info" element={<AccountInfo />} />
            <Route path="open_positions" element={<AccountOpenPositions />} />
            <Route path="positions" element={<AccountPositions />} />
            <Route path="open_orders" element={<AccountOpenOrders />} />
            <Route path="orders" element={<AccountOrders />} />
            <Route path="transactions" element={<AccountTransactions />} />
            <Route path="active_alerts" element={<AccountActiveAlerts />} />
            <Route path="alerts" element={<AccountAlerts />} />
            <Route path="log" element={<AccountLog />} />
        </Route>
    </Route>
    <Route path="/open_order">
        <Route path="list" element={<OrderList />} />
        <Route path="create" element={<OrderCreate />} />
        <Route path="edit/:id" element={<OrderEdit />} />
        <Route path="show/:id" element={<OrderShow />} />
    </Route>
    <Route path="/order">
        <Route path="list" element={<ClosedOrderList />} />
        <Route path="show/:id" element={<OrderShow />} />
    </Route>
    <Route path="/open_position">
        <Route path="list" element={<PositionList />} />
        <Route path="edit/:id" element={<PositionEdit />} />
        <Route path="show/:id" element={<PositionShow />} />
    </Route>
    <Route path="/position">
        <Route path="list" element={<ClosedPositionList />} />
        <Route path="show/:id" element={<PositionShow />} />
    </Route>
    <Route path="/active_alert">
        <Route path="list" element={<AlertList />} />
        <Route path="edit/:id" element={<AlertEdit />} />
        <Route path="show/:id" element={<AlertShow />} />
    </Route>
    <Route path="/alert">
        <Route path="list" element={<ClosedAlertList />} />
        <Route path="show/:id" element={<AlertShow />} />
    </Route>
    <Route path="/pairCategory">
        <Route path="list" element={<PairCategoryList />} />
        <Route path="create" element={<PairCategoryCreate />} />
        <Route path="edit/:id" element={<PairCategoryEdit />} />
        <Route path="show/:id" element={<PairCategoryShow />} />
    </Route>
    <Route path="/pair">
        <Route path="list" element={<PairList />} />
        <Route path="create" element={<PairCreate />} />
        <Route path="edit/:id" element={<PairEdit />} />
        <Route path="show/:id" element={<PairShow />} />
    </Route>
    <Route path="/exchange">
        <Route path="list" element={<ExchangeList />} />
        <Route path="create" element={<ExchangeCreate />} />
        <Route path="edit/:id" element={<ExchangeEdit />} />
        <Route path="show/:id" element={<ExchangeShow />} />
    </Route>
    <Route path="/transaction">
        <Route path="list" element={<TransactionList />} />
        <Route path="create" element={<TransactionCreate />} />
        <Route path="edit/:id" element={<TransactionEdit />} />
        <Route path="show/:id" element={<TransactionShow />} />
    </Route>
    <Route path="/marginCall" element={<MarginCallList />} />
    <Route path="/marginCallPolicy">
        <Route path="list" element={<MarginCallPolicyList />} />
        <Route path="create" element={<MarginCallPolicyCreate />} />
        <Route path="edit/:id" element={<MarginCallPolicyEdit />} />
        <Route path="show/:id" element={<MarginCallPolicyShow />} />
    </Route>
    <Route path="/management">
        <Route path="/management/margin" element={<MarginManagementList />} />
    </Route>
    <Route path="/accounting" element={<AccountingList />} />
    <Route path="/sessions" element={<SessionsList />} />
    <Route path="/loginhistory" element={<LoginHistoryList />} />
    <Route path="/log" element={<LogList />} />
</>
