import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import { MarginCallPolicyForm } from "./form";

export const MarginCallPolicyCreate: React.FC<
    IResourceComponentsProps
> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <MarginCallPolicyForm formProps={formProps} />
        </Create>
    );
};
