import { useTranslate } from '@refinedev/core';
import React from 'react';


interface FlagFieldProps<Flags> {
  value: number;
  enum: Flags;
  name: string;
}


function FlagField<T>({ value, enum: enumObject, name }: FlagFieldProps<T>) {
  const translate = useTranslate();
  const renderFlags = () => {
    const flags: string[] = [];

    for (const flag in enumObject) {
      if (value & enumObject[flag as never]) {
        flags.push(flag);
      }
    }

    return flags.map((flag) => <div key={flag}>{translate(`enums.${name}.${flag}`)}</div>);
  };

  return <div>{renderFlags()}</div>;
};


export default FlagField;