import {
  useTable
} from "@refinedev/antd";
import {
  IResourceComponentsProps
} from "@refinedev/core";
import React from "react";
import { List } from "../../components/crud/list";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { ClosedPositionsTable } from "./closedPositionsTable";

export const ClosedPositionList: React.FC<IResourceComponentsProps> = () => {
  const { search, listProps } = useSearchList();

  const query: Record<string, string> = {};
  if (search) {
    query.tokenLIKE = search; // TODO: search
  }
  const { tableProps, sorters, filters } = useTable({
    initialPageSize: defaultPageSize,
    resource: 'position',
    meta: { query },
    sorters: {
      initial: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
    },
    filters: {
      permanent: [
        {
          field: 'status',
          operator: 'in',
          value: ['Closed',]
        }
      ]
    }
  });

  return (
    <List {...listProps}>
      <ClosedPositionsTable tableProps={tableProps} withIdentity={true} sorters={sorters} filters={filters} />
    </List>
  );
};
