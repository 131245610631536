import { useTranslate } from "@refinedev/core";
import { Card, Checkbox, Form, FormProps, Input, Space } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useState } from "react";
import { useExposureFormItems } from "../../components/exposureLimits";
import { defaultFormLayout } from "../../constants";
import { AssetClassesEditGroups, AssetClassesEnumMap, AssetClassesEnumNames } from "../../interfaces";



interface OnChangeHandler {
    (e: any): void;
}
interface FlagInputProps {
    disabled: boolean;
    value?: number;
    onChange?: OnChangeHandler;
}

/**
 * Renders a set of checkboxes based on the provided AssetClassesEnumMap, and updates the state value when a checkbox is checked or unchecked.
 *
 * @param {FlagInputProps} props - An object containing the following properties:
 *   - value: The initial value for the state (default: 0)
 *   - onChange: A callback function to be called when the state value changes
 *   - disabled: A boolean indicating whether the checkboxes should be disabled
 * @return {JSX.Element} A div containing a set of checkboxes
 */
const AssetClassEnumValuesInput = ({ value, onChange, disabled }: FlagInputProps) => {
    const [retValue, setValue] = useState(value || 0);
    const translate = useTranslate();

    const groups = Object.entries(AssetClassesEditGroups).map(([name, group]) => {
        return {
            title: translate(`asset.AssetClassesEditGroups.${name}`),
            items: AssetClassesEnumMap.filter(([key, v]) => v & group).map(([key, v]) => ({ value: v, label: translate(`enums.AssetClassesEnum.${key}`) }))
        }
    });


    const change = (event: CheckboxChangeEvent) => {
        const newValue = event.target.checked ? retValue | event.target.value : retValue & ~event.target.value;
        setValue(newValue);
        if (typeof onChange === 'function') {
            console.log(newValue);
            onChange(newValue);
        }
    };

    return (<Space direction="horizontal" align="start">
        {groups.map((group) => (
            <Card key={group.title} title={group.title} size="small">
                {group.items.map((item) => (
                    <div key={item.value}>
                        <Checkbox disabled={disabled} onChange={change} value={item.value} checked={(retValue! & item.value) !== 0}>{item.label}</Checkbox>
                    </div>
                ))}
            </Card>
        ))}
    </Space>)
};

export const AssetClassForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();
    const exposureItems = useExposureFormItems();

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Form.Item
                label={translate("asset.fields.name")}
                name={["name"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name={["id"]}
                label={translate("asset.fields.flags")}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <AssetClassEnumValuesInput disabled={formProps.initialValues?.id} />
            </Form.Item>
            {exposureItems}
        </Form>
    );
};
