import { useTranslate } from "@refinedev/core";
import { Checkbox, Form, FormProps, Input, InputNumber } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";
import { defaultFormLayout } from "../../constants";

export const PositionForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const isTrailingStopLoss = Form.useWatch('isTrailingStopLoss', formProps.form);
    const isTrailingTakeProfit = Form.useWatch('isTrailingTakeProfit', formProps.form);

    const onChangeTrailing = (checked: boolean, name: string) => {
        const trailingName = `trailing${name}`;
        const totalName = `total${name}`;
        let totalValue, trailingValue;

        if (checked) {
            totalValue = null;
            trailingValue = formProps.form?.getFieldValue(totalName);
        } else {
            totalValue = formProps.form?.getFieldValue(trailingName);
            trailingValue = null;
        }
        formProps.form?.setFieldsValue({
            [totalName]: totalValue,
            [trailingName]: trailingValue
        })
    };

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Form.Item
                label={translate("position.fields.stopLoss")}
                name={["totalStopLoss"]}
                hidden={isTrailingStopLoss}
            >
                <InputNumber />
            </Form.Item>

            <Form.Item
                label={translate("position.fields.stopLoss")}
                name={["trailingStopLoss"]}
                hidden={!isTrailingStopLoss}
            >
                <InputNumber />
            </Form.Item>


            <Form.Item
                label={translate("position.fields.trailingStopLoss")}
                name={["isTrailingStopLoss"]}
                valuePropName="checked"
            >
                <Checkbox onChange={(e: CheckboxChangeEvent) => onChangeTrailing(e.target.checked, "StopLoss")} />
            </Form.Item>



            <Form.Item
                label={translate("position.fields.takeProfit")}
                name={["totalTakeProfit"]}
                hidden={isTrailingTakeProfit}
            >
                <InputNumber />
            </Form.Item>

            <Form.Item
                label={translate("position.fields.takeProfit")}
                name={["trailingTakeProfit"]}
                hidden={!isTrailingTakeProfit}

            >
                <InputNumber />
            </Form.Item>

            <Form.Item
                label={translate("position.fields.trailingTakeProfit")}
                name={["isTrailingTakeProfit"]}
                valuePropName="checked"
            >
                <Checkbox onChange={(e: CheckboxChangeEvent) => onChangeTrailing(e.target.checked, "TakeProfit")} />
            </Form.Item>

            <Form.Item name={["id"]} >
                <Input type="hidden" />
            </Form.Item>
        </Form>
    );
};
