import { useTable } from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useTranslate
} from "@refinedev/core";
import { DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { List } from "../../components/crud/list";
import { LogRecord } from "../../interfaces/logRecord";
import { LogTable } from "./table";
import dayjs, { Dayjs } from 'dayjs';
import { getRangePresets } from "../../utils";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
const { RangePicker } = DatePicker;


export const LogList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
    const rangePresets = getRangePresets(translate);
    const { search, listProps } = useSearchList();

    const query: Record<string, string> = {};
    if (dateRange[0]) {
        query.timestampGT = dateRange[0].utc().toISOString();
    }
    if (dateRange[1]) {
        query.timestampLT = dateRange[1].utc().toISOString();
    }
    if (search) {
        query.dispositionLIKE = search;
    }

    const { tableProps, sorters, setCurrent } = useTable<LogRecord>({
        resource: "log",
        syncWithLocation: true,
        initialPageSize: defaultPageSize,
        sorters: {
            initial: [
                {
                    field: "timestamp",
                    order: "desc",
                },
            ],
        },
        meta: { query }
    });

    useEffect(() => {
        setCurrent(1);
    }, [search, dateRange]);


    const extraButtons = (headerProps: any) => {
        return (
            <RangePicker presets={rangePresets} showTime value={dateRange} onChange={(a1, a2) => a1 && setDateRange([a1[0]!, a1[1]!])} />
        );
    }

    return (
        <List extraButtons={extraButtons} autorefresh={true} {...listProps}>
            <LogTable tableProps={tableProps} sorters={sorters} />
        </List>
    );
};
