import { getDefaultSortOrder } from "@refinedev/antd";
import { CrudSort, useTranslate } from "@refinedev/core";
import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { DateField } from "../../components/fields";
import { IPField } from "../../components/fields/IPField";
import { LoginEvent } from "../../interfaces";

type LoginLogDetailsProps = {
    tableProps: any;
    sorters: CrudSort[];
};

export const LoginHistoryTable: React.FC<LoginLogDetailsProps> = ({ tableProps, sorters }) => {
    const translate = useTranslate();

    return (
        <Table {...tableProps} rowKey="id">
            <Table.Column
                dataIndex="userToken"
                title={translate("loginhistory.fields.userToken")}
                render={(value: string, record: LoginEvent) => <Link to={`/user/show/${record.userId}/details`} className="inline-flex"><span>{value}</span></Link>}
            />

            <Table.Column
                dataIndex={["details", "ip"]}
                title={translate("loginhistory.fields.ipAddress")}
                render={(value: string, record: LoginEvent) => <IPField value={value} />}
            />
            <Table.Column
                dataIndex={["at"]}
                sorter={true}
                title={translate("loginhistory.fields.connectionEstablished")}
                defaultSortOrder={getDefaultSortOrder("at", sorters)}
                render={(value: string, record: LoginEvent) => <DateField value={value} format="LLL" />}
            />
        </Table>
    );
};