import { Account, TradingModeNamed } from "./account";
import { AccountGroup } from "./accountGroup";
import { AssetClassesEnum } from "./assetClass";
import { LyraSequential } from "./base";
import { Pair } from "./pair";

export enum FeeChargeTiming {
    Unknown = 'Unknown',
    OpenOnly = 'OpenOnly',
    CloseOnly = 'CloseOnly',
    OpenAndClose = 'OpenAndClose'
}

export interface Override extends LyraSequential {
    orderFeeFloatingBuy: number;
    orderFeeFloatingSell: number;
    orderFeeFixedBuy: number,
    orderFeeFixedSell: number,
    chargeTiming: FeeChargeTiming,
    readonly marginLeverageShort: number,
    readonly marginLeverageLong: number,
    marginRatioShort: number
    marginRatioLong: number
    description: string;
    quoteSpreadAffectAsk: number;
    quoteSpreadAffectBid: number;
    permittedMode: TradingModeNamed;
}

export interface AccountPairOverride {
    accountId: number;
    account: Account;

    pairId: string;
    pair: Pair;

    overrideFeeId: number;
    overrideFee: Override;
}

export interface AccountAssetClassOverride {
    accountId: number;
    account: Account;

    assetClassId: AssetClassesEnum;

    overrideFeeId: number;
    overrideFee: Override;
}


export interface AccountGroupAssetClassOverride {
    accountGroupId: number;
    accountGroup: AccountGroup;

    assetClassId: AssetClassesEnum;

    overrideFeeId: number;
    overrideFee: Override;
}


export interface AccountGroupPairOverride {
    accountGroupId: number;
    accountGroup: AccountGroup;

    pairId: string;
    pair: Pair;

    overrideFeeId: number;
    overrideFee: Override;
}