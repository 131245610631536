import React from "react";

import { EditButton, ShowButton, TextField, useModalForm } from "@refinedev/antd";
import {
    BaseRecord, useTranslate
} from "@refinedev/core";
import { Modal, Space, Table } from "antd";
import { GoodUntilDateField } from "../../components/common/GoodUntilDateField";
import { DateField, EnumField, LogoPairField, NumberField, UnitValueField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { useArrayFilter } from "../../hooks/useArrayFilter";
import { useReferenceData } from "../../hooks/useReferenceFilter";
import { useTableClick } from "../../hooks/useTableClick";
import { Account, Alert } from "../../interfaces";
import { OrderSide, TriggerStatus } from "../../interfaces/triggerable";
import { getEnumFilter } from "../../utils";
import { AlertForm } from "./form";


export const AlertsTable: React.FC<any> = (props) => {
    const translate = useTranslate();

    const statusFilter = getEnumFilter(TriggerStatus, 'TriggerStatus', translate, ["Unknown"]);
    const sideFilter = getEnumFilter(OrderSide, 'OrderSide', translate, ["Unknown"]);

    const pairFilters = useArrayFilter({ resource: 'alerts_pairs', labelName: 'id' })
    const accountsData = useReferenceData({ resource: 'account', ids: props.dataSource?.map((item: Alert) => item.accountId) });
    const pairsHash = useReferenceData({ resource: 'pair/info', ids: props.dataSource?.map((item: Alert) => item.pairId) });

    const clickTableProps = useTableClick({ resource: 'alert', action: 'show' });
    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        open: editModalOpen,
        formLoading,
    } = useModalForm<Alert>({
        resource: "alert",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });

    return <><Table {...props} rowKey="id" {...clickTableProps}>
        <Table.Column
            dataIndex="token"
            title={translate("alert.fields.token")}
        />
        <Table.Column
            dataIndex={["pairId"]}
            title={translate("order.fields.pairId")}
            filters={pairFilters}
            render={(value) => <LogoPairField pairId={value} />}
        />
        <Table.Column
            dataIndex="side"
            title={translate("alert.fields.side")}
            filters={sideFilter}
        />
        <Table.Column
            dataIndex="triggerPrice"
            align="right"
            title={translate("alert.fields.triggerPrice")}
            render={(value: any, record: Alert) => <>{value != 0 && <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}</>}
        />

        <Table.Column
            dataIndex={["goodUntil"]}
            title={translate("alert.fields.goodUntil")}
            render={(value: any) => <GoodUntilDateField value={value} />}
        />
        <Table.Column
            dataIndex="persistentStatus"
            title={translate("order.fields.persistentStatus")}
            filters={statusFilter}
            render={(value: any) => <EnumField enum="TriggerStatus" value={value} translate={translate} />}
        />
        <Table.Column
            dataIndex="cancellationReason"
            title={translate("order.fields.cancellationReason")}
            render={(value: any, record: Alert) => record.persistentStatus == TriggerStatus.Cancelled && <TextField value={value} />}
        />
        {props.withIdentity && <Table.Column
            dataIndex={["accountId"]}
            title={translate("position.fields.account")}
            render={(value) => <AccountField value={accountsData.get(value) as Account} showIdentity={false} link="alerts" />}
        />}
        <Table.Column
            dataIndex={["createdAt"]}
            title={translate("alert.fields.createdAt")}
            render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
            align="end"
            width={100}
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
                <Space>
                    <EditButton
                        hideText
                        size="small"
                        resource="alert"
                        recordItemId={record.id}
                        disabled={record.persistentStatus !== TriggerStatus.Pending}
                        onClick={() => {
                            editModalShow(record.id);
                        }} />
                    <ShowButton
                        hideText
                        size="small"
                        resource="alert"
                        recordItemId={record.id}
                    />
                </Space>
            )}
        />
    </Table>
        {editModalOpen && <Modal {...editModalProps}>
            <AlertForm formProps={editFormProps} />
        </Modal>}
    </>
};
