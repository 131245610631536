import { defaultDateTimeFormat } from "../constants";
import { ExecutionMethod, UserIdentity } from "./base";
import { Position } from "./position";
import { Transaction } from "./transaction";
import { CancellationReason, OrderSide, TriggerStatus, Triggerable } from "./triggerable";
import dayjs from "dayjs";

export enum OrderType {
    Unknown = "Unknown",
    Market = "Market",
    Limit = "Limit",
    Stop = "Stop"
}

export enum OrderPositionAssociation
{
    Unknown = 'Unknown',
    OpenPosition = 'OpenPosition',
    ClosePosition = 'ClosePosition',
    PositionStopLoss = 'PositionStopLoss',
    PositionTakeProfit = 'PositionTakeProfit'
}

export interface Order extends Triggerable {
    type: OrderType;
    quotedPrice: number | null;
    priceTolerance: number | null;
    qty: number;
    referencePositionId: number | null;
    referencePosition: Position | null;
    referenceType: OrderPositionAssociation;
    transactions: Transaction[] | null;
    reservedAmount: number;
    marginRatio: number;
    stopLossTrigger: number | null;
    takeProfitTrigger: number | null;
    stopLossTotal: number | null;
    takeProfitTotal: number | null;
    stopLossTrailing: number | null;
    takeProfitTrailing: number | null;
    connectorId: string;
    routedId: string;

    isTrailingTakeProfit: boolean;
    isTrailingStopLoss: boolean;
}


export class OrderDTO implements Order {
    id: number = 0;
    token: string = '';
    accountId: number = 0;
    identity: UserIdentity | undefined;
    createdByUserId: number = 0;
    createdAt: string = '';
    lastUpdatedAt: string = '';
    pairId: string = '';
    side: OrderSide = OrderSide.Unknown;
    bridgePairId: string | null = null;
    bridgeForward: boolean | null = null;
    goodUntil: Date = new Date();
    persistentStatus: TriggerStatus = TriggerStatus.Unknown;
    cancellationReason: CancellationReason = CancellationReason.Unknown;
    triggerPrice: number = 0;
    executedAt: Date | null = null;
    executionPrice: number | null = null;
    bridgeRate: number | null = null;
    beingProcessed: boolean = false;
    isExecutable: boolean = false;
    type: OrderType = OrderType.Unknown;
    quotedPrice: number | null = null;
    priceTolerance: number | null = null;
    qty: number = 0;
    referencePositionId: number | null = null;
    referencePosition: Position | null = null;
    referenceType: OrderPositionAssociation = OrderPositionAssociation.Unknown;
    transactions: Transaction[] | null = null;
    reservedAmount: number = 0;
    marginRatio: number = 0;
    stopLossTrigger: number | null = null;
    takeProfitTrigger: number | null = null;
    stopLossTotal: number | null = null;
    takeProfitTotal: number | null = null;
    method: ExecutionMethod = ExecutionMethod.Unknown;
    connectorId: string = '';
    routedId: string = '';
    stopLossTrailing: number | null = null;
    takeProfitTrailing: number | null = null;

    isTrailingTakeProfit: boolean;
    isTrailingStopLoss: boolean;

    constructor(orderObject: Partial<Order>) {
        Object.assign(this, orderObject);
        this.isTrailingStopLoss = this.stopLossTrailing !== null && this.stopLossTrailing < 0;
        this.isTrailingTakeProfit = this.takeProfitTrailing !== null && this.takeProfitTrailing > 0;
    }

    get displayName(): string {
        const date = dayjs(this.createdAt).format(defaultDateTimeFormat);
        return `${this.token}: ${this.persistentStatus}: ${this.pairId} ${this.qty} ${date}`;
    }
}