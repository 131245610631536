import { useMemo } from "react"
import {
    useList,
} from "@refinedev/core";
import { ColumnFilterItem } from "antd/lib/table/interface";

export const useFilter = (props: any = {}): ColumnFilterItem[] => {

    const { data } = useList({
        ...props, pagination: {
            mode: 'off'
        }
    });

    const filters = useMemo(() => {
        const valueName = props.valueName || "id";
        const textName = props.labelName || "name";
        return data?.data?.map((item) => { return { text: item[textName], value: item[valueName] } as ColumnFilterItem })
    }, [data]) || [];

    return filters;
}