import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Checkbox, DatePicker } from "antd";
import dayjs from "dayjs";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={translate("user.fields.culture")}
                    name={["culture"]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.userName")}
                    name={["userName"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.email")}
                    name={["email"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    name={["emailConfirmed"]}
                >
                    <Checkbox>{translate("user.fields.emailConfirmed")}</Checkbox>
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.phoneNumber")}
                    name={["phoneNumber"]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    name={["phoneNumberConfirmed"]}
                >
                    <Checkbox>{translate("user.fields.phoneNumberConfirmed")}</Checkbox>
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.twoFactorEnabled")}
                    valuePropName="checked"
                    name={["twoFactorEnabled"]}
                >
                    <Checkbox>Two Factor Enabled</Checkbox>
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.lockoutEnd")}
                    name={["lockoutEnd"]}
                    getValueProps={(value) => ({
                        value: value ? dayjs(value) : undefined,
                    })}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.lockoutEnabled")}
                    valuePropName="checked"
                    name={["lockoutEnabled"]}
                >
                    <Checkbox>Lockout Enabled</Checkbox>
                </Form.Item>
                <Form.Item
                    label={translate("user.fields.accessFailedCount")}
                    name={["accessFailedCount"]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    );
};
