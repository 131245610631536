import { CheckSquareOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import {
    getDefaultSortOrder,
    useTable
} from "@refinedev/antd";
import {
    BaseKey,
    BaseRecord, getDefaultFilter, IResourceComponentsProps,
    LogicalFilter, useOne,
    useTranslate
} from "@refinedev/core";
import { Space, Table, TableColumnsType } from "antd";
import React, { useState } from "react";
import { ClosePositionButton } from "../../components/buttons/closePosition";
import { MarginCallActionButton } from "../../components/buttons/marginCallAction";
import { List } from "../../components/crud/list";
import { DateField, NumberField, UnitValueField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { EnumField } from "../../components/fields/EnumField";
import { useReferenceData } from "../../hooks/useReferenceFilter";
import { Account, Asset, MarginCall, MarginCallKind, MarginCallStatus, MarginPolicyWorkflowType, Position } from "../../interfaces";
import { getEnumFilter } from "../../utils";


type MarginCallItemProps = {
    item: MarginCall;
    assets: Map<BaseKey, Asset>;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: (keys: React.Key[]) => void;
}

const MarginCallPositionsList: React.FC<MarginCallItemProps> = ({ item, assets, selectedRowKeys, setSelectedRowKeys }) => {
    const translate = useTranslate();

    const { data, isLoading } = useOne({
        resource: 'OpenPosition',
        id: item.account.id,
        meta: {
            endpoint: `UserActions/${item.account.id}/OpenPositions`
        }
    });

    const asset = assets.get(item.account.denominatedInAssetId);
    const currency = asset?.symbol;
    const digits = asset?.decimals || 2;

    const columns: TableColumnsType<Position> = [
        { title: 'Pair', dataIndex: 'pairId', key: 'pairId' },
        {
            title: 'Side',
            key: 'side',
            dataIndex: 'side',
        },
        {
            title: translate("position.fields.currentMargin"),
            key: 'currentMargin',
            dataIndex: 'currentMargin',
            align: 'right',
            render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
        },
        {
            title: translate("position.fields.currentValue"),
            key: 'currentValue',
            dataIndex: 'currentValue',
            align: 'right',
            render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
        },
        {
            title: translate("position.fields.unrealizedPnL"),
            key: 'unrealizedPnL',
            dataIndex: 'unrealizedPnL',
            align: 'right',
            render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
        },
        {
            title: translate("position.fields.entranceCost"),
            key: 'entranceCost',
            dataIndex: 'entranceCost',
            align: 'right',
            render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
        },
        {
            title: 'Notional',
            key: 'notional',
            dataIndex: 'notional',
            align: 'right',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value: string) => <DateField value={value} format="lll" />
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, record: BaseRecord) => (
                <Space>
                    <ClosePositionButton
                        size="small"
                        resource="position"
                        ids={[record.id]}
                        confirmOkText={translate("management_margin.buttons.confirm_cancel")}
                    >{translate("management_margin.buttons.cancel_one")}</ClosePositionButton>
                </Space>
            )
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return <Table
        loading={isLoading}
        size="small"
        rowKey="id"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.data.positions as Position[]}
        pagination={false}
    />;
}


export const MarginCallList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { tableProps, filters, sorters } = useTable<MarginCall>({
        syncWithLocation: true,
        filters: {
            initial: [{
                field: 'status',
                operator: "in",
                value: [MarginCallStatus.Pending],
            },
            {
                field: 'kind',
                operator: "in",
                value: [MarginCallKind.Liquidation],
            }
            ] as LogicalFilter[]
        },
        sorters: {
            initial: [
                {
                    field: "marginIndicator",
                    order: "asc",
                },
            ],
        }
    });

    const assetData = useReferenceData<Asset>({ resource: "asset", ids: tableProps?.dataSource?.map((item) => item?.account?.denominatedInAssetId) ?? [] })

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const statusFilter = getEnumFilter(MarginCallStatus, 'MarginCallStatus', translate, [MarginCallStatus.Unknown]);
    const kindFilter = getEnumFilter(MarginCallKind, 'MarginCallKind', translate, [MarginCallKind.Unknown]);

    // const onRow = (record: BaseRecord, rowIndex?: number) => {
    //     return {
    //         onClick: (event: any) => {
    //             console.log(event, record);

    //         },
    //     };
    // }


    const expandedRowRender = (record: MarginCall) => {
        return <MarginCallPositionsList item={record} assets={assetData} selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} />;
    };

    return (
        <List autorefresh={true}>
            <ClosePositionButton
                resource="position"
                disabled={!hasSelected}
                ids={selectedRowKeys}
                confirmOkText={translate("management_margin.buttons.confirm_cancel")}
            >
                {translate("management_margin.buttons.delete")}
            </ClosePositionButton>
            <Table
                // onRow={onRow}
                {...tableProps}
                rowKey="id"
                size="small"
                expandable={{ expandedRowRender, expandRowByClick: true }}
                rowClassName="clickable-row"
            >
                <Table.Column
                    dataIndex={["account"]}
                    sorter={true}
                    title={translate("marginCall.fields.account")}
                    render={(value: Account) => <AccountField value={value} link="margincall" showCurrency={false} />}
                />
                <Table.Column
                    dataIndex="cash"
                    sorter={true}
                    title={translate("marginCall.fields.cash")}
                    align="right"
                    render={(value: number, record: MarginCall) => <NumberField value={value} digits={record.account?.denominatedInAsset?.decimals || 2} />}
                />
                <Table.Column
                    dataIndex="status"
                    sorter={true}
                    filters={statusFilter}
                    defaultFilteredValue={getDefaultFilter("status", filters, "in")}
                    title={translate("marginCall.fields.status")}
                    render={(value: MarginCallStatus) => <EnumField enum="MarginCallStatus" value={value} translate={translate} />}
                />
                <Table.Column
                    dataIndex="kind"
                    sorter={true}
                    filters={kindFilter}
                    defaultFilteredValue={getDefaultFilter("kind", filters, "in")}
                    title={translate("marginCall.fields.kind")}
                    render={(value: MarginCallKind) => <EnumField enum="MarginCallKind" value={value} translate={translate} />}
                />
                <Table.Column
                    dataIndex="positionCount"
                    sorter={true}
                    title={translate("marginCall.fields.positionCount")}
                    align="right"
                    render={(value: number) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="unrealizedPnl"
                    sorter={true}
                    align="right"
                    title={translate("marginCall.fields.unrealizedPnl")}
                    render={(value: number, record: MarginCall) => <NumberField value={value} digits={record.account?.denominatedInAsset?.decimals || 2} />}
                />
                <Table.Column
                    dataIndex="totalMargin"
                    sorter={true}
                    align="right"
                    title={translate("marginCall.fields.totalMargin")}
                    render={(value: number, record: MarginCall) => <NumberField value={value} digits={record.account?.denominatedInAsset?.decimals || 2} />}
                />
                <Table.Column
                    dataIndex="totalValue"
                    sorter={true}
                    align="right"
                    title={translate("marginCall.fields.totalValue")}
                    render={(value: number, record: MarginCall) => <NumberField value={value} digits={record.account?.denominatedInAsset?.decimals || 2} />}
                />
                <Table.Column
                    dataIndex="netEquity"
                    sorter={true}
                    align="right"
                    title={translate("marginCall.fields.netEquity")}
                    render={(value: number, record: MarginCall) => <NumberField value={value} digits={record.account?.denominatedInAsset?.decimals || 2} />}
                />
                <Table.Column
                    dataIndex="availableToTrade"
                    sorter={true}
                    align="right"
                    title={translate("marginCall.fields.availableToTrade")}
                    render={(value: number, record: MarginCall) => <NumberField value={value} digits={record.account?.denominatedInAsset?.decimals || 2} />}
                />
                <Table.Column
                    dataIndex="marginIndicator"
                    sorter={true}
                    align="right"
                    defaultSortOrder={getDefaultSortOrder("marginIndicator", sorters)}
                    title={translate("marginCall.fields.marginIndicator")}
                    render={(value: number) => <UnitValueField value={value * 100} unit="%" digits={2} />}
                />
                <Table.Column
                    dataIndex={["issuedOn"]}
                    sorter={true}
                    title={translate("marginCall.fields.issuedOn")}
                    render={(value: string) => <DateField value={value} format="LLL" />}
                />
                <Table.Column
                    dataIndex={["userNotifiedOn"]}
                    sorter={true}
                    title={translate("marginCall.fields.userNotifiedOn")}
                    render={(value: string) => <DateField value={value} format="LLL" />}
                />
                <Table.Column
                    dataIndex={["resolvedOn"]}
                    sorter={true}
                    title={translate("marginCall.fields.resolvedOn")}
                    render={(value: string) => <DateField value={value} format="LLL" />}
                />
                <Table.Column
                    dataIndex={["policy", "description"]}
                    title={translate("marginCall.fields.policy")}
                />
                <Table.Column
                    align="right"
                    width={130}
                    dataIndex={["policy", "workflowType"]}
                    render={(value, record: MarginCall) => {
                        if (value !== MarginPolicyWorkflowType.Manual) {
                            return <></>
                        }
                        return (<Space>
                            <MarginCallActionButton
                                size="small"
                                icon={<CheckSquareOutlined />}
                                recordItemId={record.id}
                                action="closeout"
                            />
                            <MarginCallActionButton
                                size="small"
                                icon={<EyeInvisibleOutlined />}
                                recordItemId={record.id}
                                action="ignore"
                            />
                        </Space>
                        )
                    }}
                />
            </Table>
        </List>
    );
};
