import React from "react";

import { UserIdentity } from "../../interfaces";


type IdentityFieldProps = {
    value?: UserIdentity;
    hideCurrency?: boolean;
}

export const IdentityField: React.FC<IdentityFieldProps> = (props) => {
    if (!props.value) {
        return <></>
    }

    return (
        <div className="inline-flex">{!props.hideCurrency && <span>{props.value.denominatedIn}:</span>}<span>{props.value.fullName}</span></div>
    );
};
