import React from 'react';

import { useOne, useTable } from '@refinedev/core';
import useAutoRefresh from '../../../hooks/useAutoRefresh';
import { Account } from '../../../interfaces/account';
import { ActiveAlertsTable } from '../../alerts/activeAlertsTable';
import { Alert } from '../../../interfaces';
import { useOutletContext } from 'react-router-dom';

export const AccountActiveAlerts: React.FC = React.memo(() => {
    const { account } = useOutletContext<{ account: Account }>();
    const tableProps = useTable<Alert>({
        resource: 'ActiveAlerts',
        meta: {
            query: { accountId: account.id },
            endpoint: `UserActions/${account.id}/ActiveAlerts`
        }
    });

    useAutoRefresh({resource: 'ActiveAlerts'});

    return (
        <>
            <ActiveAlertsTable {...tableProps} withIdentity={false} />
        </>
    );
});