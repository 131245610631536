import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Card, Checkbox, Col, Form, FormProps, Input, InputNumber, Row, Select } from "antd";
import React from "react";
import { useExposureFormItems } from "../../components/exposureLimits";
import { defaultFormLayout } from "../../constants";

export const AssetForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();
    const { selectProps: assetClassSelectProps } = useSelect({ resource: "assetClass", optionLabel: "name" });
    const exposureItems = useExposureFormItems();

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Row>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                    <Card title={translate("pair.fields.basic")} className="mb-24" size="small">
                        <Form.Item
                            label={translate("asset.fields.name")}
                            name={["name"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("asset.fields.assetClass")}
                            name={["assetClassId"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...assetClassSelectProps} />
                        </Form.Item>
                        <Form.Item
                            label={translate("asset.fields.description")}
                            name={["description"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("asset.fields.symbol")}
                            name={["symbol"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            valuePropName="checked"
                            name={["enabled"]}
                        >
                            <Checkbox>{translate("asset.fields.enabled")}</Checkbox>
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                    <div >
                        <Card title={translate("pair.fields.decimals")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("asset.fields.decimals")}
                                name={["decimals"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("asset.fields.displayDecimals")}
                                name={["displayDecimals"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>

                        <Card title={translate("asset.fields.hedge")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("asset.fields.hedgePosition")}
                                name={["hedgePosition"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Card>
                    </div>
                </Col>
                <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                    {exposureItems}
                </Col>
            </Row>
        </Form>
    );
};
