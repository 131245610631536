
import {
    useOne, useTranslate
} from "@refinedev/core";
import { Card, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Asset, ExposureEntryMetrics } from "../../../interfaces";
import { RisksHistoryComponent } from "./components/history";
import { RatiosComponent } from "./components/ratios";
import { VaRComponent } from "./components/var";

import './riskPage.scss';

const { Title } = Typography;

export const RiskPage: React.FC = () => {
    const translate = useTranslate();

    const [currentEntry, setCurrentEntry] = useState<ExposureEntryMetrics | null>(null);

    const { data: latestEntry, isLoading: isLoadingLatest } = useOne<ExposureEntryMetrics>({
        resource: 'latestEntry',
        queryOptions: { enabled: true, },
    });

    const { data: asset, isLoading: isAssetLoading } = useOne<Asset>({
        resource: "asset",
        id: latestEntry?.data.denominatedIn.accountingCurrency,
    });

    useEffect(() => {
        if (latestEntry?.data) {
            setCurrentEntry(latestEntry.data);
        }
    }, [latestEntry]);

    if (!currentEntry) {
        return <Spin spinning={isLoadingLatest} />
    }

    return (
        <Spin spinning={isLoadingLatest || isAssetLoading}>
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
                <Card title={<Title level={3}>{translate('var.fields.risk', { currency: currentEntry?.denominatedIn.accountingCurrency })}</Title>}>
                    <VaRComponent currentEntry={currentEntry} setCurrentEntry={setCurrentEntry} asset={asset?.data} />
                </Card>

                <Card title={<Title level={3}>{translate('var.fields.ratios', { currency: currentEntry?.denominatedIn.accountingCurrency })}</Title>}>
                    <RatiosComponent currentEntry={currentEntry} setCurrentEntry={setCurrentEntry} asset={asset?.data} />
                </Card>

                <RisksHistoryComponent />
            </Space>
        </Spin >
    )
};
