import { useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Edit } from "../../components/crud/edit";
import { Alert } from "../../interfaces";
import { AlertForm } from "./form";

export const AlertEdit: React.FC<IResourceComponentsProps> = () => {

    const { formProps, saveButtonProps } = useForm<Alert>(
        {
            resource: "activealerts",
            action: "edit",
            mutationMeta: {
                endpoint: `useractions/updatealert`,
            },
            warnWhenUnsavedChanges: true,
            invalidates: ["list", "detail"]
        }
    );


    return (
        <Edit saveButtonProps={saveButtonProps}>
            <AlertForm formProps={formProps} />
        </Edit>
    );
};

