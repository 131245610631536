import { ExecutionMethod, LyraUacObject } from "./base";

export enum TriggerStatus {
    Unknown = 0,
    Ticket = 16,
    Pending = 512,
    Executing = 1024,
    Completed = 16384,
    Cancelled = 33554432
}

export const TriggerStatusMap: [string, TriggerStatus][] = Object.entries(TriggerStatus).filter(([key, value]) => typeof value === 'number' && value > 0) as [string, TriggerStatus][];
export const TriggerStatusNames: Map<TriggerStatus, string> = new Map(TriggerStatusMap.map(([key, value]) => [value, key]));


export enum CancellationReason {
    Unknown = "Unknown",
    UserCancelled = "UserCancelled",
    BrokerCancelled = "BrokerCancelled",
    Expired = "Expired",
    NSF = "NSF",
    WouldExceedMarginReq = "WouldExceedMarginReq",
    NotPermitted = "NotPermitted",
    PriceToleranceExceeded = "PriceToleranceExceeded",
    ProcessingFailure = "ProcessingFailure",
    MalformedTicket = "MalformedTicket",
    OtherReason = "OtherReason"
}

export enum OrderSide {
    Unknown = "Unknown",
    Buy = "Buy",
    Sell = "Sell"
}


export interface Triggerable extends LyraUacObject {
    pairId: string;
    side: OrderSide;
    bridgePairId: string | null;
    bridgeForward: boolean | null;
    goodUntil: Date;
    persistentStatus: TriggerStatus;
    cancellationReason: CancellationReason;
    triggerPrice: number;
    executedAt: Date | null;
    executionPrice: number | null;
    bridgeRate: number | null;
    beingProcessed: boolean;
    isExecutable: boolean;
    method: ExecutionMethod;
}
