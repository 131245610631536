import React from "react";
import { EditButton, ShowButton, TextField, getDefaultSortOrder, useModalForm } from "@refinedev/antd";
import {
    useTranslate,
} from "@refinedev/core";
import { Modal, Space, Table } from "antd";
import { GoodUntilDateField } from "../../components/common/GoodUntilDateField";
import { DateField, EnumField, LogoPairField, NumberField, UnitValueField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { useArrayFilter } from "../../hooks/useArrayFilter";
import { useReferenceData } from "../../hooks/useReferenceFilter";
import { useTableClick } from "../../hooks/useTableClick";
import { Account, ExecutionMethod, Order, OrderPositionAssociation, OrderType } from "../../interfaces";
import { OrderSide, TriggerStatus, TriggerStatusNames } from "../../interfaces/triggerable";
import { getEnumFilter } from "../../utils";
import { OrderForm } from "./form";


export const OrdersTable: React.FC<any> = (props) => {
    const translate = useTranslate();

    const getRowClassName = (record: Order) => {
        return 'default-action-show clickable-row position-' + (record.side === OrderSide.Buy ? 'long' : 'short');
    }

    const typeFilter = getEnumFilter(OrderType, 'OrderType', translate, ["Unknown"]);
    const sideFilter = getEnumFilter(OrderSide, 'OrderSide', translate, ["Unknown"]);
    const statusFilter = getEnumFilter(TriggerStatus, 'TriggerStatus', translate, [TriggerStatus.Unknown]);
    const pairFilters = useArrayFilter({ resource: 'orders_pairs', labelName: 'id' });
    const methodFilter = getEnumFilter(ExecutionMethod, 'ExecutionMethod', translate, ["Unknown"]);

    const accountsHash = useReferenceData({ resource: 'account', ids: props.dataSource?.map((item: Order) => item.accountId) });
    const pairsHash = useReferenceData({ resource: 'pair/info', ids: props.dataSource?.map((item: Order) => item.pairId) });
    // const accountAssetHash = useReferenceData({ resource: 'asset', ids: props.dataSource?.map((item: Order) => item.identity?.denominatedIn) });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        open: isEditModalOpen,
    } = useModalForm<Order>({
        resource: "order",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });

    const clickTableProps = useTableClick({ resource: 'order', action: 'show' });

    const { sorters } = props;

    return <><Table
        {...props}
        rowKey="id"
        {...clickTableProps}
        className="wrap-normal"
        rowClassName={(record: Order) => getRowClassName(record)}
    >
        <Table.Column
            dataIndex="token"
            title={translate("order.fields.token")}
        />
        <Table.Column
            dataIndex={["pairId"]}
            title={translate("order.fields.pairId")}
            render={(value) => <LogoPairField pairId={value} />}
            filters={pairFilters}
        />
        <Table.Column
            dataIndex="type"
            title={translate("order.fields.type")}
            filters={typeFilter}
            render={(value: OrderType) => <EnumField enum="OrderType" value={value} translate={translate} />}
        />
        <Table.Column
            dataIndex="side"
            title={translate("order.fields.side")}
            filters={sideFilter}
            render={(value: OrderSide) => <EnumField enum="OrderSide" value={value} translate={translate} />}
        />
        <Table.Column
            dataIndex="quotedPrice"
            align="end"
            title={translate("order.fields.quotedPrice")}
            render={(value: number, record: Order) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}
        />
        <Table.Column
            dataIndex="qty"
            align="end"
            title={translate("order.fields.qty")}
            render={(value: number, record: Order) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.baseSymbol} />}
        />
        <Table.Column
            dataIndex="referenceType"
            title={translate("order.fields.referenceType")}
            render={(value: OrderPositionAssociation) => <EnumField enum="OrderPositionAssociation" value={value} translate={translate} />}
        />
        <Table.Column
            align="end"
            dataIndex="reservedAmount"
            title={translate("order.fields.reservedAmount")}
            render={(value: number) => <>{value != 0 && <NumberField value={value} />}</>}
        />
        <Table.Column
            align="end"
            dataIndex="marginRatio"
            title={translate("order.fields.marginRatio")}
            render={(value: number) => <NumberField value={value} />}
        />
        <Table.Column
            align="end"
            dataIndex={["goodUntil"]}
            title={translate("order.fields.goodUntil")}
            render={(value: string) => <GoodUntilDateField value={value} />}
        />
        <Table.Column
            dataIndex="persistentStatus"
            title={translate("order.fields.persistentStatus")}
            filters={statusFilter}
            render={(value: TriggerStatus) => <EnumField enum="TriggerStatus" value={TriggerStatusNames.get(value)} translate={translate} />}
        />
        <Table.Column
            dataIndex="cancellationReason"
            title={translate("order.fields.cancellationReason")}
            render={(value: string, record: Order) => record.persistentStatus == TriggerStatus.Cancelled && <TextField value={value} />}
        />
        <Table.Column
            align="end"
            dataIndex="triggerPrice"
            title={translate("order.fields.triggerPrice")}
            render={(value: number, record: Order) => <>{value != 0 && <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}</>}
        />
        <Table.Column
            align="end"
            dataIndex="executionPrice"
            title={translate("order.fields.executionPrice")}
            render={(value: number, record: Order) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}
        />
        {props.withIdentity && <Table.Column
            dataIndex={["accountId"]}
            title={translate("position.fields.account")}
            render={(value) => <AccountField value={accountsHash.get(value) as Account} showIdentity={false} link="orders" />}
        />}

        <Table.Column
            align="end"
            dataIndex="method"
            filters={methodFilter}
            sorter={(a: Order, b: Order) => a.method > b.method ? 1 : -1}
            title={translate("order.fields.method")}
            render={(value: number) => <EnumField value={value} enum="ExecutionMethod" translate={translate} />}
        />
        <Table.Column
            align="end"
            dataIndex="connectorId"
            sorter={(a: Order, b: Order) => a.connectorId > b.connectorId ? 1 : -1}
            title={translate("order.fields.connectorId")}
        />
        <Table.Column
            align="end"
            dataIndex="routedId"
            sorter={(a: Order, b: Order) => a.routedId > b.routedId ? 1 : -1}
            title={translate("order.fields.routedId")}
        />
        <Table.Column
            align="end"
            dataIndex={["createdAt"]}
            title={translate("order.fields.createdAt")}
            sorter={true}
            defaultSortOrder={getDefaultSortOrder("createdAt", sorters)}
            render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
            align="end"
            dataIndex={["executedAt"]}
            title={translate("order.fields.executedAt")}
            render={(value: string) => <DateField value={value} />}
        />

        <Table.Column
            align="end"
            width={100}
            dataIndex="actions"
            render={(_, record: Order) => (
                <Space>
                    <EditButton
                        hideText
                        size="small"
                        resource="order"
                        recordItemId={record.id}
                        disabled={record.persistentStatus != TriggerStatus.Pending}
                        onClick={() => {
                            editModalShow(record.id);
                        }}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        resource="order"
                        recordItemId={record.id}
                    />
                </Space>
            )}
        />
    </Table>
        {isEditModalOpen && <Modal {...editModalProps}>
            <OrderForm formProps={editFormProps} />
        </Modal>}
    </>

};
