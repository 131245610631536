import { defaultDateTimeFormat } from "../constants";
import { ExecutionMethod, LyraUacObject, UserIdentity } from "./base";
import dayjs from "dayjs";

export enum PositionStatus {
    Unknown = 'Unknown',
    Open = 'Open',
    Closed = 'Closed',
    FailedToOpen = 'FailedToOpen',
    ClosureRequested = 'ClosureRequested'
}

export enum PositionSide {
    Unknown = 'Unknown',
    Long = 'Long',
    Short = 'Short'
}

export enum PositionCompexity {
    Unknown = 'Unknown',
    Simple = 'Simple',
    Compound = 'Compound'
}

export interface Position extends LyraUacObject {
    pairId: string;
    bridgePairId: string;
    bridgeForward: boolean;
    bridgeOpening: number;
    bridgeClosing: number;
    status: PositionStatus;
    closedAt: string;
    notional: number;
    bodCost: number;
    side: PositionSide;
    marginRatio: number;
    realizedPnl: number;
    currentMargin: number;
    currentValue: number;
    unrealizedPnL: number;
    totalStopLoss: number;
    totalTakeProfit: number;
    trailingStopLoss: number;
    trailingTakeProfit: number;
    isTrailingStopLoss: boolean;
    isTrailingTakeProfit: boolean;
    beingClosed: number;
    compexity: PositionCompexity;
    quoteMain: number;
    quoteBridge: number;
    entranceCost: number;
    mae: number;
    mfe: number;
    method: ExecutionMethod;
    connectorId: string;
}

export class PositionDTO implements Position {
    id: number = 0;
    token: string = '';
    accountId: number = 0;
    identity?: UserIdentity = undefined;
    createdByUserId: number = 0;
    createdAt: string = '';
    lastUpdatedAt: string = '';
    pairId: string = '';
    bridgePairId: string = '';
    bridgeForward: boolean = false;
    bridgeOpening: number = 0;
    bridgeClosing: number = 0;
    status: PositionStatus = PositionStatus.Unknown;
    closedAt: string = '';
    notional: number = 0;
    bodCost: number = 0;
    side: PositionSide = PositionSide.Unknown;
    marginRatio: number = 0;
    realizedPnl: number = 0;
    currentMargin: number = 0;
    currentValue: number = 0;
    unrealizedPnL: number = 0;
    totalStopLoss: number = 0;
    totalTakeProfit: number = 0;
    trailingStopLoss: number = 0;
    trailingTakeProfit: number = 0;
    beingClosed: number = 0;
    compexity: PositionCompexity = PositionCompexity.Unknown;
    quoteMain: number = 0;
    quoteBridge: number = 0;
    entranceCost: number = 0;
    mae: number = 0;
    mfe: number = 0;
    method: ExecutionMethod = ExecutionMethod.Unknown;
    connectorId: string = '';
    isTrailingTakeProfit: boolean;
    isTrailingStopLoss: boolean;

    constructor(positionObject: Partial<Position>) {
        Object.assign(this, positionObject);
        this.isTrailingTakeProfit = this.trailingTakeProfit > 0;
        this.isTrailingStopLoss = this.trailingStopLoss < 0;
    }
    get displayName(): string {
        const date = dayjs(this.createdAt).format(defaultDateTimeFormat);
        return `${this.token}: ${this.status}: ${this.pairId} ${this.notional} ${date}`;
    }

    // get isTrailingTakeProfit() {
    //     return this.trailingTakeProfit > 0;
    // }

    // get isTrailingStopLoss() {
    //     return this.trailingStopLoss < 0;
    // }
}