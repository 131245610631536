import {
    Show,
    TextField,
} from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useOne,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { StaffUserAccessControl, User, UserGroup } from "../../interfaces";

const StaffUserInfo: React.FC<{ staffUser: StaffUserAccessControl }> = ({ staffUser }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'staffUser',
                    label: translate("staffUser.fields.staffUser"),
                    children: <TextField value={staffUser.staffUser?.email} />,
                },
                {
                    key: 'managedUserGroup',
                    label: translate("staffUser.fields.managedUserGroup"),
                    children: <TextField value={staffUser.managedUserGroup?.name} />,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const StaffUserShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<StaffUserAccessControl>();
    const { data, isLoading } = queryResult;

    const { data: userInfo } = useOne<User>({
        resource: "user",
        id: data?.data.staffUserId,
        queryOptions: {
            enabled: !!data,
        },
    });

    const { data: userGroupInfo } = useOne<UserGroup>({
        resource: "userGroup",
        id: data?.data.managedUserGroupId,
        queryOptions: {
            enabled: !!data,
        },
    });

    const staffUser = data?.data;
    if (staffUser && userInfo) {
        staffUser.staffUser = userInfo.data;
    }

    if (staffUser && userGroupInfo) {
        staffUser.managedUserGroup = userGroupInfo.data;
    }

    return (
        <Show isLoading={isLoading}>
            {staffUser !== undefined && <StaffUserInfo staffUser={staffUser}></StaffUserInfo>}
        </Show>
    );
};
