import React, { useCallback } from "react";
import { ModalProps } from "antd";

import {
    useTranslate, HttpError, BaseRecord, BaseKey,
    useUserFriendlyName,
    useResource, useInvalidate,
    useShow,
    useShowProps,
    GetOneResponse
} from "@refinedev/core";
import { useModal } from "@refinedev/antd";
import { QueryObserverResult } from "@tanstack/react-query";


export type useModalShowFromSFReturnType<
    TData
> = {
    open: boolean;
    show: (id?: BaseKey) => void;
    close: () => void;
    modalProps: ModalProps;
    loading: boolean;
    queryResult?: QueryObserverResult<GetOneResponse<TData>>;
};


export type UseModalShowProps<
    TQueryFnData extends BaseRecord = BaseRecord,
    TError extends HttpError = HttpError,
    TData extends BaseRecord = TQueryFnData,
> = useShowProps<
    TQueryFnData,
    TError,
    TData
>;
/**
 * `useModalForm` hook allows you to manage a form within a modal. It returns Ant Design {@link https://ant.design/components/form/ Form} and {@link https://ant.design/components/modal/ Modal} components props.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/hooks/form/useModalForm} for more details.
 *
 * @typeParam TData - Result data of the query extends {@link https://refine.dev/docs/api-reference/core/interfaceReferences#baserecord `BaseRecord`}
 * @typeParam TError - Custom error object that extends {@link https://refine.dev/docs/api-reference/core/interfaceReferences/#httperror `HttpError`}
 * @typeParam TVariables - Values for params. default `{}`
 *
 *
 */
export const useModalShow = <
    TQueryFnData extends BaseRecord = BaseRecord,
    TError extends HttpError = HttpError,
    TVariables = {},
    TData extends BaseRecord = TQueryFnData,
    TResponse extends BaseRecord = TData,
    TResponseError extends HttpError = TError,
>({
    ...rest
}: UseModalShowProps<
    TQueryFnData,
    TError,
    TData
>): useModalShowFromSFReturnType<
    TData
> => {
    const [initiallySynced, setInitiallySynced] = React.useState(false);
    const invalidate = useInvalidate();

    const {
        resource,
        identifier,
    } = useResource(rest.resource);

    const getUserFriendlyName = useUserFriendlyName();

    const useShowProps = useShow<TQueryFnData, TError, TData>({ ...rest });

    const { queryResult, setShowId, showId } = useShowProps;

    const translate = useTranslate();

    const { show, close, modalProps } = useModal({
        modalProps: {
            open: false,
        },
    });

    const { isLoading: loading, data } = queryResult;

    const sunflowerUseModal: useModalShowFromSFReturnType<TData> = {
        modalProps,
        close,
        open: modalProps.open || false,
        show,
        loading: loading,
        queryResult
    };


    const handleClose = useCallback(() => {
        setShowId?.(undefined);
        sunflowerUseModal.close();
    }, []);

    const handleShow = useCallback(
        (showId?: BaseKey) => {
            if (typeof showId !== "undefined") {
                setShowId?.(showId);
            }
            sunflowerUseModal.show();
        },
        [showId],
    );

    const { open: _open, ...otherModalProps } = modalProps;
    const newModalProps = { open: _open, ...otherModalProps };
    const visible = modalProps.open || false;

    return {
        ...sunflowerUseModal,
        show: handleShow,
        close: handleClose,
        open: visible,
        modalProps: {
            ...newModalProps,
            width: "1000px",
            title: translate(
                `${identifier}.titles.show`,
                `${getUserFriendlyName(
                    `show ${resource?.meta?.label ??
                    resource?.options?.label ??
                    resource?.label ??
                    identifier
                    }`,
                    "singular",
                )}`,
            ),
            cancelButtonProps: { style: { display: 'none' } },
            onOk: handleClose,
            forceRender: true,
        },
        loading,
    };
};
