import React, { useState, useEffect } from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { getEnumOptions } from "../../utils";
import { AccountType } from "../../interfaces";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";


export const BankAccountForm: React.FC<{ formProps: any }> = ({ formProps }) => {
    const translate = useTranslate();
    const [accountType, setAccountType] = useState(null);
    const accountTypeOptions = getEnumOptions(AccountType, 'AccountType', translate, [AccountType.Unknown]);
    const { selectProps: assetSelectProps } = useSelect({
        resource: 'asset',
        optionLabel: 'id',
        pagination: {
            pageSize: 10,
            mode: "server",
        },
    });

    useEffect(() => {
        if (formProps?.initialValues) {
            setAccountType(formProps?.initialValues.accountType);
        }
    }, [formProps]);


    const onValuesChange = (changedValues: any, values: any) => {
        setAccountType(values.accountType);
    };

    return <Form {...formProps}
        {...defaultFormLayout}
        labelCol= {{ flex: '180px' }}
        onValuesChange={onValuesChange}>
        <Form.Item
            label={translate("bankAccount.fields.denominatedInAssetId")}
            name="denominatedInAssetId"
            rules={[
                {
                    required: true,
                },
            ]}
        >
            <Select {...assetSelectProps} />
        </Form.Item>
        <Form.Item
            label={translate("bankAccount.fields.accountType")}
            name="accountType"
            rules={[
                {
                    required: true,
                },
            ]}
        >
            <Select options={accountTypeOptions} />
        </Form.Item>
        {accountType === AccountType.BankAccount && <>
            <Form.Item
                label={translate("bankAccount.fields.nameOnTheAccount")}
                name="nameOnTheAccount"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.accountNumber")}
                name="accountNumber"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.bankName")}
                name="bankName"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.bankAddress")}
                name="bankAddress"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.bankCity")}
                name="bankCity"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.bankState")}
                name="bankState"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.bankZip")}
                name="bankZip"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.bankCountry")}
                name="bankCountry"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={translate("bankAccount.fields.routingNumber")}
                name="routingNumber"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.SWIFTCode")}
                name="SWIFTCode"
            >
                <Input />
            </Form.Item>
        </>}

        {accountType === AccountType.Card && <><Form.Item
            label={translate("bankAccount.fields.cardNumber")}
            name="cardNumber"
        >
            <Input />
        </Form.Item>
        </>}
        {accountType === AccountType.CryptoWallet && <><Form.Item
            label={translate("bankAccount.fields.networkName")}
            name="networkName"
        >
            <Input />
        </Form.Item>
            <Form.Item
                label={translate("bankAccount.fields.walletAddress")}
                name="walletAddress"
            >
                <Input />
            </Form.Item>
        </>}
        <Form.Item name="ownerId">
            <Input type='hidden' />
        </Form.Item>
    </Form>
}