import { User } from "./user";
import { UserGroup } from "./userGroup";

export class StaffUserAccessControl {

    get id(): string { 
        return [this.staffUserId, this.managedUserGroupId] as never;
    }

    constructor(userObject: Partial<StaffUserAccessControl>) {
        Object.assign(this, userObject);
    }
    staffUserId: number = 0;
    staffUser?: User;
    managedUserGroupId: number = 0;
    managedUserGroup?: UserGroup;
}
