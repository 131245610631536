import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { AccountGroup } from "../../interfaces";
import { AccountGroupsForm } from "./form";
import { Edit } from "../../components/crud/edit";

export const AccountGroupEdit: React.FC<IResourceComponentsProps> = () => {
    
    const { formProps, saveButtonProps, queryResult } = useForm<AccountGroup>();
    
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <AccountGroupsForm formProps={formProps}></AccountGroupsForm>
        </Edit>
    );
};
