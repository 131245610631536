import {
    IResourceComponentsProps, useShow, useTranslate
} from "@refinedev/core";
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Show } from "../../components/crud/show";
import { useTitleData } from '../../contexts/title';
import { UserData } from '../../interfaces';

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const { id } = useParams<{ id: string }>();
    const tab = useLocation().pathname.split("/").pop();
    const showProps = useShow<UserData>({ resource: 'user', id });
    const [activeTab, setActiveTab] = useState(tab);
    const navigate = useNavigate();

    const { queryResult: { data, isLoading } } = showProps;
    const translate = useTranslate();
    const user = data?.data;

    useTitleData({ ...user, tab: activeTab });

    if (isLoading || !user) {
        return <div>Loading...</div>;
    }

    const items: TabsProps['items'] = [
        {
            key: 'details',
            label: translate("user.show.details"),
        },
        {
            key: 'documents',
            label: translate("user.show.documents"),
        },
        {
            key: 'bankAccounts',
            label: translate("user.show.bankAccounts"),
        },
        {
            key: 'accounts',
            label: translate("user.show.accounts"),
        },
        {
            key: 'sessions',
            label: translate("user.show.sessions"),
        },
        {
            key: 'loginHistory',
            label: translate("user.show.loginHistory"),
        },
        {
            key: 'log',
            label: translate("user.show.log"),
        },
    ];

    const tabsProps = {
        info: {
            resource: 'user',
            recordItemId: user.id,
            canEdit: true,
        },
        documents: {
            resource: 'document',
            recordItemId: undefined,
            canEdit: false,
        },
        bankAccounts: {
            resource: 'bankAccount',
            recordItemId: undefined,
            canEdit: false,
        },
        accounts: {
            resource: 'account',
            recordItemId: undefined,
            canEdit: false,
            canDelete: false,
        },
        sessions: {
            resource: 'UserSessions',
            recordItemId: undefined,
            canEdit: false,
        },
        loginHistory: {
            resource: 'loginhistory',
            recordItemId: undefined,
            canEdit: false,
        },
        log: {
            resource: 'log',
            recordItemId: undefined,
            canEdit: false,
        },
    }
    const tabProps = tabsProps[activeTab as keyof typeof tabsProps];


    const onChange = (key: string) => {
        setTimeout(() => {
            navigate(`/user/show/${user.id}/${key}`, { replace: true });
        }, 10);
        setActiveTab(key);
    };

    return (
        <Show
            isLoading={isLoading}
            title={translate(`user.titles.show`, { ...user, tab: activeTab })}
            {...tabProps}
        >
            <Tabs items={items} onChange={onChange} defaultActiveKey={activeTab} />
            <Outlet context={{ user: user }} />
        </Show>
    );
};

export default UserShow;
