import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { AssetForm } from "./form";
import { Asset } from "../../interfaces";


export const AssetCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm<Asset>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <AssetForm formProps={formProps}></AssetForm>
        </Create>
    );
};
