import React from 'react';
import {
    useModal,
    useModalForm,
    useTable
} from "@refinedev/antd";
import {
    useTranslate
} from "@refinedev/core";

import dayjs from "dayjs";

import { Account } from '../../../interfaces/account';
import { StatementPeriod, Transaction, TransactionVisibility } from '../../../interfaces';
import { TransactionsTable } from '../../transactions/table';
import { defaultDateFormat, defaultPageSize } from '../../../constants';
import { Button, DatePicker, Form, Modal, Row, Select, Space, message } from 'antd';
import { FileDoneOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { TransactionCreateForm } from '../../transactions/createForm';
import { getEnumOptions } from '../../../utils';
import { axiosInstance } from '../../../providers';
import { useOutletContext } from 'react-router-dom';

export const AccountTransactions: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();

    const translate = useTranslate();
    const { tableProps, sorters, filters } = useTable<Transaction>({
        resource: 'transaction',
        initialPageSize: defaultPageSize,
        syncWithLocation: false,
        meta: { query: { accountId: account.id } },
        sorters: {
            initial: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
        }
    });

    const { modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow } = useModalForm<Transaction>({
            resource: 'transaction',
            meta: {
                endpoint: `UserActions/CreateTransaction`
            },
            successNotification: (data: any) => {
                return {
                    message: data.data,
                    description: translate(`transaction.messages.created`),
                    type: "success",
                    key: 'transactionCreated',
                };
            },
            action: "create",
            redirect: false,
        });
    if (createFormProps) {
        createFormProps.initialValues = {
            accountId: account.id,
            hidden: TransactionVisibility.Visible,
            reserveChange: 0,
        }
    }

    const { modalProps, show: showModal, close: closeModal } = useModal({
        modalProps: {
            okText: translate('transaction.buttons.statement'),
            onOk: (event) => handleUpload(event),
        }
    });

    const [form] = Form.useForm();
    const initialValues = {
        periodLength: StatementPeriod.Month,
        periodStart: dayjs().subtract(1, 'month'),
    };

    const periodOptions = getEnumOptions(StatementPeriod, "StatementPeriod", translate)

    const handleUpload = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        form.validateFields();

        const api_url = `${import.meta.env.VITE_BACKEND_URL}api`;
        const periodStart = form.getFieldValue('periodStart');
        const periodLength = form.getFieldValue('periodLength');

        if (periodStart && periodLength) {
            const result = await axiosInstance.get(
                `${api_url}/useractions/${account.id}/statement?periodStart=${periodStart.format(defaultDateFormat)}&periodLength=${periodLength}`,
                {
                    responseType: 'blob'
                }
            )

            message.success(translate('transaction.messages.statementGenerated'));
            closeModal();

            const url = window.URL.createObjectURL(
                new Blob([result.data]),
            );
            const link = document.createElement('a') as HTMLAnchorElement;
            link.href = url;
            link.setAttribute(
                'download',
                `statement.pdf`,
            );

            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    }

    return (
        <>
            <Row justify={"end"} className='mb-8'>
                <Space>
                    <Button type="primary" onClick={() => createModalShow()}><PlusSquareOutlined /> {translate('buttons.create')}</Button>
                    <Button onClick={() => showModal()}><FileDoneOutlined /> {translate('transaction.buttons.statement')}</Button>
                </Space>
            </Row>

            <TransactionsTable tableProps={tableProps} withIdentity={false} sorters={sorters} filters={filters} />
            <Modal {...createModalProps}>
                <TransactionCreateForm formProps={createFormProps} />
            </Modal>
            <Modal {...modalProps}>
                <Form
                    form={form}
                    initialValues={initialValues}
                >
                    <Form.Item
                        name="periodStart"
                        rules={[{ required: true }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="periodLength"
                        rules={[{ required: true }]}
                    >
                        <Select options={periodOptions} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
