import React from "react";
import {
    IResourceComponentsProps,
    BaseRecord,
    useTranslate,
} from "@refinedev/core";

import {
    useTable,
    EditButton,
    ShowButton,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Popover } from "antd";
import { AccountGroup } from "../../interfaces";
import { MarginCallPolicyShowInternal } from "../margincallpolicies";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTableClick } from "../../hooks/useTableClick";
import { useSearchList } from "../../hooks/useSearchList";
import { defaultPageSize } from "../../constants";
import { getBooleanTableFilter } from "../../utils";
import { BooleanField } from "../../components/fields";
import { List } from "../../components/crud/list";

export const AccountGroupList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { search, listProps } = useSearchList();
    const query: Record<string, string | string[]> = {
        _include: ['marginCallPolicy']
    };
    if (search) {
        query.nameLIKE = search;
        query.descriptionLIKE = search;
    }
    const { tableProps } = useTable({
        syncWithLocation: true,
        meta: { query },
        initialPageSize: defaultPageSize,
    });


    const clickTableProps = useTableClick({resource: 'accountGroup', action: 'edit'});
    const yesNo = getBooleanTableFilter(translate);
  
    return (
        <List  {...listProps}>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex="name"
                    sorter={true}
                    title={translate("accountGroup.fields.name")}
                />
                <Table.Column
                    dataIndex="description"
                    title={translate("accountGroup.fields.description")}
                />
                <Table.Column
                    dataIndex={["isDefaultGroup"]}
                    title={translate("accountGroup.fields.isDefaultGroup")}
                    filters={yesNo}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["hasNBP"]}
                    title={translate("accountGroup.fields.hasNBP")}
                    filters={yesNo}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={"marginCallPolicyId"}
                    sorter={true}
                    title={translate("accountGroup.fields.marginCallPolicy")}
                    render={(_, record: AccountGroup) => {
                        if (!record) {
                            return <></>
                        }
                        const value = record.marginCallPolicy?.description;
                        const content = <MarginCallPolicyShowInternal record={record.marginCallPolicy!} />
                        return <Space>
                            <Popover content={content} placement="rightBottom">
                                <InfoCircleOutlined />
                            </Popover>
                            {value}
                        </Space>
                    }}
                />
                <Table.Column
                    align="right"
                    width={130}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
