import React, { useContext } from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, StopOutlined } from "@ant-design/icons";
import {
    useCustomMutation,
    useNotification,
    useApiUrl,
    useTranslate,
    useMutationMode,
    useCan,
    useResource,
    pickNotDeprecated,
    useWarnAboutChange,
    AccessControlContext,
    useInvalidate,
    pickDataProvider,
} from "@refinedev/core";
import {
    RefineButtonClassNames,
    RefineButtonTestIds,
} from "@refinedev/ui-types";
import { DeleteButtonProps } from "@refinedev/antd";
import { Position } from "../../interfaces";


export const CancelOrderButton: React.FC<DeleteButtonProps & {ids: Array<any>}> = ({
    resource: resourceNameFromProps,
    resourceNameOrRouteName: propResourceNameOrRouteName,
    ids,
    onSuccess,
    mutationMode: mutationModeProp,
    children,
    successNotification,
    errorNotification,
    hideText = false,
    accessControl,
    metaData,
    meta,
    dataProviderName,
    confirmTitle,
    confirmOkText,
    confirmCancelText,
    invalidates,
    ...rest
}) => {
    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;

    const translate = useTranslate();
    const { id, resource, identifier, resources, select } = useResource(
        resourceNameFromProps ?? propResourceNameOrRouteName,
    );

    const { mutate } = useCustomMutation<Position>();

    const { data } = useCan({
        resource: resource?.name,
        action: "delete",
        params: { ids, resource },
        queryOptions: {
            enabled: accessControlEnabled,
        },
    });

    const apiUrl = useApiUrl();
    const { open } = useNotification();
    const invalidateStore = useInvalidate();


    const disabledTitle = () => {
        if (data?.can) return "";
        else if (data?.reason) return data.reason;
        else
            return translate(
                "buttons.notAccessTitle",
                "You don't have permission to access",
            );
    };

    const { setWarnWhen } = useWarnAboutChange();

    if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
        return null;
    }


    return (
        <Popconfirm
            key="delete"
            okText={confirmOkText ?? translate("buttons.order.confirm_cancel", "Cancel order")}
            cancelText={
                confirmCancelText ?? translate("buttons.order.no", "No")
            }
            okType="danger"
            title={
                confirmTitle ?? translate("buttons.order.confirm", "Are you sure?")
            }
            onConfirm={(): void => {
                if (ids) {
                    setWarnWhen(false);
                    const data = new FormData();
                    data.append('stringIds', ids.join(','));

                    mutate(
                        {
                            url: `${apiUrl}/UserActions/CancelOrders`,
                            method: "post",
                            config: {
                                headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
                            },
                            values: data
                        },
                        {
                            onSettled: (
                                _data,
                                _error,
                                {
                                    dataProviderName,
                                },
                            ) => {
                                invalidateStore({
                                    resource: identifier,
                                    dataProviderName: pickDataProvider(
                                        identifier,
                                        dataProviderName,
                                        resources,
                                    ),
                                    invalidates: ["list", "many", "detail"],
                                    id,
                                });
                            },
                            onSuccess: (value) => {
                                open?.({
                                    type: "success",
                                    message: translate("order.messages.canceled"),
                                });
                            },
                        },
                    );
                }
            }}
            disabled={
                typeof rest?.disabled !== "undefined"
                    ? rest.disabled
                    : data?.can === false
            }
        >
            <Button
                danger
                icon={<StopOutlined />}
                title={disabledTitle()}
                disabled={data?.can === false}
                data-testid={RefineButtonTestIds.DeleteButton}
                className={RefineButtonClassNames.DeleteButton}
                {...rest}
            >
                {!hideText &&
                    (children ?? translate("buttons.delete", "Delete"))}
            </Button>
        </Popconfirm>
    );
};
