import { useTranslate } from "@refinedev/core";
import { DatePicker, Form, FormProps, Input, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { defaultFormLayout } from "../../constants";
import { GoodUntilTypes, getGoodUntilOptions, getTypeByDate, setGoodUntil, setGoodUntilValueAndType } from "../../utils";

export const AlertForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const [goodUntilType, setGoodUntilType] = useState(0);

    useEffect(() => {
        if (formProps.form && formProps.initialValues) {
            const type = getTypeByDate(formProps.initialValues.goodUntil, formProps);
            setGoodUntilType(type);
            formProps.form?.setFieldValue('goodUntilType', type);
            formProps.initialValues.goodUntilType = type;
            formProps.form?.setFieldValue('goodUntilDate', dayjs(formProps.initialValues.goodUntil));
            formProps.initialValues.goodUntilDate = dayjs(formProps.initialValues.goodUntil);
        }
    }, [formProps?.initialValues]);

    const goodUntilOptions = getGoodUntilOptions(translate);

    const onGoodUntilChange = (value: number) => {
        setGoodUntilValueAndType(value, formProps, setGoodUntilType);
    };

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Form.Item
                label={translate("alert.fields.triggerPrice")}
                name={["triggerPrice"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("alert.fields.goodUntil")}
                name={"goodUntilType"}
            >
                <Select
                    options={goodUntilOptions}
                    onChange={onGoodUntilChange}
                />
            </Form.Item>
            {goodUntilType === GoodUntilTypes.GTT &&
                <Form.Item
                    label={" "}
                    name={["goodUntilDate"]}
                >
                    <DatePicker
                        format="DD/MM/YYYY hh:mm A"
                        onChange={(date) => setGoodUntil(formProps, date)}
                        showTime={{ use12Hours: true }}
                        disabledDate={(current) => current && current < dayjs()}
                    />
                </Form.Item>}
            <Form.Item
                name={["goodUntil"]}
            >
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name={["id"]} >
                <Input type="hidden" />
            </Form.Item>
        </Form>
    );
};
