import {
    BaseKey,
    BaseRecord,
    useList, useMany
} from "@refinedev/core";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { useEffect, useMemo, useState } from "react";

interface ReferenceFilterProps {
    resource: string,
    valueName?: string,
    labelName?: string,
    search?: object,
    enabled?: boolean,
}

export function useReferenceFilter<TData extends BaseRecord = BaseRecord>(props: ReferenceFilterProps): {filters?: ColumnFilterItem[], hash?: Map<BaseKey, TData>} {

    const { data: searchData } = useList({
        ...props.search, pagination: {
            mode: 'off'
        },
        queryOptions: { enabled: props.enabled !== false }
    });

    const ids: BaseKey[] = (searchData?.data as unknown[] as BaseKey[] || []).filter(id => !!id);

    const { data } = useMany<TData>({
        resource: props.resource,
        ids: Array.from(new Set(ids)),
        queryOptions: { enabled: ids.length > 0 && props.enabled !== false }
    });

    const {filters, hash} = useMemo(() => {
        const valueName = props.valueName || "id";
        const textName = props.labelName || "name";
        const filters = data?.data?.map((item) => { return { text: item[textName], value: item[valueName] } as ColumnFilterItem })
        const hash: Map<BaseKey, TData> = new Map();
        data?.data?.forEach((item) => {
            hash.set(item[valueName], item);
        });
        return {filters, hash};
    }, [data]) || [];

    return {filters, hash};
}

const cache: { [key: string]: Map<BaseKey, BaseRecord> } = {};

type ReferenceDataProps = {
    resource: string,
    ids?: BaseKey[],
}

/**
 * Hook to fetch reference data for given resource and ids.
 * @param props - Object with resource and ids.
 * @param props.resource - Resource name.
 * @param props.ids - Array of ids.
 * @returns Map with ids as keys and data as values.
 */
export const useReferenceData = <TData extends BaseRecord>({
    resource,
    ids
}: ReferenceDataProps): Map<BaseKey, TData> => {
    // Extract ids and create a unique set of ids.
    const queryIds = Array.from(new Set(ids ? ids : []));

    // Check if cache for the given resource exists.
    // If not, initialize an empty cache.
    if (cache[resource] === undefined) {
        cache[resource] = new Map<BaseKey, TData>();
    }

    // Get the cache for the given resource.
    const resourceCache = cache[resource] as Map<BaseKey, TData>;
    const [localCache, setLocalCache] = useState(resourceCache);

    // Filter out ids that already exist in the cache.
    const remainingIds = [...queryIds].filter(id => id && !resourceCache.has(id));

    // Fetch data for the remaining ids using useMany hook.
    const { data } = useMany<TData>({
        resource,
        ids: remainingIds,
        // Enable query only if there are ids to fetch.
        queryOptions: { enabled: remainingIds !== undefined && remainingIds.length > 0 }
    });

    // Update the cache with the fetched data.
    useEffect(() => {
        data?.data?.forEach((item) => {
            resourceCache?.set(item.id!, item);
        });

        setLocalCache(resourceCache);
    }, [remainingIds, resource, data]);

    return localCache;
}

// export const useUpdateReferenceData = <TData extends BaseRecord>(dataSource: TData[], resource: string, propName: string, idName: string) => {
//     const [newData, setNewData] = useState(dataSource);
//     const ids = Array.from(new Set(dataSource?.map(item => item[idName]).filter(id => !!id) || []));

//     const fieldDataCache = useReferenceData({resource,ids});

//     useEffect(() => {
//         if (dataSource && dataSource?.length > 0 && fieldDataCache.size && ids.length) {
//             const retItems: TData[] = [];
//             dataSource?.forEach((item) => {
//                 const newItem = {...item} as any;
//                 newItem[propName] = fieldDataCache.get(item[idName]);
//                 retItems.push(newItem);
//             });
    
//             setNewData(retItems);
//         }
//     }, [ids.length, fieldDataCache.size, dataSource?.length, resource, propName, idName]);

//     return newData;
// }