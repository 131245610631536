import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { ListButton, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Checkbox, Select } from "antd";
import { AssetForm } from "./form";
import { useParams } from "react-router-dom";
import { Edit } from "../../components/crud/edit";

export const AssetEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();
    let { id } = useParams();

    return (
        <Edit saveButtonProps={saveButtonProps}
        headerButtons={({ defaultButtons }) => (
            <>
                <ListButton resource="lending" meta={{assetId: id}} >{translate('lending.lending')}</ListButton>
                {defaultButtons}
            </>
        )}
>
            <AssetForm formProps={formProps}></AssetForm>
        </Edit>
    );
};
