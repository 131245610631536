import React from "react";
import { useParsed, useTranslate, useUserFriendlyName } from "@refinedev/core";
import { PropsWithChildren, createContext, useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { generateDefaultDocumentTitle } from "../../helpers/titleGenerator";

type TitleContextType = {
  titleData: any;
  setTitleData: (data: any) => void;
};

export const TitleContext = createContext<TitleContextType>(
  {} as TitleContextType
);


export const useTitleData = (data: any) => {
  const { setTitleData } = useContext(TitleContext);

  useEffect(() => {
    if (data) {
      setTitleData(data);
    }
    return () => setTitleData(null);
  }, [JSON.stringify(data)]);
};


export const TitleContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [titleData, setTitleData] = useState<any>(null);

  const location = useLocation();
  const { action, id, resource } = useParsed();

  const translate = useTranslate();
  const getUserFriendlyName = useUserFriendlyName();

  const identifier = resource?.identifier ?? resource?.name;
  const resourceName =
    resource?.label ??
    resource?.meta?.label ??
    getUserFriendlyName(identifier, action === "list" ? "plural" : "singular");

  useLayoutEffect(() => {
    const autoGeneratedTitle = generateDefaultDocumentTitle(
      translate,
      resource!,
      action,
      `${id}`,
      resourceName,
    );
    if (titleData) {
      document.title = translate(`documentTitle.${identifier}.${action}`, titleData);
    } else {
      document.title = autoGeneratedTitle;
    }
  }, [location, titleData]);

  return (
    <TitleContext.Provider value={{ titleData, setTitleData }}>
      {children}
    </TitleContext.Provider>
  );
};
