import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";
import { Col, Form, Input, Row, Select } from "antd";
import { getEnumNumericOptions, getEnumOptions } from "../../utils";
import { TransactionType, TransactionVisibility } from "../../interfaces";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";
import { useTitleData } from "../../contexts/title";

export const TransactionEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    const transactionData = queryResult?.data?.data;
    useTitleData({ ...formProps.initialValues });

    const accountId = transactionData?.accountId;
    const meta = {
        query: {
            accountId: accountId,
        }
    };

    const { selectProps: orderSelectProps } = useSelect({ resource: "order", optionLabel: 'displayName', meta, queryOptions: { enabled: !!accountId } });
    const { selectProps: positionSelectProps } = useSelect({ resource: "position", optionLabel: 'displayName', meta, queryOptions: { enabled: !!accountId } });

    const typeOptions = getEnumOptions(TransactionType, "TransactionType", translate, [TransactionType.Unknown]);
    const visibilityOptions = getEnumNumericOptions(TransactionVisibility, "TransactionVisibility", translate);

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps}
                {...defaultFormLayout}
            >
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item
                            label={translate("transaction.fields.order")}
                            name={"orderId"}
                        >
                            <Select {...orderSelectProps} />
                        </Form.Item>
                        <Form.Item
                            label={translate("transaction.fields.position")}
                            name={"positionId"}
                        >
                            <Select {...positionSelectProps} />
                        </Form.Item>
                        <Form.Item
                            label={translate("transaction.fields.accountChange")}
                            name={["accountChange"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("transaction.fields.reserveChange")}
                            name={["reserveChange"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <Form.Item
                            label={translate("transaction.fields.type")}
                            name={["type"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select options={typeOptions} />
                        </Form.Item>
                        <Form.Item
                            label={translate("transaction.fields.description")}
                            name={["description"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("transaction.fields.hidden")}
                            name={["hidden"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select options={visibilityOptions} />
                        </Form.Item>
                        <Form.Item name={"accountId"}>
                            <Input type="hidden" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
