import { FormProps } from "antd";

export const defaultPageSize: number = 20;
export const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm';
export const defaultDateFormat = 'YYYY-MM-DD';

export const defaultFormLayout: Partial<FormProps> = {
    layout: "horizontal",
    labelCol: { flex: '130px' },
    labelAlign: "left",
    labelWrap: true,
    colon: false,
}