import {
    BaseRecord,
    IResourceComponentsProps,
    useTranslate
} from "@refinedev/core";
import React from "react";

import {
    EditButton,
    useTable
} from "@refinedev/antd";
import { Space, Table } from "antd";
import { List } from "../../components/crud/list";
import { useExposureColumns } from "../../components/exposureLimits";
import { LogoAssetClassField, LogoCategoryField, LogoField } from "../../components/fields/Logo";
import { defaultPageSize } from "../../constants";
import { useTableClick } from "../../hooks/useTableClick";
import { Asset, AssetClass, AssetClassesEnum, AssetClassesEnumToString } from "../../interfaces";
import { EnumField } from "../../components/fields";


export const AssetClassList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const { tableProps, filters } = useTable<AssetClass>({
        syncWithLocation: true,
        initialPageSize: defaultPageSize,
    });

    const limitColumns = useExposureColumns();
    const clickTableProps = useTableClick({ resource: 'assetClass' });

    return (
        <List>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex="name"
                    width={100}
                    title={translate("asset.fields.name")}
                    sorter={true}
                    render={(value: any, record: Asset) => <LogoCategoryField token={record.id} name={value} type="assetClass" />}
                />
                <Table.Column
                    dataIndex="id"
                    width={100}
                    title={translate("asset.fields.flags")}
                    render={(value: AssetClassesEnum) => <div>{AssetClassesEnumToString(value)}</div>}
                />
                {limitColumns}

                <Table.Column
                    dataIndex="actions"
                    align="right"
                    width={100}
                    render={(_: any, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};