import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";

import { AssetClass } from "../../interfaces";
import { AssetClassForm } from "./form";


export const AssetClassCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm<AssetClass>();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <AssetClassForm formProps={formProps}></AssetClassForm>
        </Create>
    );
};
