import { TextField } from '@refinedev/antd';
import {
    useTranslate
} from "@refinedev/core";
import { Descriptions, DescriptionsProps } from 'antd';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { BooleanField, DateField, EnumField, NumberField } from '../../../components/fields';
import { EmailField } from '../../../components/fields/EmailField';
import { UserData } from '../../../interfaces';


export const UserInfo: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>()
    const translate = useTranslate();

    const items1: DescriptionsProps['items'] = [
        {
            key: 'token',
            label: translate("user.fields.token"),
            children: <TextField value={user?.token} />,
        },
        {
            key: 'userName',
            label: translate("user.fields.userName"),
            children: <TextField value={user?.userName} />,
        },
        {
            key: 'status',
            label: translate("user.fields.status"),
            children: <EnumField value={user?.status} translate={translate} enum="UserStatus"  />,
        },
        {
            key: 'stmtFreq',
            label: translate("user.fields.stmtFreq"),
            children: <EnumField value={user?.stmtFreq} translate={translate} enum="StatementFrequency"  />,
        },
        {
            key: 'email',
            label: translate("user.fields.email"),
            children: <EmailField value={user?.email} />,
        },
        {
            key: 'emailConfirmed',
            label: translate("user.fields.emailConfirmed"),
            children: <BooleanField value={user?.emailConfirmed} />,
        },
        {
            key: 'phoneNumber',
            label: translate("user.fields.phoneNumber"),
            children: <TextField value={user?.phoneNumber} />,
        },
        {
            key: 'phoneNumberConfirmed',
            label: translate("user.fields.phoneNumberConfirmed"),
            children: <BooleanField value={user?.phoneNumberConfirmed} />,
        },
        {
            key: 'twoFactorEnabled',
            label: translate("user.fields.twoFactorEnabled"),
            children: <BooleanField value={user?.twoFactorEnabled} />,
        },
    ]

    const items2: DescriptionsProps['items'] = [
        {
            key: 'nameFirst',
            label: translate("user.fields.nameFirst"),
            children: <TextField value={user?.nameFirst} />,
        },
        {
            key: 'nameMiddle',
            label: translate("user.fields.nameMiddle"),
            children: <TextField value={user?.nameMiddle} />,
        },
        {
            key: 'nameLast',
            label: translate("user.fields.nameLast"),
            children: <TextField value={user?.nameLast} />,
        },

        {
            key: 'docId',
            label: translate("user.fields.docId"),
            children: <TextField value={user?.docId} />,
        },
        {
            key: 'docIdType',
            label: translate("user.fields.docIdType"),
            children: <EnumField translate={translate} enum="DocIdTypeEnum" value={user?.docIdType} />,
        },
        {
            key: 'docIdAuthority',
            label: translate("user.fields.docIdAuthority"),
            children: <TextField value={user?.docIdAuthority} />,
        },
        {
            key: 'taxId',
            label: translate("user.fields.taxId"),
            children: <TextField value={user?.taxId} />,
        },
        {
            key: 'taxIdType',
            label: translate("user.fields.taxIdType"),
            children: <EnumField translate={translate} enum="TaxIdTypeEnum" value={user?.taxIdType} />,
        },
        {
            key: 'taxIdAuthority',
            label: translate("user.fields.taxIdAuthority"),
            children: <TextField value={user?.taxIdAuthority} />,
        },

        {
            key: 'fullAddress',
            label: translate("user.fields.fullAddress"),
            children: <TextField value={user?.fullAddress} />,
        },
    ];

    const items3: DescriptionsProps['items'] = [
        {
            key: 'culture',
            label: translate("user.fields.culture"),
            children: <TextField value={user?.culture} />,
        },
        {
            key: 'timezone',
            label: translate("user.fields.timezone"),
            children: <TextField value={user?.timezone} />,
        },
        {
            key: 'userGroup',
            label: translate("user.fields.userGroup,name"),
            children: <TextField value={user?.userGroup?.name} />,
        },
        {
            key: 'lockoutEnabled',
            label: translate("user.fields.lockoutEnabled"),
            children: <BooleanField value={user?.lockoutEnabled} />,
        },
        {
            key: 'lockoutEnd',
            label: translate("user.fields.lockoutEnd"),
            children: user?.lockoutEnabled ? <DateField value={user?.lockoutEnd} /> : <></>,
        },
        {
            key: 'accessFailedCount',
            label: translate("user.fields.accessFailedCount"),
            children: <NumberField value={user?.accessFailedCount} />,
        },
    ]

    return (
        <>
            <Descriptions items={items1} title={translate('user.fields.basic')} />
            <Descriptions items={items2} title={translate('user.fields.personal')} />
            <Descriptions items={items3} title={translate('user.fields.system')} />
        </>
    )
};
