import React from 'react';
import { NumberField } from "./fields"
import { Card, Form, Input, InputNumber, Table } from 'antd';
import { ExposureLimit } from '../interfaces/ExposureLimits';
import { useOne, useTranslate } from '@refinedev/core';
import { ExposureAccountingCurrency } from '../interfaces';

export const getExposureColumnsItems = (translate: CallableFunction, sorter=false) => {
    return [
        {
            title: translate("limits.fields.expLimLongT1"),
            dataIndex: 'expLimLongT1',
            key: 'expLimLongT1',
            align: 'right',
            width: 75,
            sorter: sorter,
            render: (value: any) => <NumberField value={value} />
        },
        {
            title: translate("limits.fields.expLimShortT1"),
            dataIndex: 'expLimShortT1',
            key: 'expLimShortT1',
            align: 'right',
            width: 75,
            sorter: sorter,
            render: (value: any) => <NumberField value={value} />
        },
        {
            title: translate("limits.fields.expLimNettedAbsT1"),
            dataIndex: 'expLimNettedAbsT1',
            key: 'expLimNettedAbsT1',
            align: 'right',
            width: 75,
            sorter: sorter,
            render: (value: any) => <NumberField value={value} />
        },
    ];
}

export const useExposureDescriptions = (translate: CallableFunction, entity: ExposureLimit) => {
    return [
        {
            label: translate("limits.fields.expLimLongT1"),
            key: 'expLimLongT1',
            children: <NumberField value={entity.expLimLongT1} />
        },
        {
            label: translate("limits.fields.expLimShortT1"),
            key: 'expLimShortT1',
            children: <NumberField value={entity.expLimShortT1} />
        },
        {
            label: translate("limits.fields.expLimNettedAbsT1"),
            key: 'expLimNettedAbsT1',
            children: <NumberField value={entity.expLimNettedAbsT1} />
        },
    ];
}

export const useExposureColumns = () => {
    const translate = useTranslate();
    const limitColumns = getExposureColumnsItems(translate);

    const { data: accountingCurrency } = useOne<ExposureAccountingCurrency>({
        resource: "crminfo",
        id: '',
    });

    const items = limitColumns.map((item: any) => <Table.Column key={item.dataIndex} {...item} />) as never;
    return <Table.Column
        dataIndex={["expLimLongT1"]}
        title={translate("limits.limits", {currency: accountingCurrency?.data?.accountingCurrency})}
    >
        {items}
    </Table.Column>
}

export const useExposureFormItems = () => {
    const translate = useTranslate();
    return <Card title={translate('limits.limits')} className="mb-24" size="small">
        <Form.Item
            label={translate("limits.fields.expLimLongT1")}
            name={["expLimLongT1"]}
        >
            <InputNumber />
        </Form.Item>
        <Form.Item
            label={translate("limits.fields.expLimShortT1")}
            name={["expLimShortT1"]}
        >
            <InputNumber />
        </Form.Item>
        <Form.Item
            label={translate("limits.fields.expLimNettedAbsT1")}
            name={["expLimNettedAbsT1"]}
        >
            <InputNumber />
        </Form.Item>
    </Card>
}