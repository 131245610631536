import { RedoOutlined } from '@ant-design/icons';
import { useInvalidate, useTranslate } from '@refinedev/core';
import { UseInvalidateProp } from '@refinedev/core/dist/hooks';
import { Button } from 'antd';
import React, { useEffect } from 'react';

type RefreshProps = Omit<UseInvalidateProp, 'invalidates'>;

const useAutoRefresh = (params: RefreshProps) => {
    const invalidate = useInvalidate();
    const translate = useTranslate();

    const refresh = () => {
        invalidate({ ...params, invalidates: ["all"] });
    }
    const headerButtons = (props: { defaultButtons?: JSX.Element }) => (
        <>
            <Button
                onClick={refresh}
                icon={<RedoOutlined spin={true} />}
            >
                {translate("buttons.refresh", "Refresh")}
            </Button>
            {props.defaultButtons}
        </>
    )

    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return { headerButtons }
};


export const useRefresh = (params: RefreshProps & { autorefresh?: boolean } ) => {
    const invalidate = useInvalidate();
    const translate = useTranslate();

    const refresh = () => {
        invalidate({ ...params, invalidates: ["detail", "list"] });
    }

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (params.autorefresh) {
            interval = setInterval(() => {
                refresh();
            }, 3000);
        }
        return () => {if (interval) clearInterval(interval)};
    }, []);

    return (params: { defaultButtons?: JSX.Element, autorefresh?: boolean }) => (
        <>
            <Button
                onClick={refresh}
                icon={<RedoOutlined spin={params.autorefresh} />}
            >
                {translate("buttons.refresh", "Refresh")}
            </Button>
            {params.defaultButtons}
        </>
    )
};

export default useAutoRefresh;