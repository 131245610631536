
import { FileExcelOutlined, FileOutlined } from "@ant-design/icons";
import {
  useTranslate
} from "@refinedev/core";
import { Button, Card, Col, Radio, Row, Space, Switch, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { AggregationType, CategoryExposureTypes, ExposureReport, ExposureType } from "../../../interfaces";
import { ExposureCard } from "./exposureCard";
import { downloadUrl } from "../../../utils";

type ExposurePaneProps = {
  activeStat: ExposureType,
  setActiveStat: CallableFunction,
  data: ExposureReport,
  setAssetType: CallableFunction,
  assetType: AggregationType,
  isLoading: boolean,
  denominatedInAssetId: string
};

export const ExposurePane: React.FC<ExposurePaneProps> = ({ data, assetType, setAssetType, isLoading, activeStat, setActiveStat, denominatedInAssetId }) => {
  const translate = useTranslate();
  const { Title } = Typography;
  const [sorter, setSorter] = useState<{ order: string | null, field: string[] }>({ order: 'descend', field: ['netted', 'totalValue'] });
  const [showHedges, setShowHedges] = useState<boolean>(true);

  const showHedgesValue = showHedges && assetType === AggregationType.Asset && activeStat === ExposureType.TotalValue;

  return (
    <>
      <Row justify="space-between" className="mb-8" align={"middle"}>
        <Col>
          <Title level={4}>
            {translate('dashboard.title', { 'symbol': data.denominatedInAssetId })}
          </Title>
        </Col>
        <Col>
          <Space>
            {assetType === AggregationType.Asset &&
              <Card size="small">
                <Space>
                  <label>
                    {translate('dashboard.fields.showHedges')} <Switch onChange={(e) => setShowHedges(e)} checked={showHedges} />
                  </label>
                </Space>
              </Card>}
            <Card size="small">
              <Radio.Group onChange={(e) => setAssetType(e.target.value)} value={assetType}>
                <Radio.Button value="pair">{translate('dashboard.tabs.ByPair')}</Radio.Button>
                <Radio.Button value="asset">{translate('dashboard.tabs.ByAsset')}</Radio.Button>
                <Radio.Button value="assetClass">{translate('dashboard.tabs.ByAssetClass')}</Radio.Button>
              </Radio.Group>
            </Card>
            <Card size="small">
              <Space>
                <Radio.Group onChange={(e) => setActiveStat(e.target.value)} value={activeStat}>
                  <Radio.Button value="totalValue">{translate('dashboard.fields.totalValue')}</Radio.Button>
                  <Radio.Button value="openPositionCount">{translate('dashboard.fields.openPositionCount')}</Radio.Button>
                  <Radio.Button value="cumulativeUnrealizedPnl">{translate('dashboard.fields.cumulativeUnrealizedPnl')}</Radio.Button>
                </Radio.Group>

                {/* <Radio.Group onChange={(e) => setViewMode(e.target.value)} value={viewMode}>
                  <Radio.Button value="chart"><PieChartOutlined /></Radio.Button>
                  <Radio.Button value="table"><TableOutlined /></Radio.Button>
                </Radio.Group> */}
              </Space>
            </Card>
            <Card size="small">
              <Space>
                <Tooltip title={translate('dashboard.export.excel')}>
                  <Button icon={<FileExcelOutlined />} onClick={() => downloadUrl(`api/exposure/download/xls?aggType=${assetType}`)} ></Button>
                </Tooltip>
                <Tooltip title={translate('dashboard.export.csv')}>
                  <Button icon={<FileOutlined />} onClick={() => downloadUrl(`api/exposure/download/csv?aggType=${assetType}`)}  ></Button>
                </Tooltip>
              </Space>
            </Card>
          </Space>
        </Col>
      </Row>

      <Row justify="space-between" gutter={[16, 24]} className="mb-24">

        <Col sm={24} md={12} lg={8}>
          <ExposureCard showHedges={showHedgesValue} isLoading={isLoading} data={data} name={CategoryExposureTypes.Netted} activeStat={activeStat} assetType={assetType} sorter={sorter} setSorter={setSorter} denominatedInAssetId={denominatedInAssetId} />
        </Col>
        <Col sm={24} md={12} lg={8}>
          <ExposureCard showHedges={false} isLoading={isLoading} data={data} name={CategoryExposureTypes.Long} activeStat={activeStat} assetType={assetType} sorter={sorter} setSorter={setSorter} denominatedInAssetId={denominatedInAssetId} />
        </Col>
        <Col sm={24} md={12} lg={8}>
          <ExposureCard showHedges={false} isLoading={isLoading} data={data} name={CategoryExposureTypes.Short} activeStat={activeStat} assetType={assetType} sorter={sorter} setSorter={setSorter} denominatedInAssetId={denominatedInAssetId} />
        </Col>
      </Row>
    </>
  );
}

export default ExposurePane;