import {
    TextField
} from "@refinedev/antd";
import {
    useOne,
    useTranslate
} from "@refinedev/core";
import React from 'react';

import { Col, Row } from "antd";
import { DescriptionGroups } from '../../../components/common/DescriptionGroups';
import { BooleanField, EnumField, UnitValueField } from '../../../components/fields';
import { Asset } from '../../../interfaces';
import { Account } from '../../../interfaces/account';
import { useOutletContext } from "react-router-dom";

export const AccountInfo: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();
    const translate = useTranslate();

    const { data: asset, isLoading: isAssetLoading } = useOne<Asset>({
        resource: "asset",
        id: account.denominatedInAssetId,
    });

    const digits = asset?.data.decimals || 2;
    const symbol = asset?.data.symbol || '';

    const groupOne = [
        {
            items: [
                {
                    key: 'name',
                    label: translate("account.fields.accountGroup"),
                    children: <TextField value={account?.accountGroup?.name} />,
                },
                {
                    key: 'defaultAccount',
                    label: translate("account.fields.defaultAccount"),
                    children: <BooleanField value={account.defaultAccount} />,
                },
                {
                    key: 'currentBalance',
                    label: translate("account.fields.currentBalance"),
                    children: <UnitValueField value={account.currentBalance} digits={digits} unit={symbol} />,
                },
                {
                    key: 'reservedAmount',
                    label: translate("account.fields.reservedAmount"),
                    children: <UnitValueField value={account.reservedAmount} digits={digits} unit={symbol} />,
                },
                {
                    key: 'enabled',
                    label: translate("account.fields.enabled"),
                    children: <BooleanField value={account.enabled} />,
                },
                {
                    key: 'mode',
                    label: translate("account.fields.mode"),
                    children: <EnumField enum="TradingMode" translate={translate} value={account.mode} />,
                },
            ]
        }
    ];

    return <Row>
        <Col xs={{ span: 12 }} lg={{ span: 12 }}>
            <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
        </Col>
    </Row>
};
