import React from 'react';
import {
    useTable
} from "@refinedev/antd";

import { Account } from '../../../interfaces/account';
import { Alert } from '../../../interfaces';
import { AlertsTable } from '../../alerts/table';
import { defaultPageSize } from '../../../constants';
import { useOutletContext } from 'react-router-dom';

export const AccountAlerts: React.FC = React.memo(() => {
    const { account } = useOutletContext<{ account: Account }>();
    const { tableProps } = useTable<Alert>({
        resource: 'alert',
        initialPageSize: defaultPageSize,
        syncWithLocation: false,
        meta: { query: { accountId: account.id } }
    });

    return (
        <AlertsTable {...tableProps} withIdentity={false} />
    );
});
