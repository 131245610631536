import React from "react";
import {
    useTranslate,
} from "@refinedev/core";

import { Order } from "../../interfaces";
import { Row, Col } from "antd";
import { DateField, EnumField, IdentityField, NumberField } from "../../components/fields";
import { TextField } from "@refinedev/antd";
import { TriggerStatus } from "../../interfaces/triggerable";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";

type OrderShowProps = {
    order: Order | null | undefined;
}

const OrderInfo: React.FC<{ order: Order }> = ({ order }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'pair',
                    label: translate("order.fields.pairId"),
                    children: <TextField value={order.pairId} />,
                },
                {
                    key: 'qty',
                    label: translate("order.fields.qty"),
                    children: <NumberField value={order.qty} />,
                },
                {
                    key: 'type',
                    label: translate("order.fields.type"),
                    children: <TextField value={order.type} />,
                },
                {
                    key: 'quotedPrice',
                    label: translate("order.fields.quotedPrice"),
                    children: <NumberField value={order.quotedPrice} />,
                },
                {
                    key: 'referenceType',
                    label: translate("order.fields.referenceType"),
                    children: <TextField value={order.referenceType} />,
                },
                {
                    key: 'reservedAmount',
                    label: translate("order.fields.reservedAmount"),
                    children: <NumberField value={order.reservedAmount} />,
                },
            ]
        }
    ];
    
    const groupTwo = [
        {
            items: [
                {
                    key: 'marginRatio',
                    label: translate("order.fields.marginRatio"),
                    children: <NumberField value={order.marginRatio} />,
                },

                {
                    key: 'side',
                    label: translate("order.fields.side"),
                    children: <TextField value={order.side} />,
                },
                {
                    key: 'goodUntil',
                    label: translate("order.fields.goodUntil"),
                    children: <DateField value={order.goodUntil} />,
                },
                {
                    key: 'triggerPrice',
                    label: translate("order.fields.triggerPrice"),
                    children: <NumberField value={order.triggerPrice} />,
                },
                {
                    key: 'persistentStatus',
                    label: translate("order.fields.persistentStatus"),
                    children: <TextField value={order.persistentStatus} />,
                },          
            ]
        }
    ];

    if (order.persistentStatus === TriggerStatus.Cancelled) {
        groupTwo[0].items.push(
            {
                key: 'cancellationReason',
                label: translate("order.fields.cancellationReason"),
                children: <TextField value={order.cancellationReason} />,
            }
        )
    };

    const groupThree = [
        {
            items: [
                {
                    key: 'executionPrice',
                    label: translate("order.fields.executionPrice"),
                    children: <NumberField value={order.executionPrice} />,
                },
                {
                    key: 'identity',
                    label: translate("position.fields.account"),
                    children: <IdentityField value={order?.identity} />,
                },
                {
                    key: 'executedAt',
                    label: translate("order.fields.executedAt"),
                    children: <DateField value={order.executedAt} />,
                },
                {
                    key: 'createdAt',
                    label: translate("order.fields.createdAt"),
                    children: <DateField value={order.createdAt} />,
                },
                {
                    key: 'lastUpdatedAt',
                    label: translate("order.fields.lastUpdatedAt"),
                    children: <DateField value={order.lastUpdatedAt} />,
                },
                {
                    key: 'token',
                    label: translate("order.fields.token"),
                    children: <TextField value={order.token} />,
                },
            ]
        }
    ];

    const groupFour = [
        {
            items: [
                {
                    key: 'method',
                    label: translate("order.fields.method"),
                    children: <EnumField enum="ExecutionMethod" value={order.method} translate={translate} />,
                },
                {
                    key: 'connectorId',
                    label: translate("order.fields.connectorId"),
                    children: <>{order.connectorId}</>,
                },
                {
                    key: 'routedId',
                    label: translate("order.fields.routedId"),
                    children: <>{order.routedId}</>,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 11 }} lg={{ span: 6 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, }} lg={{ span: 5, offset: 1 }}>
                <DescriptionGroups groups={groupThree} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 12, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                <DescriptionGroups groups={groupFour} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )

}

export const OrderShowModal: React.FC<OrderShowProps> = ({ order }) => {

    if (!order) {
        return <></>
    }

    return (
        <OrderInfo order={order}></OrderInfo>
    );
};
