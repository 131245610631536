
import { ExposureLimit } from "./ExposureLimits";
import { Asset } from "./asset";
import { AssetClassesEnum } from "./assetClass";

export enum TradeAvailability {
    None = "None",
    LiquidateOnly = "LiquidateOnly",
    Full = "Full"
}

// TODO: Check all possible values
export enum ExchangeStatusEnum {
    online = "online",
    reduce_only = "reduce_only"
}

// TODO: Check all possible values
export enum PairSubTypeEnum {
    Unknown ="Unknown",
    CommonStock = "CommonStock",
    PreferredStock = "PreferredStock",
    DepositaryReceipt = "DepositaryReceipt",
    LimitedPartnership = "LimitedPartnership",
    REIT = "REIT"
}

export interface Pair extends ExposureLimit {
    id: string;
    quoteId: string;
    quote: Asset;
    assetClassId: AssetClassesEnum;
    subtype: PairSubTypeEnum;
    pairDecimals: number;
    costDecimals: number;
    lotDecimals: number;
    lotUnitName: string;
    lotSize: number;
    orderMin: number;
    orderMax: number;
    costMin: number;
    tickSize: number;
    token: string;
    baseId: string;
    base: Asset;
    exchangeStatus: ExchangeStatusEnum;
    availability: TradeAvailability;
    displayName: string;
    altName: string;
    feedFromId: string;
    feedFrom: string;
    hedgePosition: number;
}

export interface PairInfo {
    id: string;
    dispName: string;
    typeDescription: string;
    assetClassId: AssetClassesEnum;
    baseId: string;
    quoteId: string;
    exchangeId?: number;
    exchangeMic?: string;
    exchangeName?: string;
    exchangeDescription?: string;
    exchangeCountry?: string;
    baseSymbol: string;
    quoteSymbol: string;
    sector?: string;
    industry?: string;
    type?: string;
}