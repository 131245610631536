import i18n from "../i18n";
import { Override } from "./override";


export enum AssetClassesEnum {
    Unknown = 0,

    FX          = 1 << 1,
    Crypto      = 1 << 2,   
    Equity      = 1 << 3,
    Index       = 1 << 4,   
    Commodity   = 1 << 5,
    ETF         = 1 << 6,
    MutualFund  = 1 << 7,
    Bond        = 1 << 8,


    Option      = 1 << 17,
    Future      = 1 << 18,
    Forward     = 1 << 19,
    CFD         = 1 << 20,
    Swap        = 1 << 21,
    CDS         = 1 << 22,

    VanillaAm   = 1 << 24,
    VanullaEu   = 1 << 25,
    Binary      = 1 << 26,
    Exotic      = 1 << 27,
};

export const AssetClassesEnumMask = {
    MaskDerivative: AssetClassesEnum.Option | AssetClassesEnum.Future | AssetClassesEnum.Forward | AssetClassesEnum.CFD | AssetClassesEnum.Swap | AssetClassesEnum.CDS,
    MaskUnderlying: AssetClassesEnum.FX | AssetClassesEnum.Crypto | AssetClassesEnum.Equity | AssetClassesEnum.Index | AssetClassesEnum.Commodity | AssetClassesEnum.ETF | AssetClassesEnum.MutualFund | AssetClassesEnum.Bond
}

export const AssetClassesEditGroups = {
    basic: AssetClassesEnum.FX | AssetClassesEnum.Crypto | AssetClassesEnum.Equity | AssetClassesEnum.Index | AssetClassesEnum.Commodity | AssetClassesEnum.ETF | AssetClassesEnum.MutualFund | AssetClassesEnum.Bond,
    derivative: AssetClassesEnum.Option | AssetClassesEnum.Future | AssetClassesEnum.Forward | AssetClassesEnum.CFD | AssetClassesEnum.Swap | AssetClassesEnum.CDS,
    style: AssetClassesEnum.VanillaAm | AssetClassesEnum.VanullaEu | AssetClassesEnum.Binary | AssetClassesEnum.Exotic,
};

export const AssetClassesEnumMap: [string, AssetClassesEnum][] = Object.entries(AssetClassesEnum).filter(([key, value]) => typeof value === 'number' && value > 0) as [string, AssetClassesEnum][];
export const AssetClassesEnumNames: Map<AssetClassesEnum, string> = new Map(AssetClassesEnumMap.map(([key, value]) => [value, key]));

export const AssetClassesEnumToString = (value: AssetClassesEnum) => {
    
    const ret = [];
    for (const [key, val] of AssetClassesEnumMap) {
        if (val & value) {
            ret.push(i18n.t(`enums.AssetClassesEnum.${key}`));
        }
    }
    
    return ret.join(' ');
}

// export const AssetClassesEnumValues =
// {
//     [AssetClassesEnum.Unknown]: 0,
//     [AssetClassesEnum.FX]: 1,
//     [AssetClassesEnum.Crypto]: 2,
//     [AssetClassesEnum.Equity]: 3,
//     [AssetClassesEnum.Index]: 4,
//     [AssetClassesEnum.Commodity]: 5,
//     [AssetClassesEnum.ETF]: 6
// }


export interface AssetClass {
    id: AssetClassesEnum;
    name: string;
    overrideFeeId: number;
    overrideFee: Override;
}
