import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Show, TextField, NumberField } from "@refinedev/antd";
import { Descriptions, DescriptionsProps } from "antd";
import { PairCategory } from "../../interfaces";

const PairCategoryInfo: React.FC<{ pairCategory: PairCategory }> = ({ pairCategory }) => {
    const translate = useTranslate();

    const items: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: translate("pairCategory.fields.name"),
            children: <TextField value={pairCategory.name} />,
        },
        {
            key: 'description',
            label: translate("pairCategory.fields.description"),
            children: <TextField value={pairCategory.description} />,
        },
        {
            key: 'type',
            label: translate("pairCategory.fields.type"),
            children: <TextField value={pairCategory.type} />,
        },
        {
            key: 'weight',
            label: translate("pairCategory.fields.weight"),
            children: <NumberField value={pairCategory.weight} />,
        },
        {
            key: 'token',
            label: translate("pairCategory.fields.token"),
            children: <TextField value={pairCategory.token} />,
        },
    ];

    return <Descriptions items={items} title="Pair Category Info" size="small" column={1} layout={'horizontal'} />
};

export const PairCategoryShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<PairCategory>();
  const { data, isLoading } = queryResult;

  const pairCategory = data?.data;

  return (
      <Show isLoading={isLoading}>
          {pairCategory !== undefined && <PairCategoryInfo pairCategory={pairCategory}></PairCategoryInfo>}
      </Show>
  )
};
