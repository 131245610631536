import {
    ListButton,
    NumberField,
    Show,
    TextField
} from "@refinedev/antd";
import {
    BaseRecord,
    IResourceComponentsProps,
    useOne,
    useShow,
    useTranslate
} from "@refinedev/core";
import { Col, Flex, Row, Table, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { useExposureDescriptions } from "../../components/exposureLimits";
import { BooleanField, DateField, EnumField } from "../../components/fields";
import { useTableClick } from "../../hooks/useTableClick";
import { Asset, LendingDTO, LendingType } from "../../interfaces";
import { Pair } from "../../interfaces/pair";
import { getEnumFilter } from "../../utils";
import { getPairsColumns } from "../pair/list";



const AssetInfo: React.FC<{ asset: Asset, lendingData?: LendingDTO[] }> = ({ asset, lendingData }) => {
    const translate = useTranslate();

    const groupOne = [
        {
            title: translate("asset.fields.basic"),
            items: [
                {
                    key: 'name',
                    label: translate("asset.fields.name"),
                    children: <TextField value={asset.name} />,
                },
                {
                    key: 'enabled',
                    label: translate("asset.fields.enabled"),
                    children: <BooleanField value={asset.enabled} />,
                },
                {
                    key: 'description',
                    label: translate("asset.fields.description"),
                    children: <TextField value={asset.description} />,
                },
                {
                    key: 'symbol',
                    label: translate("asset.fields.symbol"),
                    children: <TextField value={asset.symbol} />,
                },
            ]
        }
    ];

    const groupTwo = [
        {
            title: translate("asset.fields.decimals"),
            items: [
                {
                    key: 'decimals',
                    label: translate("asset.fields.decimals"),
                    children: <NumberField value={asset.decimals} />,
                },
                {
                    key: 'displayDecimals',
                    label: translate("asset.fields.displayDecimals"),
                    children: <NumberField value={asset.displayDecimals} />,
                },
            ]
        },
        {
            title: translate('asset.fields.hedge'),
            items: [{
                key: 'hedgePosition',
                label: translate("asset.fields.hedgePosition"),
                children: <NumberField value={asset.hedgePosition} />,
            }]
        }
    ];

    const groupThree = [
        {
            key: 'limits',
            title: translate('limits.limits'),
            items: useExposureDescriptions(translate, asset),
        }
    ];

    return (
        <>
            <Row>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                    <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
                </Col>
                <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                    <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
                </Col>
                <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                    <DescriptionGroups groups={groupThree} size="small" layout={'horizontal'} column={1} />
                </Col>
            </Row>
        </>
    )

}

export const AssetPairs: React.FC<{ asset: Asset, pairs: Pair[] }> = ({ asset, pairs }) => {
    const translate = useTranslate();
    const clickTableProps = useTableClick({ resource: 'pair', action: 'show' });
    const pairColumns = getPairsColumns(translate, false);
    return (
        <>
            <Table dataSource={pairs} rowKey="id" {...clickTableProps} columns={pairColumns}>
            </Table>
        </>
    )
}

export const AssetLendigns: React.FC<{ asset: Asset, lendingData?: LendingDTO[] }> = ({ asset, lendingData }) => {
    const translate = useTranslate();
    const clickTableProps = useTableClick({ resource: 'lending', action: 'edit' });
    const lendingTypeFilter = getEnumFilter(LendingType, 'LendingType', translate, ["Unknown"]);
    const getRowClassName = (record: LendingDTO): string => {
        return 'clickable-row default-action-edit ' + ( 'future' );
    }

    return (

        <Table
            dataSource={lendingData}
            {...clickTableProps}
            rowKey="id"
            rowClassName={(record: BaseRecord) => getRowClassName(record as never as LendingDTO)}
            footer={() => <Flex justify="end"><ListButton className="" resource="lending" meta={{ assetId: asset.id }} >{translate('asset.show.allLendings')}</ListButton></Flex>}
        >
            <Table.Column
                dataIndex={["effectiveFrom"]}
                className="date"
                title={translate("lending.fields.effectiveFrom")}
                key={"effectiveFrom"}
                render={(value: any, record: LendingDTO) => <DateField value={dayjs.unix(record.effectiveFrom)} />}
            />
            <Table.Column
                dataIndex="lendingType"
                sorter={true}
                title={translate("lending.fields.lendingType")}
                filters={lendingTypeFilter}
                render={(value: LendingType) => <EnumField enum='LendingType' translate={translate} value={value} />}
            />
            <Table.Column
                dataIndex="basicRate"
                title={translate("lending.fields.basicRate")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
            <Table.Column
                dataIndex="fromAmount1"
                title={translate("lending.fields.fromAmount1")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
            <Table.Column
                dataIndex="rate1"
                title={translate("lending.fields.rate1")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
            <Table.Column
                dataIndex="fromAmount2"
                title={translate("lending.fields.fromAmount2")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
            <Table.Column
                dataIndex="rate2"
                title={translate("lending.fields.rate2")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
            <Table.Column
                dataIndex="fromAmount3"
                title={translate("lending.fields.fromAmount3")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
            <Table.Column
                dataIndex="rate3"
                title={translate("lending.fields.rate3")}
                align="right"
                render={(value: any) => <NumberField value={value} />}
            />
        </Table>

    )
}

export const AssetShow: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const { id, tab } = useParams();

    const { queryResult } = useShow<Asset>({
        id: id,
        resource: 'asset',
        meta: {
            query: {
                _include: ['allPairBasesOfAsset', 'allPairQuotesOfAsset']
            }
        }
    });

    const { data, isLoading } = queryResult;
    const asset = data?.data;
    const { data: lendingData, isLoading: lendingIsLoading } = useOne({
        resource: "activelendings",
        meta: {
            endpoint: 'specialized/activelendings?assetId='+id
        },
        id: id,
    });

    if (!asset) {
        return <>Loading</>
    }

    const items: TabsProps['items'] = [
        {
            key: 'details',
            label: translate("asset.show.details"),
            children: <AssetInfo asset={asset} />,
        },
        {
            key: 'allPairQuotesOfAsset',
            label: translate("asset.show.allPairQuotesOfAsset"),
            children: <AssetPairs asset={asset} pairs={asset.allPairQuotesOfAsset} />,
        },
        {
            key: 'allPairBasesOfAsset',
            label: translate("asset.show.allPairBasesOfAsset"),
            children: <AssetPairs asset={asset} pairs={asset.allPairBasesOfAsset} />,
        },
        {
            key: 'lendings',
            label: translate("asset.show.activeLendings"),
            children: <AssetLendigns asset={asset} lendingData={lendingData?.data[id!]} />,
        },
    ];

    const onChange = (key: string) => {
        navigate(`/asset/${id}/show/${key}`, { replace: true });
    };

    return (
        <Show isLoading={isLoading || lendingIsLoading}
            resource="asset" recordItemId={id}
            headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    <ListButton resource="lending" meta={{ assetId: id }} >{translate('lending.lending')}</ListButton>
                </>
            )}
        >
            <Tabs items={items} defaultActiveKey={tab} onChange={onChange} />
        </Show>
    );
};
