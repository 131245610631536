import { useList } from '@refinedev/core';
import { AssetClass, AssetClassesEnum } from '../../interfaces';


// TODO:  HACK! use query cache
const stored = new Map<AssetClassesEnum, AssetClass>();

export function useAssetClassData() {
    const { data } = useList<AssetClass>({
        resource: 'assetClass',
        hasPagination: false,
        meta: {
        },
        queryOptions: {
            enabled: stored.size === 0
        }
    });

    if (stored.size === 0 && data?.data) {
        data?.data.forEach((item: AssetClass) => {
            stored.set(item.id, item);
        });
    }

    return stored;
}
