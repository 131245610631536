import React from 'react';

type EnumFiledProps = {
    enum?: string;
    translate: CallableFunction;
    value?: number | string | null | undefined;
}

export const EnumField: React.FC<EnumFiledProps> = (props) => {
    if (!props.value) {
        return <></>
    }

    return (
        <>{props.translate(`enums.${props.enum}.${props.value}`)}</>
    );
};
