import { EnumToObject } from "../utils";
import { Account } from "./account";
import { LyraSequential } from "./base";
import { MarginCallPolicy } from "./marginCallPolicy";
import { Position } from "./position";

export enum MarginCallStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    ResolvedMarket = 'ResolvedMarket',
    ResolvedFunding = 'ResolvedFunding',
    ResolvedIgnored = 'ResolvedIgnored',
    ResolvedManualCloseout = 'ResolvedManualCloseout',
    ResolvedAutoCloseout = 'ResolvedAutoCloseout',
    NotificationIssued = 'NotificationIssued'
}

export const MarginCallStatusNames = EnumToObject(MarginCallStatus);

export enum MarginCallKind {
    Unknown = 'Unknown',
    Warning = 'Warning',
    Critical = 'Critical',
    Liquidation = 'Liquidation',
}

export const MarginCallKindNames = EnumToObject(MarginCallKind);

export interface MarginCall extends LyraSequential {
    accountId: number;
    account: Account;
    policyId: number;
    policy: MarginCallPolicy;
    cash: number;
    status: MarginCallStatus;
    kind: MarginCallKind;
    positionCount: number;
    unrealizedPnl: number;
    totalMargin: number;
    totalValue: number;
    netEquity: number;
    availableToTrade: number;
    marginIndicator: number;
    issuedOn: Date;
    resolvedOn: Date;
    userNotifiedOn: Date;
    positions: Position[];
}
