import React from "react";
import {
    IResourceComponentsProps,
    BaseRecord,
    useTranslate,
    useMany,
} from "@refinedev/core";

import {
    useTable,
    List,
    EditButton,
    ShowButton,
    DeleteButton
} from "@refinedev/antd";
import { Table, Space } from "antd";
import { useTableClick } from "../../hooks/useTableClick";
import { StaffUserAccessControl, User } from "../../interfaces";
import { defaultPageSize } from "../../constants";
import { useFilter } from "../../hooks/useFilter";

export const StaffUserList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    
    // const query = {
    //     _include: ['staffUser', 'managedUserGroup']
    // };
    
    const { tableProps } = useTable({
        syncWithLocation: true,
        // meta: { query },
        initialPageSize: defaultPageSize,
    });
    const clickTableProps = useTableClick({ resource: 'staffUserAccessControl', action: 'edit' });

    const { data: users } = useMany<User>({
        resource: "user",
        ids: tableProps?.dataSource?.map((item: BaseRecord) => item?.staffUserId) ?? [],
        queryOptions: {
            enabled: !!tableProps?.dataSource,
        },
    });
    const userFilters = useFilter({ resource: 'userGroup', labelName: 'name' })

    return (
        <List resource="staffUser">
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex={["staffUserId"]}
                    sorter={true}
                    title={translate("staffUser.fields.staffUser")}
                    render={(_, record: StaffUserAccessControl) => users?.data?.find((user) => user.identityUserId === record.staffUser?.identityUserId)?.email}
                />
                <Table.Column
                    dataIndex={'managedUserGroupId'}
                    sorter={true}
                    filters={userFilters}
                    title={translate("staffUser.fields.managedUserGroup")}
                    render={(_, record: StaffUserAccessControl) => record?.managedUserGroup?.name}
                />
                <Table.Column
                    align="right"
                    width={130}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
