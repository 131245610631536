

import {
    useOne, useTranslate
} from "@refinedev/core";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { UnitValueField } from "../../../../components/fields";
import { Asset, BrokerVarInfo, ExposureEntryMetrics, VaRMethod } from "../../../../interfaces";
import { useConfidence } from "../filters/confidence";
import { useMonteCarloOptions } from "../filters/mcOptions";
import { useMethod } from "../filters/method";
import { useWindow } from "../filters/window";

const { Title } = Typography;

interface VaRComponentProps {
    currentEntry: ExposureEntryMetrics;
    setCurrentEntry: React.Dispatch<React.SetStateAction<ExposureEntryMetrics | null>>;
    asset?: Asset;
}



export const VaRComponent: React.FC<VaRComponentProps> = ({ currentEntry, setCurrentEntry, asset }) => {

    const translate = useTranslate();
    const [windowComponent, window] = useWindow();
    const [methodComponent, method] = useMethod();
    const [confidenceComponent, confidence] = useConfidence();
    const [monteCarloComponent, numberOfPaths, horizon] = useMonteCarloOptions();

    const [varQueryId, setVaRQueryId] = useState<undefined | number>(undefined);
    const { data: varData } = useOne<BrokerVarInfo>({
        resource: 'brokerVaR',
        id: varQueryId,
        meta: {
            query: {
                method: method,
                window: window,
                confidence: confidence,
                monteCarloNumPaths: numberOfPaths,
                horizon: horizon
            }
        }
    });

    useEffect(() => {
        if (varData && currentEntry) {
            setCurrentEntry({ ...currentEntry, ...varData.data });
            setVaRQueryId(undefined);
        }
    }, [varData]);

    const calculateRisks = useCallback(() => {
        setVaRQueryId(Date.now())
    }, []);

    return <>
        <Row justify="start" className="filtersContainer">
            <Col>
                <Space size="middle">
                    <div>{methodComponent}</div>
                    <div>{windowComponent}</div>
                    <div>{confidenceComponent}</div>
                    {method === VaRMethod.MonteCarlo && monteCarloComponent}
                    <div>
                        <div><label>&nbsp;</label></div>
                        <Button type="primary" onClick={() => calculateRisks()}>{translate('var.buttons.calculate')}</Button>
                    </div>
                </Space>
            </Col>
        </Row>

        <Row>
            <Col className="scoreContainer">
                <Space size="large">
                    <Card title={translate('var.fields.var')} size="small">
                        <div className="score">
                            <Title level={3}>
                                <Space direction="horizontal" align="baseline">
                                    <UnitValueField
                                        value={currentEntry.vaR}
                                        digits={currentEntry.denominatedIn.displayDecimals}
                                        unit={asset?.symbol}
                                    />
                                    (
                                    <UnitValueField
                                        value={currentEntry.vaR! / currentEntry.totalValue! * 100}
                                        digits={2}
                                        unit="%"
                                    />)
                                </Space>
                            </Title>
                        </div>
                    </Card>
                    <Card title={translate('var.fields.cvar')} size="small">
                        <div className="score">
                            <Title level={3}>
                                <Space direction="horizontal" align="baseline">
                                    <UnitValueField
                                        value={currentEntry.cVaR}
                                        digits={currentEntry.denominatedIn.displayDecimals}
                                        unit={asset?.symbol}
                                    />
                                    (
                                    <UnitValueField
                                        value={currentEntry.cVaR! / currentEntry.totalValue! * 100}
                                        digits={2}
                                        unit="%"
                                    />)
                                </Space>

                            </Title>
                        </div>
                    </Card>
                    <Card title={translate('var.fields.volatility')} size="small">
                        <Title level={3}>
                            <div className="score">
                                <UnitValueField
                                    value={(currentEntry?.volatility || 0) * 100}
                                    unit="%"
                                    digits={2}
                                />
                            </div>
                        </Title>
                    </Card>
                    <Card title={translate('var.fields.totalValue')} size="small">
                        <Title level={3}>
                            <div className="score">
                                <UnitValueField
                                    value={currentEntry?.totalValue}
                                    digits={currentEntry.denominatedIn.displayDecimals}
                                    unit={asset?.symbol}
                                />
                            </div>
                        </Title>
                    </Card>
                </Space>
            </Col>
        </Row>
    </>
}
