import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import {
    DeleteButton,
    EditButton,
    ShowButton,
    UrlField,
    getDefaultSortOrder,
    useTable,
} from "@refinedev/antd";
import {
    BaseRecord,
    CrudFilters,
    IResourceComponentsProps,
    getDefaultFilter,
    useOne,
    useTranslate,
} from "@refinedev/core";
import { Popover, Space, Table, Tooltip } from "antd";
import React, { useMemo } from "react";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { Exchange } from "../../interfaces";
import { getBooleanTableFilter } from "../../utils";
import { BooleanField } from "../../components/fields";
import { List } from "../../components/crud/list";



type CountryCityRecord = {
    [key: string]: string[];
}

interface CountryCity {
    data?: CountryCityRecord;
}

function useCountryCityFilters(countryCity: CountryCity, filters: CrudFilters) {
    const countryFilter = getDefaultFilter("countryName", filters, "in");

    const countriesFilters = useMemo(() => {
        return countryCity?.data ? Object.keys(countryCity?.data).map((name: string) => ({ text: name ? name : 'N/A', value: name })) : [];
    }, [countryCity?.data]);

    const citiesFilters = useMemo(() => {
        return countryCity?.data ? Object.entries(countryCity?.data).map(([key, value]) => ({
            text: key ? key : 'N/A',
            value: key,
            children: value.map((city: string) => ({
                text: city ? city : "N/A",
                value: city
            }))
        })).filter((countryName => !countryFilter || countryFilter.includes(countryName.value))) : [];
    }, [countryCity?.data, countryFilter]);

    return { countriesFilters, citiesFilters };
}

export const ExchangeList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { search, listProps } = useSearchList();

    const { data: countryCity, isLoading: ccIsLoading } = useOne<CountryCityRecord>({
        resource: "crminfo",
        id: 'exchanges',
    });

    const query: Record<string, string> = {};
    if (search) {
        query.nameLIKE = search;
        query.descriptionLIKE = search;
        query.micCodeLIKE = search;
        query.legalEntityLIKE = search;
    }
    const { tableProps, sorters, filters } = useTable({
        syncWithLocation: true,
        initialPageSize: defaultPageSize,
        meta: { query },
        sorters: {
            initial: [
                {
                    field: "micCode",
                    order: "asc",
                },
            ],
        }
    });
    const clickTableProps = useTableClick({ resource: 'exchange' });
    const yesNo = getBooleanTableFilter(translate);

    const {countriesFilters, citiesFilters} = useCountryCityFilters(countryCity as CountryCity, filters);

    return (
        <List {...listProps}>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex="micCode"
                    sorter={true}
                    title={translate("exchange.fields.micCode")}
                    defaultSortOrder={getDefaultSortOrder("micCode", sorters)}
                />
                <Table.Column
                    dataIndex="description"
                    title={translate("exchange.fields.description")}
                />
                <Table.Column
                    dataIndex="legalEntity"
                    title={translate("exchange.fields.legalEntity")}
                />
                {/* 
                <Table.Column
                    dataIndex="opMic"
                    title={translate("exchange.fields.opMic")}
                />
                <Table.Column
                    dataIndex="lei"
                    title={translate("exchange.fields.lei")}
                />
                <Table.Column
                    dataIndex="mcc"
                    title={translate("exchange.fields.mcc")}
                />
                <Table.Column
                    dataIndex="acro"
                    title={translate("exchange.fields.acro")}
                /> */}
                <Table.Column
                    sorter={true}
                    dataIndex="countryName"
                    title={translate("exchange.fields.countryName")}
                    // {...getColumnSearchProps('countryName')}
                    filters={countriesFilters}
                />
                {/* <Table.Column
                    dataIndex="isoCountry"
                    title={translate("exchange.fields.isoCountry")}
                /> */}
                <Table.Column
                    dataIndex="city"
                    sorter={true}
                    title={translate("exchange.fields.city")}
                    filters={citiesFilters}
                />
                <Table.Column
                    dataIndex="site"
                    title={translate("exchange.fields.site")}
                    render={(value: any) => {
                        if (!value) return null;
                        const url = `https://${value}`;
                        return <UrlField value={url} target="blank"><Tooltip title={url}><LinkOutlined /></Tooltip></UrlField>
                    }}
                />
                <Table.Column
                    dataIndex="comments"
                    title={translate("exchange.fields.comments")}
                    render={(value: any, record: Exchange) => (
                        value && <Popover content={value} placement="rightBottom">
                            <InfoCircleOutlined />
                        </Popover>)}
                />
                {/* <Table.Column
                    dataIndex="timeZone"
                    title={translate("exchange.fields.timeZone")}
                /> */}
                <Table.Column
                    dataIndex="timeOffset"
                    sorter={true}
                    title={translate("exchange.fields.timeOffset")}
                />
                <Table.Column
                    dataIndex={["stock"]}
                    title={translate("exchange.fields.stock")}
                    filters={yesNo}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["etf"]}
                    title={translate("exchange.fields.etf")}
                    filters={yesNo}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["index"]}
                    title={translate("exchange.fields.index")}
                    filters={yesNo}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["fx"]}
                    title={translate("exchange.fields.fx")}
                    filters={yesNo}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["crypto"]}
                    filters={yesNo}
                    title={translate("exchange.fields.crypto")}
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    align="end"
                    width={100}
                    dataIndex="actions"
                    render={(_:any, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
