import React, { useContext } from "react";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, StopOutlined } from "@ant-design/icons";
import {
    useCustomMutation,
    useNotification,
    useApiUrl,
    useTranslate,
    useMutationMode,
    useCan,
    useResource,
    pickNotDeprecated,
    useWarnAboutChange,
    AccessControlContext,
    useInvalidate,
    pickDataProvider,
    useUpdate,
} from "@refinedev/core";
import {
    RefineButtonClassNames,
    RefineButtonTestIds,
} from "@refinedev/ui-types";
import { DeleteButtonProps } from "@refinedev/antd";
import { MarginCall, MarginCallStatus, Position } from "../../interfaces";


export type MarginCallActionButtonProps = DeleteButtonProps & {
    action: "closeout" | "ignore";
};


export const MarginCallActionButton: React.FC<MarginCallActionButtonProps> = ({
    resource: resourceNameFromProps,
    resourceNameOrRouteName: propResourceNameOrRouteName,
    recordItemId,
    onSuccess,
    mutationMode: mutationModeProp,
    children,
    successNotification,
    errorNotification,
    hideText = false,
    accessControl,
    metaData,
    meta,
    dataProviderName,
    confirmTitle,
    confirmOkText,
    confirmCancelText,
    invalidates,
    action,
    ...rest
}) => {
    const accessControlContext = useContext(AccessControlContext);

    const accessControlEnabled =
        accessControl?.enabled ??
        accessControlContext.options.buttons.enableAccessControl;

    const hideIfUnauthorized =
        accessControl?.hideIfUnauthorized ??
        accessControlContext.options.buttons.hideIfUnauthorized;

    const translate = useTranslate();
    const { id, resource, identifier, resources, select } = useResource(
        resourceNameFromProps ?? propResourceNameOrRouteName,
    );

    const { mutate } = useUpdate<MarginCall>();

    const { data } = useCan({
        resource: resource?.name,
        action: "update",
        params: { id: recordItemId ?? id, resource },
        queryOptions: {
            enabled: accessControlEnabled,
        },
    });

    const disabledTitle = () => {
        if (data?.can) return "";
        else if (data?.reason) return data.reason;
        else
            return translate(
                "buttons.notAccessTitle",
                "You don't have permission to access",
            );
    };

    const { setWarnWhen } = useWarnAboutChange();

    if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
        return null;
    }

    return (
        <Popconfirm
            key="delete"
            okText={confirmOkText ?? translate(`marginCall.buttons.${action}`, "Ignore")}
            cancelText={
                confirmCancelText ?? translate("buttons.cancel", "Cancel")
            }
            okType="danger"
            title={
                confirmTitle ?? translate("buttons.confirm", "Are you sure?")
            }
            onConfirm={(): void => {
                if ((recordItemId ?? id) && identifier) {
                    setWarnWhen(false);
                    
                    mutate({
                        resource: identifier,
                        values: { 
                            status: action === "closeout" ? MarginCallStatus.ResolvedManualCloseout : MarginCallStatus.ResolvedIgnored
                        },
                        id: recordItemId!,
                    });
                }
            }}
            disabled={
                typeof rest?.disabled !== "undefined"
                    ? rest.disabled
                    : data?.can === false
            }
        >
            <Button
                danger
                icon={<StopOutlined />}
                title={disabledTitle()}
                disabled={data?.can === false}
                data-testid={RefineButtonTestIds.DeleteButton}
                className={RefineButtonClassNames.DeleteButton}
                {...rest}
            >
                {!hideText &&
                    (children ?? translate(`marginCall.buttons.${action}`))}
            </Button>
        </Popconfirm>
    );
};
