import { BooleanField, EditButton } from '@refinedev/antd';
import { BaseRecord, useTranslate } from '@refinedev/core';
import { Space, Table, Typography } from 'antd';
import React from 'react';
import { NumberField, UnitValueField } from '../../components/fields';
import FlagField from '../../components/fields/FlagFiled';
import { Breach, ExposureLimitBreachActionEnum } from '../../interfaces';


interface BreachesTableProps {
    breaches: Breach[];
    title: string;
    idRender?: (value: string) => React.ReactNode;
    editId?: (record: Breach) => string;
    editResource?: string;
    displayDecimals: number,
}

const BreachesTable: React.FC<BreachesTableProps> = ({ breaches, title, idRender, editId, editResource, displayDecimals }) => {
    const translate = useTranslate();
    const { Title } = Typography;
    const digits = displayDecimals;

    return (
        <>
            <Title level={4}>{title}</Title>
            <Table dataSource={breaches} rowKey={"underlying.id"} pagination={false} >
                <Table.Column
                    dataIndex={["underlying", "id"]}
                    title={translate("exposure.breaches.fields.id")}
                    render={idRender}
                />
                <Table.Column
                    dataIndex={["actionFlags"]}
                    title={translate("exposure.breaches.fields.actionFlags")}
                    render={(value: number) => <FlagField value={value} enum={ExposureLimitBreachActionEnum} name="ExposureLimitBreachActionEnum" />}
                />
                <Table.ColumnGroup title={translate("exposure.utilization.fields.long")}>
                    <Table.Column
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.expLimLongT1 - b.underlying.expLimLongT1}
                        dataIndex={["underlying", "expLimLongT1"]}
                        title={translate("exposure.breaches.fields.expLimT1")}
                        render={(value: number) => <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        dataIndex={["underlying", "suspendNewLong"]}
                        title={translate("exposure.breaches.fields.suspendNew")}
                        align='center'
                        render={(value: boolean) => (
                            <BooleanField value={value} trueIcon={"⚠️"} falseIcon={""} valueLabelTrue={translate("exposure.breaches.fields.suspendNewLong")} />
                        )}
                    />
                    <Table.Column
                        align='end'
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.marginMultiplierOpenLong - b.underlying.marginMultiplierOpenLong}
                        dataIndex={["underlying", "marginMultiplierOpenLong"]}
                        title={translate("exposure.breaches.fields.marginMultiplierOpen")}
                        render={(value: number) => (
                            <UnitValueField value={value * 100} unit='%' digits={2} />
                        )}
                    />
                    <Table.Column
                        align='end'
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.marginMultiplierMaintainLong - b.underlying.marginMultiplierMaintainLong}
                        dataIndex={["underlying", "marginMultiplierMaintainLong"]}
                        title={translate("exposure.breaches.fields.marginMultiplierMaintain")}
                        render={(value: number) => (
                            <UnitValueField value={value * 100} unit='%' digits={2} />
                        )}
                    />
                </Table.ColumnGroup>
                <Table.ColumnGroup title={translate("exposure.utilization.fields.short")}>
                    <Table.Column
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.expLimShortT1 - b.underlying.expLimShortT1}
                        dataIndex={["underlying", "expLimShortT1"]}
                        title={translate("exposure.breaches.fields.expLimT1")}
                        render={(value: number) => <NumberField value={value} digits={digits} />}
                    />
                    <Table.Column
                        dataIndex={["underlying", "suspendNewShort"]}
                        title={translate("exposure.breaches.fields.suspendNew")}
                        render={(value: boolean) => (
                            <BooleanField value={value} trueIcon={"⚠️"} falseIcon={""} valueLabelTrue={translate("exposure.breaches.fields.suspendNewShort")} />
                        )}
                    />
                    <Table.Column
                        align='end'
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.marginMultiplierOpenShort - b.underlying.marginMultiplierOpenShort}
                        dataIndex={["underlying", "marginMultiplierOpenShort"]}
                        title={translate("exposure.breaches.fields.marginMultiplierOpen")}
                        render={(value: number) => (
                            <UnitValueField value={value * 100} unit='%' digits={2} />
                        )}
                    />
                    <Table.Column
                        align='end'
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.marginMultiplierMaintainShort - b.underlying.marginMultiplierMaintainShort}
                        dataIndex={["underlying", "marginMultiplierMaintainShort"]}
                        title={translate("exposure.breaches.fields.marginMultiplierMaintain")}
                        render={(value: number) => (
                            <UnitValueField value={value * 100} unit='%' digits={2} />
                        )}
                    />
                </Table.ColumnGroup>
                <Table.ColumnGroup title={translate("exposure.breaches.fields.expLimNettedAbsT1")}>
                    <Table.Column
                        sorter={(a: BaseRecord, b: BaseRecord) => a.underlying.expLimNettedAbsT1 - b.underlying.expLimNettedAbsT1}
                        dataIndex={["underlying", "expLimNettedAbsT1"]}
                        title={translate("exposure.breaches.fields.expLimT1")}
                        render={(value: number) => <NumberField value={value} digits={digits} />}
                    />
                </Table.ColumnGroup>
                <Table.Column
                    width={130}
                    align="right"
                    dataIndex="actions"
                    render={(_, record: Breach) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                resource={editResource}
                                recordItemId={editId ? editId(record) : record.underlying.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </>
    );
};

export default BreachesTable;