import { EditButton, ShowButton, useModalForm } from "@refinedev/antd";
import {
    BaseRecord,
    useTranslate
} from "@refinedev/core";
import { Modal, Space, Table } from "antd";
import { TableProps } from "antd/lib";
import { ColumnFilterItem } from "antd/lib/table/interface";
import React, { useMemo } from "react";
import { CancelOrderButton } from "../../components/buttons/cancelOrder";
import { DateField, EnumField, LogoPairField, NumberField, UnitValueField } from "../../components/fields";
import { useReferenceData } from "../../hooks/useReferenceFilter";
import { useTableClick } from "../../hooks/useTableClick";
import { ExecutionMethod, Order, OrderPositionAssociation, OrderType } from "../../interfaces";
import { OrderSide } from "../../interfaces/triggerable";
import { getEnumFilter } from "../../utils";
import { OrderForm } from "./form";


interface OrderTableProps {
    internal: boolean;
    withIdentity: boolean;
    tableProps: TableProps;
    resource: string;
}

export const OpenOrdersTable: React.FC<OrderTableProps> = ({ tableProps, internal, resource }) => {
    const translate = useTranslate();

    const getRowClassName = (record: Order) => {
        return 'default-action-show clickable-row position-' + (record.side === OrderSide.Buy ? 'long' : 'short');
    }

    const typeFilter = getEnumFilter(OrderType, 'OrderType', translate, ["Unknown"]);
    const sideFilter = getEnumFilter(OrderSide, 'OrderSide', translate, ["Unknown"]);
    const pairFilters = useMemo(() => {
        return tableProps.dataSource?.map((item: Order) => { return { text: item.pairId, value: item.pairId } as ColumnFilterItem })
    }, [tableProps.dataSource]);
    const methodFilter = getEnumFilter(ExecutionMethod, 'ExecutionMethod', translate, ["Unknown"]);

    // const accountsHash = useReferenceData({ resource: 'account', ids: tableProps.dataSource?.map((item: Order) => item.accountId) });
    const pairsHash = useReferenceData({ resource: 'pair/info', ids: tableProps.dataSource?.map((item: Order) => item.pairId) });
    // const accountAssetHash = useReferenceData({ resource: 'asset', ids: tableProps.dataSource?.map((item: Order) => item.identity?.denominatedIn) });

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
        open: editModalOpen,
    } = useModalForm<Order>({
        resource: "order",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });

    const clickTableProps = useTableClick({ resource: resource, action: 'show' });

    return <><Table
        {...tableProps}
        rowKey="id"
        className="wrap-normal"
        {...clickTableProps}
        rowClassName={(record: Order) => getRowClassName(record)}
    >
        <Table.Column
            dataIndex="token"
            title={translate("order.fields.token")}
        />
        {!internal && <Table.Column
            dataIndex={["pairId"]}
            title={translate("order.fields.pairId")}
            render={(value) => <LogoPairField pairId={value} />}
            filters={pairFilters}
            onFilter={(value, record: Order) => record.pairId == value}
        />}
        <Table.Column
            dataIndex="type"
            title={translate("order.fields.type")}
            filters={typeFilter}
            render={(value: OrderType) => <EnumField enum="OrderType" value={value} translate={translate} />}
            onFilter={(value, record: Order) => record.type == value}
        />
        {!internal && <Table.Column
            dataIndex="side"
            title={translate("order.fields.side")}
            filters={sideFilter}
            render={(value: OrderSide) => <EnumField enum="OrderSide" value={value} translate={translate} />}
            onFilter={(value, record: Order) => record.side == value}
        />}
        <Table.Column
            dataIndex="quotedPrice"
            align="end"
            title={translate("order.fields.quotedPrice")}
            render={(value: number, record: Order) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}
        />
        <Table.Column
            dataIndex="qty"
            align="end"
            title={translate("order.fields.qty")}
            render={(value: number, record: Order) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.baseSymbol} />}
        />
        <Table.Column
            dataIndex="referenceType"
            title={translate("order.fields.referenceType")}
            render={(value: OrderPositionAssociation) => <EnumField enum="OrderPositionAssociation" value={value} translate={translate} />}
        />
        <Table.Column
            align="end"
            dataIndex="reservedAmount"
            title={translate("order.fields.reservedAmount")}
            render={(value: number) => <NumberField value={value} />}
        />
        <Table.Column
            align="end"
            dataIndex="marginRatio"
            title={translate("order.fields.marginRatio")}
            render={(value: number) => <NumberField value={value} />}
        />
        <Table.Column
            align="end"
            dataIndex="triggerPrice"
            title={translate("order.fields.triggerPrice")}
            render={(value: number, record: Order) => <>{value != 0 && <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.quoteSymbol} />}</>}
        />

        <Table.Column
            align="end"
            dataIndex="method"
            filters={methodFilter}
            onFilter={(value, record: Order) => record.method === value}
            sorter={(a: Order, b: Order) => a.method > b.method ? 1 : -1}
            title={translate("order.fields.method")}
            render={(value: number) => <EnumField value={value} enum="ExecutionMethod" translate={translate} />}
        />
        <Table.Column
            align="end"
            dataIndex="connectorId"
            sorter={(a: Order, b: Order) => a.connectorId > b.connectorId ? 1 : -1}
            title={translate("order.fields.connectorId")}
        />
        <Table.Column
            align="end"
            dataIndex="routedId"
            sorter={(a: Order, b: Order) => a.routedId > b.routedId ? 1 : -1}
            title={translate("order.fields.routedId")}
        />

        <Table.Column
            align="end"
            dataIndex={["createdAt"]}
            title={translate("order.fields.createdAt")}
            render={(value: string) => <DateField value={value} />}
        />
        <Table.Column
            align="end"
            width={100}
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
                <Space>
                    <CancelOrderButton
                        hideText
                        size="small"
                        resource={resource}
                        ids={[record.id]}
                        confirmOkText={translate("management_margin.buttons.confirm_cancel")}
                    />
                    <EditButton
                        hideText
                        size="small"
                        resource="order"
                        recordItemId={record.id}
                        onClick={() => {
                            editModalShow(record.id);
                        }}
                    />
                    <ShowButton
                        hideText
                        size="small"
                        resource="order"
                        recordItemId={record.id}
                    />
                </Space>
            )}
        />
    </Table>
        {editModalOpen && <Modal {...editModalProps}>
            <OrderForm formProps={editFormProps} />
        </Modal>}
    </>

};
