import { Descriptions, DescriptionsProps } from "antd";
import React from "react";


type DescriptionGroupItem = DescriptionsProps;

export const DescriptionGroups: React.FC<DescriptionsProps & { groups: DescriptionGroupItem[] }> = ({
    groups, ...rest
}) => {
    return <div className="description-groups">{groups.map((group, idx) => <Descriptions key={idx} {...rest} {...group} />)}</div>
}