import React from "react";
import { useTranslate } from "@refinedev/core";
import { useSelect } from "@refinedev/antd";
import { Form, Input, Checkbox, Select, FormProps } from "antd";
import { MarginCallPolicy } from "../../interfaces";
import { defaultFormLayout } from "../../constants";

export const AccountGroupsForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const { queryResult: mcQR } = useSelect<MarginCallPolicy>({
        resource: 'marginCallPolicy',
    });

    const options = mcQR?.data?.data.map((item: MarginCallPolicy) => { return { label: item.description, value: item.id } });

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
            labelCol= {{ flex: '150px' }}
        >
            <Form.Item
                label={translate("accountGroup.fields.name")}
                name={["name"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("accountGroup.fields.description")}
                name={["description"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                valuePropName="checked"
                name={["isDefaultGroup"]}
            >
                <Checkbox>{translate("accountGroup.fields.isDefaultGroup")}</Checkbox>
            </Form.Item>
            <Form.Item
                valuePropName="checked"
                name={["hasNBP"]}
            >
                <Checkbox>{translate("accountGroup.fields.hasNBP")}</Checkbox>
            </Form.Item>
            <Form.Item
                label={translate("accountGroup.fields.marginCallPolicy")}
                name={"marginCallPolicyId"}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select options={options} />
            </Form.Item>
        </Form>
    );
};
