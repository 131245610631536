import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import { BankAccountForm } from "./form";
import { useParams } from "react-router-dom";
import { Edit } from "../../components/crud/edit";

export const BankAccountEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { id: userId, bankAccountId } = useParams();
    const { formProps, saveButtonProps, queryResult } = useForm({
        redirect: 'show',
        id: bankAccountId,
        meta: {userId: userId}
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <BankAccountForm formProps={formProps} />
        </Edit>
    );
};
