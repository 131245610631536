

import {
    useOne, useTranslate
} from "@refinedev/core";
import { Button, Card, Col, Row, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { UnitValueField } from "../../../../components/fields";
import { Asset, BrokerRatioInfo, ExposureEntryMetrics } from "../../../../interfaces";
import { useRisk } from "../filters/risk";
import { useWindow } from "../filters/window";

const { Title } = Typography;

interface RatiosComponentProps {
    currentEntry: ExposureEntryMetrics;
    setCurrentEntry: React.Dispatch<React.SetStateAction<ExposureEntryMetrics | null>>;
    asset?: Asset;
}


export const RatiosComponent: React.FC<RatiosComponentProps> = ({ currentEntry, setCurrentEntry }) => {

    const translate = useTranslate();
    const [windowComponent, window] = useWindow();
    const [riskComponent, risk] = useRisk();

    // calculate ratios
    const [ratiosQueryId, setRatiosQueryId] = useState<undefined | number>(undefined);
    const { data: ratiosData } = useOne<BrokerRatioInfo>({
        resource: 'brokerRatios',
        id: ratiosQueryId,
        meta: {
            query: {
                window: window,
                riskFreeRate: risk,
            }
        }
    });

    useEffect(() => {
        if (ratiosData && currentEntry) {
            setCurrentEntry({ ...currentEntry, sortinoRatio: ratiosData.data.sortino, sharpeRatio: ratiosData.data.sharpe });
            setRatiosQueryId(undefined);
        }
    }, [ratiosData]);

    const calculateRatios = useCallback(() => {
        setRatiosQueryId(Date.now())
    }, []);

    return <>
        <Row justify="start" className="filtersContainer">
            <Col>
                <Space size="middle">
                    <div>{windowComponent}</div>
                    <div>{riskComponent}</div>
                    <div>
                        <div><label>&nbsp;</label></div>
                        <Button type="primary" onClick={() => calculateRatios()}>{translate('var.buttons.calculate')}</Button>
                    </div>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col className="scoreContainer">
                <Space size="large">
                    <Card title={translate('var.fields.sharpe')} size="small">
                        <div className="score">
                            <Title level={3}>
                                <UnitValueField
                                    value={currentEntry?.sharpeRatio}
                                    digits={2}
                                />
                            </Title>
                        </div>
                    </Card>
                    <Card title={translate('var.fields.sortino')} size="small">
                        <div className="score">
                            <Title level={3}>
                                <UnitValueField
                                    value={currentEntry?.sortinoRatio}
                                    digits={2}
                                />
                            </Title>
                        </div>
                    </Card>
                </Space>
            </Col>
        </Row>
    </>
}
