import { useTable } from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useTranslate
} from "@refinedev/core";
import { Select } from "antd";
import React from "react";
import { List } from "../../components/crud/list";
import { LoginEvent } from "../../interfaces";
import { LoginHistoryTable } from "./table";


export const LoginHistoryList: React.FC<IResourceComponentsProps> = () => {
    const [hours, setHours] = React.useState(24);
    const { tableProps, sorters } = useTable<LoginEvent>({
        resource: "loginhistory",
        syncWithLocation: true,
        sorters: {
            initial: [
                {
                    field: "at",
                    order: "desc",
                },
            ],
        },
        meta: { query: { hours: hours } }
    });

    const translate = useTranslate();


    const historyOptions = [
        { label: translate("common.dateRange.hour"), value: 1 },
        { label: translate("common.dateRange.day"), value: 24 },
        { label: translate("common.dateRange.week"), value: 168 },
        { label: translate("common.dateRange.month"), value: 720 },
    ]

    const extraButtons = (headerProps: any) => {
        return (
            <Select options={historyOptions} value={hours} onChange={setHours} />
        );
    }

    return (
        <List extraButtons={extraButtons}>
            <LoginHistoryTable tableProps={tableProps} sorters={sorters} />
        </List>
    );
};
