import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { AccountGroupsForm } from "./form";

export const AccountGroupCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <AccountGroupsForm formProps={formProps}></AccountGroupsForm>
        </Create>
    );
};
