import { LyraSequential } from "./base";

export enum AccountType {
  Unknown = 'Unknown',
  BankAccount = 'BankAccount',
  Card = 'Card',
  CryptoWallet = 'CryptoWallet',
}

export interface BankAccount extends LyraSequential {
  ownerId: number;
  denominatedInAssetId: string;
  accountType: AccountType;
  nameOnTheAccount: string;
  bankName: string;
  bankAddress?: string;
  bankCity?: string;
  bankState: string;
  bankZip?: string;
  bankCountry: string;
  accountNumber: string;
  routingNumber?: string;
  SWIFTCode?: string;
  cardNumber?: string;
  walletAddress?: string;
  networkName?: string;
}
