import { useTranslate } from '@refinedev/core';
import { PivotTable } from '@visactor/react-vtable';
import React from 'react';
import { AccountingAggregation, AccountingReportLine, Asset, AssetClassesEnumToString } from '../../interfaces';
import { formatNumberFix, formatNumberTrim } from '../../utils';

interface AccountingTableProps {
    currency: string;
    dimensions: AccountingAggregation[];
    types: string[];
    fromGTE?: Date;
    toLT?: Date;
    asset?: Asset;
    readonly records?: AccountingReportLine[];
}

const totalColor = '#1a1921';
const headerColor = '#333238';
const cellColor = '#202024';
const bgColor = '#0a0911';

const AccountingTable: React.FC<AccountingTableProps> = ({ currency, dimensions, types, fromGTE, toLT, records, asset }) => {
    const translate = useTranslate();
    const digits = asset?.displayDecimals || 2;
    const symbol = asset?.symbol;

    const numberStyle = {
        fontWeight: "normal",
        textAlign: 'right',
    };

    const headerStyle = {
        bgColor(arg: any) {
            if (arg.dataValue === 'Totals') {
                return totalColor;
            }
            return headerColor;
        }
    }

    const allColumns = {
        [AccountingAggregation.ByTransactionType]: {
            dimensionKey: 'txType',
            title: translate('accounting.fields.txType'),
            headerStyle: numberStyle,
            headerFormat: (title: number | string, col: number, row: number) => {
                if (title !== 'Totals' && title)
                    return translate(`enums.TransactionType.${title}`);
                else
                    return title ? title : 'N/A';
            },
        },
    }

    const allRows = {
        [AccountingAggregation.ByAssetClass]: {
            dimensionKey: 'assetClassName',
            title: translate('accounting.fields.assetClassName'),
            headerStyle: headerStyle,
            headerFormat: (title: number | string, col: number, row: number) => {
                if (title !== 'Totals' && title)
                    return title as string;
                else
                    return title ? title : 'N/A';
            },
        },
        [AccountingAggregation.ByPair]: {
            dimensionKey: 'pairId',
            title: translate('accounting.fields.pairId'),
            headerStyle: headerStyle,
            headerFormat: (title: number | string, col: number, row: number) => {
                return title ? title : 'N/A';
            },
        },
        [AccountingAggregation.ByAccountGroup]: {
            dimensionKey: 'accountGroupName',
            title: translate('accounting.fields.accountGroupName'),
            headerStyle: headerStyle,
            headerFormat: (title: number | string, col: number, row: number) => {
                return title ? title : 'N/A';
            },
        },
        [AccountingAggregation.ByAccount]: {
            dimensionKey: 'accountName',
            title: translate('accounting.fields.account'),
            headerStyle: headerStyle,
        },
    }

    // const displayColumns = dimensions?.map((d: AccountingAggregation) => allColumns[d]);

    const sortedDimensions = dimensions?.sort((a, b) => {
        const aIndex = Object.keys(allRows).indexOf(a);
        const bIndex = Object.keys(allRows).indexOf(b);
        return aIndex - bIndex;
    });

    const rows = sortedDimensions?.map((d: AccountingAggregation) => allRows[d as keyof typeof allRows]).filter((d: any) => d) || [];
    const cols = dimensions?.map((d: AccountingAggregation) => allColumns[d as keyof typeof allColumns]).filter((d: any) => d) || [];

    const option = {
        hideIndicatorName: cols.length > 0,
        records: records || [],
        rows: rows,
        columns: cols,
        indicators: [
            {
                title: translate('accounting.fields.total'),
                indicatorKey: "totalAccountChange",
                width: "auto",
                showSort: true,
                headerStyle: numberStyle,
                format: (value: any) => {
                    if (value)
                        return formatNumberFix(value, digits) + ' ' + symbol;
                    return '';
                },
                style: {
                    textAlign: 'right',
                    color(row: any) {
                        if (row.dataValue < 0)
                            return 'red'
                        else
                            return 'white'
                    },
                    bgColor(arg: any) {
                        const cellHeaderPaths = arg.table.getCellHeaderPaths(
                            arg.col,
                            arg.row,
                        );
                        if (cellHeaderPaths.colHeaderPaths[0]?.value === 'Totals') {
                            return totalColor;
                        }
                        if (cellHeaderPaths.rowHeaderPaths[0]?.value === 'Totals') {
                            return totalColor;
                        }

                        return cellColor;
                    }
                }
            },
        ],
        corner: {
            titleOnDimension: "row",
            headerStyle: {
                "textStick": true,
                bgColor: headerColor,
            }
        },
        dataConfig: {
            totals: {
                row: {
                    showGrandTotals: true,
                    showSubTotals: true,
                    // subTotalsDimensions: rows.map((r: any) => r.dimensionKey),
                    grandTotalLabel: 'Totals',
                    subTotalLabel: 'Subtotal',
                    showGrandTotalsOnTop: false //totals show on top
                },
                column: {
                    showGrandTotals: true,
                    showSubTotals: true,
                    // subTotalsDimensions: cols.map((c: any) => c.dimensionKey),
                    grandTotalLabel: 'Totals',
                    subTotalLabel: 'Subtotal'
                }
            }
        },
        widthMode: 'autoWidth',
        heightMode: 'autoHeight',

        // autoHeight: true,
        enableDataAnalysis: true,
        theme: {
            defaultStyle: {
                bgColor: cellColor,
                color: 'white',
                fontSize: 14,
            },
            headerStyle: {
                bgColor(arg: any) {
                    if (arg.dataValue === 'Totals') {
                        return totalColor;
                    }
                    return headerColor;
                }
            },
            underlayBackgroundColor: bgColor,
        },
    };
    return (
        <>
            {records?.length && <PivotTable option={option} height={600} />}
        </>
    );
};

export default AccountingTable;