import { TransactionType } from "./";

export enum AccountingAggregation {
    ByAssetClass = "ByAssetClass",
    ByPair = "ByPair",
    ByAccountGroup = "ByAccountGroup",
    ByAccount = "ByAccount",
    ByTransactionType = "ByTransactionType"
}

export class AccountingReportLine {
    txType?: TransactionType;
    pairId?: string;
    assetClassName?: string;
    accountId?: number;
    accountName?: string;
    accountGroupName?: string;
    totalAccountChange: number = 0;
    denominatedIn: string = '';
}
