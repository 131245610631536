import React from 'react';
import {
    useTable
} from "@refinedev/antd";

import { Account } from '../../../interfaces/account';
import { Position } from '../../../interfaces';
import { ClosedPositionsTable } from '../../position/closedPositionsTable';
import { defaultPageSize } from '../../../constants';
import { useOutletContext } from 'react-router-dom';

export const AccountPositions: React.FC = () => {
    const { account } = useOutletContext<{ account: Account }>();
    const { tableProps, sorters, filters } = useTable<Position>({
        resource: 'position',
        initialPageSize: defaultPageSize,
        syncWithLocation: false,
        meta: {
            query: { accountId: account.id },
        },
        sorters: {
            initial: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
        }
    });

    return (
        <ClosedPositionsTable tableProps={tableProps} withIdentity={false} sorters={sorters} filters={filters} />
    );
};