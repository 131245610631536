import {
    useTranslate,
} from "@refinedev/core";
import { Table, Space, Input, Button, Form } from "antd";


export const SearchWidget: React.FC<{ searchCallback: CallableFunction, search: string }> = ({ searchCallback, search }) => {
    const translate = useTranslate();
    return <>
        <Input.Search defaultValue={search} placeholder={translate("search")} allowClear onSearch={(value) => searchCallback(value)} />
    </>
}
