import React from 'react';

import { useTable } from '@refinedev/antd';
import { useOutletContext } from 'react-router-dom';
import { LoginEvent, UserData } from '../../../interfaces';
import { LogTable } from '../../log';

export const UserLog: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>();

    const { tableProps, sorters } = useTable<LoginEvent>({
        resource: 'log',
        syncWithLocation: false,
        sorters: {
            initial: [
                {
                    field: "timestamp",
                    order: "desc",
                },
            ],
        },
        meta: {
            query: { userId: user.id },
        }
    });

    return (
        <LogTable tableProps={tableProps} sorters={sorters} showUser={false} />
    );
};
