import React, { ReactChild } from "react";
import { Typography } from "antd";
import type { NumberFieldProps } from "@refinedev/antd";
import { RefineFieldCommonProps } from "@refinedev/ui-types";
import { formatNumberNegativeRound, formatNumberTrim } from "../../utils";

const { Text } = Typography;

function toLocaleStringSupportsOptions() {
  return !!(
    typeof Intl == "object" &&
    Intl &&
    typeof Intl.NumberFormat == "function"
  );
}


export type NumberFieldPropsExt = Omit<NumberFieldProps, "value"> & {
  digits?: number;
  value?: number | string | null | undefined;
  padRight?: number;
}


/**
 * This field is used to display a number formatted according to the browser locale, right aligned. and uses {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl `Intl`} to display date format.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/fields/number} for more details.
 */
export const NumberField: React.FC<NumberFieldPropsExt> = ({
  value,
  locale,
  options,
  digits,
  padRight,
  ...rest
}) => {
  if (value === null || value === undefined || value === "") {
    return <></>
  }

  let number = Number(value) as any;
  rest.className = rest.className ? rest.className + " text-number" : "text-number";
  if (digits !== undefined) {
    if (digits < 0) {
      number = Math.round(number / Math.pow(10, -digits)) * Math.pow(10, -digits);
    } else {
      options = { maximumFractionDigits: digits, minimumFractionDigits: digits };
    }
  }

  if (!locale) {
    locale = navigator.language;
  }

  const formatted = toLocaleStringSupportsOptions() ? number.toLocaleString(locale, options) : number;
  
  let add = '';

  if (padRight) {
    let point = formatted.indexOf(",");
    if (point === -1) {
      point = formatted.indexOf(".");
    }
    if (point === -1) {
      add = "." + "$".repeat(padRight);
    } else {
      add = "$".repeat(Math.max(padRight - (formatted.length - point) + 1, 0));
    }
  }
  
  return (
    <span {...rest}>
      <span>{formatted}</span><span className="hidden">{add}</span>
    </span>
  );
};
