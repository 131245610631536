import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Checkbox } from "antd";

export const AccountCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { selectProps: accountGroupSelectProps } = useSelect({
    resource: "accountGroup",
    optionLabel: "name",
  });

  const { selectProps: userSelectProps } = useSelect({
    resource: "user",
    optionLabel: "email",
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={translate("account.fields.owner")}
          name={"ownerId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelectProps} />
        </Form.Item>
        <Form.Item
          label={translate("account.fields.accountGroup")}
          name={"accountGroupId"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...accountGroupSelectProps} />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name={["defaultAccount"]}
        >
          <Checkbox>{translate("account.fields.defaultAccount")}</Checkbox>
        </Form.Item>
        <Form.Item
          label={translate("account.fields.currentBalance")}
          name={["currentBalance"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("account.fields.reservedAmount")}
          name={["reservedAmount"]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name={["enabled"]}
        >
          <Checkbox>{translate("account.fields.enabled")}</Checkbox>
        </Form.Item>
      </Form>
    </Create>
  );
};
