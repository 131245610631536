import { Pair } from "./pair";

export interface Exposure {
    totalNotional: number;
    totalValue: number;
    openPositionCount: number;
    cumulativeUnrealizedPnl: number;
}

export enum ExposureType
{
    TotalNotional = 'totalNotional',
    TotalValue = 'totalValue',
    OpenPositionCount = 'openPositionCount'
}


export interface CategoryExposure {
    categoryId: string;
    limitLong?: number;
    limitNetted?: number;
    limitShort?: number;
    hedgeValue: number;
    hedgePosition: number;
    long: Exposure;
    short: Exposure;
    netted: Exposure;
}

export enum CategoryExposureTypes {
    Long = "long",
    Short = "short",
    Netted = "netted"
} ;

export enum AggregationType
{
    Pair = 'pair',
    Asset = 'asset',
    AssetClass = 'assetClass'
}

export interface ExposureReport {
    denominatedInAssetId: string;
    displayDecimals: number;
    exposureDecimals: number;
    aggregation: AggregationType;
    categories: CategoryExposure[];
    preparedOn: string;
}

export interface ExposureAccountingCurrency { 
    accountingCurrency: string;
    displayDecimals: number;
    exposureDecimals: number;
}

export enum ExposureLimitBreachActionEnum
{
    None = 0,
    Notify = 1,
    ReduceLeverageNewPositionsLong = 2,
    ReduceLeverageNewPositionsShort = 4,
    IncreaseMarginExistingPositionsLong = 8,
    IncreaseMarginExistingPositionsShort = 16,
    SuspendOpeningNewPostionsLong = 32,
    SuspendOpeningNewPostionsShort = 64,
    ShiftOutsideLong = 256,
    ShiftOutsideShort = 512,
    ClosePositionsLong = 1024, //not supported
    ClosePositionsShort = 2048, //not supported
}

interface IExposureLimit {
    id: string;

    expLimLongT1: number;
    expLimShortT1: number;
    expLimNettedAbsT1: number;

    suspendNewLong: boolean;
    suspendNewShort: boolean;

    marginMultiplierOpenLong: number;
    marginMultiplierOpenShoprt: number;
    marginMultiplierMaintainLong: number;
    marginMultiplierMaintainShort: number;
}

class LimitReport<T> {
    byAssetClass: T[] = [];
    byAsset: T[] = [];
    byPair: T[] = [];
}

export interface Breach {
    actionFlags: number;
    underlying: IExposureLimit;
}

export type BreachLimitReport = LimitReport<Breach>;


export interface CurrentExposure {
    long: number;
    short: number;
    netted: number;
    underlying: IExposureLimit;


    longUtilization: number;
    shortUtilization: number;
    nettedUtilization: number;
}