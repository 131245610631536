import {
  useTable
} from "@refinedev/antd";
import {
  IResourceComponentsProps
} from "@refinedev/core";
import React from "react";
import { List } from "../../components/crud/list";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { AlertsTable } from "./table";

export const ClosedAlertList: React.FC<IResourceComponentsProps> = () => {
  const { search, listProps } = useSearchList();

  const query: Record<string, string> = {};
  if (search) {
    query.tokenLIKE = search; // TODO: search
  }
  const { tableProps } = useTable({
    initialPageSize: defaultPageSize,
    resource: 'alert',
    meta: { query }
  });

  return (
    <List {...listProps}>
      <AlertsTable {...tableProps} withIdentity={true} />
    </List>
  );
};
