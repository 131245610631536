import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";
import { Card, Col, Form, Input, Row, Select, InputNumber } from "antd";
import { useExposureFormItems } from "../../components/exposureLimits";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";

export const PairEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    const pairData = queryResult?.data?.data;

    const { selectProps: assetClassSelectProps } = useSelect({
        resource: "assetClass",
        defaultValue: pairData?.assetClassId,
        optionLabel: "name",
    });

    const exposureItems = useExposureFormItems();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                {...defaultFormLayout}
            >
                <Row>
                    <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                        <Card title={translate("pair.fields.basic")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("pair.fields.displayName")}
                                name={["displayName"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.altName")}
                                name={["altName"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.assetClass")}
                                name={"assetClassId"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select {...assetClassSelectProps} />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.costMin")}
                                name={["costMin"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.tickSize")}
                                name={["tickSize"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.exchangeStatus")}
                                name={["exchangeStatus"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.availability")}
                                name={["availability"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                        <Card title={translate("pair.fields.decimals")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("pair.fields.costDecimals")}
                                name={["costDecimals"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.pairDecimals")}
                                name={["pairDecimals"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.lotDecimals")}
                                name={["lotDecimals"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Card>
                        <Card title={translate("pair.fields.lot")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("pair.fields.lotSize")}
                                name={["lotSize"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.lotUnitName")}
                                name={["lotUnitName"]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                        <Card title={translate("pair.fields.hedge")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("asset.fields.hedgePosition")}
                                name={["hedgePosition"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                        <Card title={translate("pair.fields.order")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("pair.fields.orderMin")}
                                name={["orderMin"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label={translate("pair.fields.orderMax")}
                                name={["orderMax"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Card>
                        {exposureItems}
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
