import { EnumToObject } from "../utils";
import { LyraSequential } from "./base";

export enum MarginPolicyCloseoutOptions {
  Unknown = 'Unknown',
  Full = 'Full',
  Partial = 'Partial'
}

export const MarginPolicyCloseoutOptionsNames = EnumToObject(MarginPolicyCloseoutOptions);

export enum MarginPolicyCloseoutStrategy {
  Unknown = 'Unknown',
  MostMarginUsage = 'MostMarginUsage',
  LargestUnrealizedLosses = 'LargestUnrealizedLosses'
}

export const MarginPolicyCloseoutStrategyNames = EnumToObject(MarginPolicyCloseoutStrategy);


export enum MarginPolicyWorkflowType {
  Unknown = 'Unknown',
  Manual = 'Manual',
  Automatic = 'Automatic'
}
export const MarginPolicyWorkflowTypeNames = EnumToObject(MarginPolicyWorkflowType);

export interface MarginCallPolicy extends LyraSequential {
  description: string;
  levelWarning?: number | null;
  levelCritical?: number | null;
  levelMC: number;
  autoCloseoutDelay: number;
  closeoutOptions: MarginPolicyCloseoutOptions;
  closeoutStrategy: MarginPolicyCloseoutStrategy;
  workflowType: MarginPolicyWorkflowType;
}
