import React, { useState } from "react";

import { BaseRecord } from "@refinedev/core";
import { useSearchParams } from "react-router-dom";
import { SearchWidget } from "../components/common/SearchWidget";

export type TableNavigateFunction = (record: BaseRecord, rowIndex?: number) => string;

export const useSearchList = (

): { search: string | null, listProps: any } => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [search, setSearch] = useState(searchParams.get('search'));

    const handleSearch = (value: string) => {
        setSearch(value);
        setSearchParams((params) => {
            params.set('search', value);
            return params;
        });
    }

    const searchWidget = (props: any) => (
        <SearchWidget searchCallback={handleSearch} search={search || ""} />
    )

    return { search, listProps: { searchWidget } }
}