import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Show } from "@refinedev/antd";
import { OrderShowModal } from "./showModal";
import { Order } from "../../interfaces";
import { useTitleData } from "../../contexts/title";

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Order>();
    const { data, isLoading } = queryResult;
    const translate = useTranslate();

    const record = data?.data;
    useTitleData({ ...record});

    return (
        <Show isLoading={isLoading} title={translate(`order.titles.show`, { ...record })} >
            {record && <OrderShowModal order={record} />}
        </Show>
    );
};
