import { Account } from "./account";

export interface LyraNamed {
    id: string;
}

export interface LyraSequential {
    id: number;
    token: string;
}


export interface UserIdentity {
    fullName: string;
    email: string;
    denominatedIn: string;
}

export interface LyraUacObject extends LyraSequential{
    accountId: number;
    account?: Account;
    identity?: UserIdentity;
    createdByUserId: number;
    createdAt: string;
    lastUpdatedAt: string;
}

export enum ExecutionMethod
{
    Unknown = 'Unknown',
    Internalized = 'Internalized',
    Routed = 'Routed'
}