import {
    useTable
} from "@refinedev/antd";
import React from 'react';
import { useOutletContext } from "react-router-dom";

import { Account, UserData } from '../../../interfaces';
import { AccountsTable } from '../../accounts/table';


export const UserAccounts: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>();
    const { tableProps } = useTable<Account>({
        resource: 'account',
        syncWithLocation: false,
        meta: { query: { ownerId: user.id, _include: 'accountGroup' } }
    });

    return (
        <AccountsTable {...tableProps} showOwner={false} />
    );
};