import { LyraSequential } from "./base";

export enum UserCategory
{
    Unknown = 'Unknown',
    Staff = 'Staff',
    Traders = 'Traders'
}

export interface UserGroup extends LyraSequential {
    name: string;
    description: string;
    isDefaultGroup: boolean;
    category: UserCategory;
}
