import { Show } from '@refinedev/antd';
import { useOne, useShow, useTranslate } from '@refinedev/core';
import { Space } from 'antd';
import React from 'react';
import { LogoAssetClassField, LogoAssetField, LogoPairField } from '../../components/fields';
import { Breach, BreachLimitReport, ExposureAccountingCurrency } from '../../interfaces';
import BreachesTable from './breachesTable';

const BreachesPage: React.FC = () => {
    // Your component logic goes here
    const translate = useTranslate();
    const { queryResult } = useShow<BreachLimitReport>(
        {
            resource: 'breaches',
            id: 'unknown/breaches',
        }
    );
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { data: crminfo } = useOne<ExposureAccountingCurrency>({ resource: "crminfo", id: '', });
    const digits = crminfo?.data?.exposureDecimals || 0;

    return (
        <Show
            resource='breaches'
            recordItemId={'unknown/breaches'}
            isLoading={isLoading}
            title={translate('exposure.breaches.title')}
        >
            <Space direction='vertical' size='large'>
                <BreachesTable
                    displayDecimals={digits}
                    breaches={record?.byAssetClass ?? []}
                    title={translate('exposure.breaches.tabs.ByAssetClass')}
                    idRender={(value: string) => <LogoAssetClassField token={value as unknown as number} />}
                    editResource='assetClass'
                    editId={(record: Breach) => record.underlying.id}
                />
                <BreachesTable
                    displayDecimals={digits}
                    breaches={record?.byAsset ?? []}
                    title={translate('exposure.breaches.tabs.ByAsset')}
                    editResource='asset'
                    idRender={(value: string) => <LogoAssetField assetId={value} name={value} />}
                />
                <BreachesTable
                    displayDecimals={digits}
                    breaches={record?.byPair ?? []}
                    title={translate('exposure.breaches.tabs.ByPair')}
                    editResource='pair'
                    idRender={(value: string) => <LogoPairField pairId={value} />}
                />
            </Space>
        </Show>
    );
};

export default BreachesPage;