import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { TransactionCreateForm } from "./createForm";

export const TransactionCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <TransactionCreateForm formProps={formProps}></TransactionCreateForm>
        </Create>
    );
};
