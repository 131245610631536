import { FilterFilled } from "@ant-design/icons";
import {
  DeleteButton,
  EditButton, getDefaultSortOrder, ShowButton, useTable
} from "@refinedev/antd";
import {
  CrudFilter, CrudSort, getDefaultFilter, IResourceComponentsProps, useOne,
  useTranslate
} from "@refinedev/core";
import { CrudFilters } from "@refinedev/core/dist/contexts/data/types";
import { Button, Input, Space, Table, Tooltip } from "antd";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import type { FilterDropdownProps } from "antd/lib/table/interface";
import React from "react";
import { List } from "../../components/crud/list";
import { getExposureColumnsItems } from "../../components/exposureLimits";
import { LogoAssetClassField, LogoPairField, NumberField } from "../../components/fields";
import { EnumField } from "../../components/fields/EnumField";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { AssetClassesEnum, ExposureAccountingCurrency } from "../../interfaces";
import { ExchangeStatusEnum, Pair, PairSubTypeEnum, TradeAvailability } from "../../interfaces/pair";
import { getEnumFilter, LogComponent } from "../../utils";

type ExtendedColumnType = (ColumnGroupType<Pair> | ColumnType<Pair>) & { hidden?: boolean };

interface FilterClearProps {
  closeDropdown: boolean;
  confirm: boolean;
}

const getColumnSearchProps = () => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
    close,
  }: {
    setSelectedKeys: (keys: string[], confirm?: FilterDropdownProps["confirm"]) => void;
    selectedKeys: string[];
    confirm: FilterDropdownProps["confirm"];
    clearFilters: (param?: FilterClearProps) => void;
    filters: CrudFilters;
    close: () => void;
  }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <LogComponent>{filters}</LogComponent>
      <Input
        value={selectedKeys[0]}
        placeholder="From"
        onChange={(e) => setSelectedKeys([e.target.value, selectedKeys[1]])}
        onPressEnter={() => setSelectedKeys(selectedKeys as string[], confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Input
        value={selectedKeys[1]}
        placeholder="To"
        onChange={(e) => setSelectedKeys([selectedKeys[0], e.target.value])}
        onPressEnter={() => setSelectedKeys(selectedKeys as string[], confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          onClick={() => {
            clearFilters && clearFilters({ closeDropdown: true, confirm: true });
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <FilterFilled style={{ color: filtered ? 'var(--primary)' : undefined }} />
  )
});


export const getPairsColumns = (translate: CallableFunction, full: boolean, sorters: CrudSort[] = [], filters: CrudFilter[] = []) => {
  const assetClassFilter = getEnumFilter(AssetClassesEnum, 'AssetClassesEnum', translate, [AssetClassesEnum.Unknown]);
  const subTypeFilter = getEnumFilter(PairSubTypeEnum, 'PairSubTypeEnum', translate, ["Unknown"]);
  const exchangeStatusFilter = getEnumFilter(ExchangeStatusEnum, 'ExchangeStatusEnum', translate);
  const availabilityFilter = getEnumFilter(TradeAvailability, 'TradeAvailability', translate, ["None"]);
  const limitColumns = getExposureColumnsItems(translate, true);
  const { data: accountingCurrency } = useOne<ExposureAccountingCurrency>({
    resource: "crminfo",
    id: '',
  });

  const columns = [
    {
      dataIndex: "id",
      rowScope: "rowgroup",
      width: 150,
      sorter: true,
      defaultSortOrder: getDefaultSortOrder("id", sorters),
      title: translate("pair.fields.id"),
      render: (value) => <LogoPairField pairId={value} />,
    },
    {
      dataIndex: "displayName",
      title: translate("pair.fields.displayName"),
      filterSearch: true,
      className: "ellipsis",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => <Tooltip title={value}>{value}</Tooltip>
    },
    // {
    //   dataIndex: "altName",
    //   title: translate("pair.fields.altName"),
    // },
    {
      dataIndex: "assetClassIdBITWISEANDANY",
      title: translate("pair.fields.assetClass"),
      filters: assetClassFilter,
      sorter: true,
      render: (value, record: Pair) => <LogoAssetClassField token={record.assetClassId} />,
    },
    {
      dataIndex: "subtype",
      title: translate("pair.fields.subtype"),
      filters: subTypeFilter,
      className: "ellipsis",
      ellipsis: true,
      hidden: !full,
      render: (value) => <span>{value}</span>,
    },
    // {
    //   title: translate("pair.fields.decimals"),
    //   hidden: !full,
    //   children: [
    //     {
    //       dataIndex: "costDecimals",
    //       align: "right",
    //       title: translate("pair.fields.costDecimals"),
    //       render: (value) => <NumberField value={value} />,
    //     },
    //     {
    //       align: "right",
    //       dataIndex: "pairDecimals",
    //       title: translate("pair.fields.pairDecimals"),
    //       render: (value) => <NumberField value={value} />,
    //     },
    //     {
    //       align: "right",
    //       dataIndex: "lotDecimals",
    //       title: translate("pair.fields.lotDecimals"),
    //       render: (value) => <NumberField value={value} />,
    //     },
    //   ],
    // },
    {
      title: translate("pair.fields.lot"),
      hidden: !full,
      children: [
        {
          align: "right",
          dataIndex: "lotSize",
          sorter: true,
          title: translate("pair.fields.lotSize"),
          render: (value: number) => <NumberField value={value} />,
          defaultFilteredValue: getDefaultFilter("lotSize", filters, "between"),
          ...getColumnSearchProps()
        },
        {
          dataIndex: "lotUnitName",
          title: translate("pair.fields.lotUnitName"),
          width: 50,
        },
      ],
    },
    {
      title: translate("pair.fields.order"),
      hidden: !full,
      children: [
        {
          align: "right",
          dataIndex: "orderMin",
          sorter: true,
          title: translate("pair.fields.orderMin"),
          render: (value) => <NumberField value={value} />,
          defaultFilteredValue: getDefaultFilter("orderMin", filters, "between"),
          ...getColumnSearchProps()
        },
        {
          align: "right",
          dataIndex: "orderMax",
          sorter: true,
          title: translate("pair.fields.orderMax"),
          render: (value) => <NumberField value={value} />,
          defaultFilteredValue: getDefaultFilter("orderMax", filters, "between"),
          ...getColumnSearchProps()
        },
      ],
    },
    {
      title: translate("limits.limits", { currency: accountingCurrency?.data?.accountingCurrency }),
      hidden: !full,
      children: limitColumns,
    },
    {
      align: "right",
      dataIndex: "costMin",
      title: translate("pair.fields.costMin"),
      render: (value) => <NumberField value={value} />,
      sorter: true,
      defaultFilteredValue: getDefaultFilter("costMin", filters, "between"),
      ...getColumnSearchProps()
    },
    {
      align: "right",
      dataIndex: "tickSize",
      title: translate("pair.fields.tickSize"),
      sorter: true,
      render: (value) => <NumberField value={value} />,
      defaultFilteredValue: getDefaultFilter("tickSize", filters, "between"),
      ...getColumnSearchProps()
    },
    {
      align: "right",
      dataIndex: "hedgePosition",
      title: translate("pair.fields.hedgePosition"),
      sorter: true,
      render: (value) => <NumberField value={value} />,
      defaultFilteredValue: getDefaultFilter("hedgePosition", filters, "between"),
      ...getColumnSearchProps()
    },
    {
      dataIndex: "exchangeStatus",
      title: translate("pair.fields.exchangeStatus"),
      filters: exchangeStatusFilter,
      sorter: true,
      render: (value) => <EnumField enum='ExchangeStatusEnum' translate={translate} value={value} />,
    },
    {
      dataIndex: "availability",
      title: translate("pair.fields.availability"),
      filters: availabilityFilter,
      sorter: true,
      render: (value) => <EnumField enum='TradeAvailability' translate={translate} value={value} />,
    },
    {
      align: "right",
      width: 100,
      dataIndex: "actions",
      render: (_, record) => (
        <Space>
          <EditButton hideText size="small" recordItemId={record.id} />
          <ShowButton hideText size="small" recordItemId={record.id} />
          <DeleteButton hideText size="small" recordItemId={record.id} />
        </Space>
      ),
    },
  ] as ExtendedColumnType[];

  return columns.filter(col => !col.hidden);
}

export const PairList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { search, listProps } = useSearchList();

  const query: Record<string, string> = {};
  if (search) {
    query.idLIKE = search;
    query.displayNameLIKE = search;
  }
  const { tableProps, sorters, filters } = useTable<Pair>({
    syncWithLocation: true,
    initialPageSize: defaultPageSize,
    meta: { query },
    sorters: {
      initial: [
        {
          field: "id",
          order: "asc",
        },
      ],
    },
    filters: {
      initial: [
        { field: "lotSize", operator: "between", value: undefined, },
        { field: "orderMin", operator: "between", value: undefined, },
        { field: "orderMax", operator: "between", value: undefined, },
        { field: "costMin", operator: "between", value: undefined, },
        { field: "tickSize", operator: "between", value: undefined, },
        { field: "hedgePosition", operator: "between", value: undefined, },
      ],
    }
  });
  const columns = getPairsColumns(translate, true, sorters, filters);

  const clickTableProps = useTableClick({ resource: 'pair', action: 'show' });

  return (
    <List {...listProps}>
      <Table {...tableProps} rowKey="id" columns={columns} {...clickTableProps}>
      </Table>
    </List>
  );
};
