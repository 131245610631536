import { Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


export const useWindow = (): [JSX.Element, number] => {
    const { t } = useTranslation();
    const windowOptions = [6, 12, 18, 24, 36].map(item => ({ value: item, label: item.toString() }));

    const [window, setWindow] = useState<number>(12);

    const component = <>
        <label style={{ marginBottom: '5px'}} htmlFor="window">{t('var.fields.window')}</label>
        <Select
            className='number-select'
            popupClassName='number-select'
            style={{ width: '100%' }}
            options={windowOptions}
            value={window}
            onChange={setWindow}
        />
    </>

    return [component, window];
};