import React from "react";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Checkbox, FormProps, Row, Col, Card } from "antd";
import { defaultFormLayout } from "../../constants";


export const ExchangeForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Row>
                <Col xs={{ span: 8 }} lg={{ span: 8 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("exchange.fields.name")}
                            name={["name"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.description")}
                            name={["description"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.legalEntity")}
                            name={["legalEntity"]}
                        >
                            <Input />
                        </Form.Item>
                    </Card>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("exchange.fields.micCode")}
                            name={["micCode"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.opMic")}
                            name={["opMic"]}

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.lei")}
                            name={["lei"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.mcc")}
                            name={["mcc"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.acro")}
                            name={["acro"]}
                        >
                            <Input />
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("exchange.fields.countryName")}
                            name={["countryName"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.isoCountry")}
                            name={["isoCountry"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.city")}
                            name={["city"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.site")}
                            name={["site"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.comments")}
                            name={["comments"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.timeZone")}
                            name={["timeZone"]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.timeOffset")}
                            name={["timeOffset"]}
                        >
                            <Input />
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={{ span: 7, offset: 1 }} lg={{ span: 7, offset: 1 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("exchange.fields.stock")}
                            valuePropName="checked"
                            name={["stock"]}
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.etf")}
                            valuePropName="checked"
                            name={["etf"]}
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.index")}
                            valuePropName="checked"
                            name={["index"]}
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.fx")}
                            valuePropName="checked"
                            name={["fx"]}
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={translate("exchange.fields.crypto")}
                            valuePropName="checked"
                            name={["crypto"]}
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};
