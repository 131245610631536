import React from "react";
import { useTranslate } from "@refinedev/core";
import { Form, Input, FormProps, Select } from "antd";
import { getEnumOptions } from "../../utils";
import { PairCategoryType } from "../../interfaces";
import { defaultFormLayout } from "../../constants";

export const PairCategoryForm: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    const translate = useTranslate();

    const pairCategoryTypes = getEnumOptions(PairCategoryType, 'PairCategoryType', translate, [PairCategoryType.Unknown]);

    return (
        <Form
            {...formProps}
            {...defaultFormLayout}
        >
            <Form.Item
                label={translate("pairCategory.fields.name")}
                name={["name"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("pairCategory.fields.description")}
                name={["description"]}
                rules={[

                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={translate("pairCategory.fields.type")}
                name={["type"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select options={pairCategoryTypes} />
            </Form.Item>
            <Form.Item
                label={translate("pairCategory.fields.weight")}
                name={["weight"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
};
