export enum PairCategoryType
{
    Unknown = 'Unknown',
    Country = 'Country',
    Exchange = 'Exchange',
    Group = 'Group',
    AssetClass = 'AssetClass'
}

export interface PairCategory {
    id: number;
    name: string;
    description: string;
    parentId: number;
    weight: number;
    type: PairCategoryType;
    token?: string;
}
