import { useForm } from "@refinedev/antd";
import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { Edit } from "../../components/crud/edit";
import { AssetClassForm } from "./form";

export const AssetClassEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <AssetClassForm formProps={formProps}></AssetClassForm>
        </Edit>
    );
};
