import { useMemo } from "react"
import {
    useList,
} from "@refinedev/core";
import { ColumnFilterItem } from "antd/lib/table/interface";

export const useArrayFilter = (props: any = {}): ColumnFilterItem[] => {

    const { data } = useList({
        ...props, pagination: {
            mode: 'off'
        }
    });

    const filters = useMemo(() => {
        return data?.data?.map((item: unknown) => { return { text: item, value: item } as ColumnFilterItem })
    }, [data]) || [];

    return filters;
}