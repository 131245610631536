import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { ExchangeForm } from "./form";
import { Edit } from "../../components/crud/edit";

export const ExchangeEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    const exchangeData = queryResult?.data?.data;

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <ExchangeForm formProps={formProps} />
        </Edit>
    );
};
