import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { DatePicker, Form, Input, Select } from "antd";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { defaultFormLayout } from "../../constants";
import { LendingForm } from "./form";


export const LendingCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();
    let { id } = useParams();

    formProps.initialValues = {
        'assetId': id,
        'effectiveFrom': dayjs().unix(),
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <LendingForm formProps={formProps} />
        </Create>
    );
};
