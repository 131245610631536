import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";
import { Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import { Asset, Lending, LendingType } from "../../interfaces";
import dayjs from "dayjs";
import { getEnumOptions } from "../../utils";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";
import { LendingForm } from "./form";


export const LendingEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { assetId, lendingId } = useParams();

    const { formProps, saveButtonProps, queryResult } = useForm<Lending>({
        resource: 'lending',
        id: lendingId,
    });
    const lendingData = queryResult?.data?.data;
    const enabled = lendingData && dayjs().isBefore(dayjs.unix(lendingData?.effectiveFrom));
    saveButtonProps.disabled = !enabled;
    const deleteButtonProps = { disabled: !enabled };

    return (
        <Edit saveButtonProps={saveButtonProps} deleteButtonProps={deleteButtonProps} >
            <LendingForm formProps={formProps} />
        </Edit>
    );
};
