import React from "react";
import { Space, Typography } from "antd";
import { EmailFieldProps } from "@refinedev/antd";
import {
    useTranslate,
} from "@refinedev/core";
import { BooleanField } from "./BooleanField";

const { Link } = Typography;

/**
 * This field is used to display email values. It uses the {@link https://ant.design/components/typography/#FAQ `<Link>`} component
 * of {@link https://ant.design/components/typography `<Typography>`} from Ant Design.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/fields/email} for more details.
 */
export const EmailField: React.FC<EmailFieldProps & { confirmed?: boolean }> = ({ value, confirmed, ...rest }) => {
    const translate = useTranslate();
    if (!value) {
        return <></>;
    }
    return (
        <Space>
            <Link href={`mailto:${value}`} {...rest}>
                {value}

            </Link>
            {confirmed !== undefined && <BooleanField value={confirmed} valueLabelTrue={translate("common.confirmed")} valueLabelFalse={translate("common.notConfirmed")} />}
        </Space>
    );
};
