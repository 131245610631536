import { Account } from "./account";
import { User } from "./user";

export enum LogSeverity
{
    Unknown = 'Unknown',
    Debug = 'Debug',
    Trace = 'Trace',
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Critical = 'Critical'
}

export enum LogCategory
{
    Unknown = 'Unknown',
    UserInfo = 'UserInfo',
    Audit = 'Audit',
    HubLog = 'HubLog',
    Housekeeping = 'Housekeeping',
    LogMessage = 'LogMessage'
}

export interface LogRecord {
    id: number;
    message: string;
    series: string;
    severity: LogSeverity;
    timestamp: string;
    type: string;
    accountId: number;
    account?: Account;
    userId: number;
    user?: User;
    category: LogCategory;
    disposition: string;
}