import { CrudSorting } from "@refinedev/core";

export const generateSort = (sorters?: CrudSorting) => {
    if (sorters && sorters.length > 0) {
        const _sort: string[] = [];
        const _sortdesc: string[] = [];

        sorters.map((item) => {
            if (item.order === 'asc') {
                _sort.push(item.field);
            } if (item.order === 'desc') {
                _sortdesc.push(item.field);
            }
        });

        return {
            _sort,
            _sortdesc,
        };
    }

    return;
};
