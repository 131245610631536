import React from 'react';

import {
    HttpError, useTable
} from "@refinedev/core";
import { useOutletContext } from 'react-router-dom';
import { BankAccount, UserData } from '../../../interfaces';
import { BankAccountsTable } from '../../bankaccounts/table';

export const UserBankAccounts: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>();

    const { tableQueryResult } = useTable<BankAccount, HttpError>({
        resource: 'bankAccount',
        syncWithLocation: false,
        meta: { query: { ownerId: user.id } }
    });

    return (
        <BankAccountsTable user={user} tableQueryResult={tableQueryResult} />
    );
};
