import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type NumericOption = {
    value: number;
    label: string;
    disabled?: boolean;
    options?: NumericOption[];
};

export type ElementOption = {
    value: number;
    label: ReactElement;
    disabled: boolean;
};


export type StringOption = {
    value: string;
    label: string;
    disabled: boolean;
};

export type TOption<T> = {
    value: T;
    label: string;
    disabled: boolean;
};

export function useEnumNumericOptions(enumObject: Record<string, string | number>, name: string): NumericOption[] {

    const { t } = useTranslation();

    return useMemo(() => {
        const enumKeys = Object.keys(enumObject) as Array<keyof typeof enumObject>;

        const enumOptions = enumKeys.map((key) => {
            const value = enumObject[key];
            if (typeof value === 'number') {
                return {
                    disabled: false,
                    value,
                    label: t(`enums.${name}.${key}`),
                };
            }
            return null;
        }).filter(Boolean) as NumericOption[];

        return enumOptions;

    }, [enumObject, name, t]);
}

export function useEnumOptions<T = string>(enumObject: Record<string, string | number>, name: string): TOption<T>[] {

    const { t } = useTranslation();

    return useMemo(() => {
        const enumKeys = Object.keys(enumObject) as Array<keyof typeof enumObject>;

        const enumOptions = enumKeys.map((key) => {
            const value = enumObject[key];
            return {
                value: value as T,
                label: t(`enums.${name}.${key}`),
                disabled: false,
            };
        }).filter(Boolean) as TOption<T>[];

        return enumOptions;

    }, [enumObject, t, name]);
}
