import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { OverrideForm } from "./form";
import { Edit } from "../../components/crud/edit";

export const OverrideEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps} = useForm();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <OverrideForm formProps={formProps} />
        </Edit>
    );
};
