import { Button, Form, Input, message, Modal, Row, Space, Table, Upload } from 'antd';
import React, { useState } from 'react';

import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { EditButton, SaveButton, TextField, useModal } from '@refinedev/antd';
import {
    HttpError,
    useInvalidate, useTable,
    useTranslate,
    useUpdate
} from "@refinedev/core";
import type { RcFile } from 'antd/lib/upload/interface';
import { useOutletContext } from 'react-router-dom';
import { Document, UserData } from '../../../interfaces';
import { axiosInstance } from '../../../providers';


export const DocumentImage: React.FC<{ id: string, name: string }> = ({ id, name }) => {
    const translate = useTranslate();
    const imgSrc = "/Identity/api/docstorage/" + id;
    const { show, close, modalProps } = useModal({
        modalProps: {
            cancelText: <a href={imgSrc} download={name} >{translate('document.buttons.download')} <DownloadOutlined /></a>,
            onCancel: () => {
                return false;
            },
            onOk: () => close(),
        }
    });
    return <>
        <img src={imgSrc} className='document-thumbnail' onClick={() => show()} />
        <Modal {...modalProps} width={800}>
            <img src={imgSrc} className='document-modal' />
        </Modal>
    </>
}

type UploadFileList = Array<{ file: File, description: string }>;

const FileList: React.FC<{ fileList: UploadFileList, onFileRemove: CallableFunction, onDescriptionChange: CallableFunction }> = ({ fileList, onFileRemove, onDescriptionChange }) => {
    const translate = useTranslate();
    return (
        <div className="upload-list ant-upload-list-picture">
            {fileList.length !== 0 &&
                <div className="upload-list-item-header">
                    <span
                        className="upload-list-item-image"
                    >
                        {translate('document.fields.document')}
                    </span>
                    <span
                        className="upload-list-item-name"
                    >
                        {translate('document.fields.description')}
                    </span>
                </div>
            }
            {fileList.map(({ file, description }, index) => (
                <div className="upload-list-item-container" key={index}>
                    <span
                        className="upload-list-item-image"
                    >
                        <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                        />
                    </span>
                    <span
                        className="upload-list-item-name"
                        title={file.name}
                    >
                        <Input
                            type="text"
                            value={description}
                            onChange={(e) => onDescriptionChange(e, index)}
                        />
                    </span>

                    <span className="ant-upload-list-item-actions picture">
                        <Button
                            title="Remove file"
                            className="ant-btn css-dev-only-do-not-override-f8diei ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action"
                            onClick={() => onFileRemove(index)}
                        >
                            <DeleteOutlined />
                        </Button>
                    </span>

                </div>
            ))}
        </div>
    );
};


export const UserDocuemnts: React.FC = () => {
    const { user } = useOutletContext<{ user: UserData }>();

    const { tableQueryResult } = useTable<Document, HttpError>({
        resource: 'document',
        syncWithLocation: false,
        meta: { query: { ownerId: user.id } }
    });
    const invalidate = useInvalidate();

    const translate = useTranslate();
    const apiUrl = '/Identity/api/docstorage?userId=' + user.id;
    const [fileList, setFileList] = useState<UploadFileList>([]);
    const [uploading, setUploading] = useState(false);

    const { show, close, modalProps } = useModal({
        modalProps: {
            onOk: (event) => handleUpload(event),
            okText: <><UploadOutlined /> {translate('document.buttons.upload')}</>,
            okButtonProps: {
                loading: uploading,
                disabled: fileList.length === 0
            },
            onCancel: () => {
                setFileList([]);
                close();
            }
        }
    });

    const handleUploadShow = () => {
        show();
    }

    const handleUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('UploadFiles[]', file.file as RcFile);
            formData.append('Descriptions[]', file.description);
        });
        formData.append('userId', user.id + "");
        setUploading(true);
        try {
            const result = await axiosInstance.post('/Identity/api/docstorage', formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

            console.log(result);
            message.success('upload successfully.');
            setFileList([]);
            invalidate({
                resource: "document",
                invalidates: ["list"],
            });
            close();
        } catch (err) {
            message.error('upload failed.');
        }
        finally {
            setUploading(false);
        }
    };

    const onFileRemove = (index: number) => {
        const newList = [...fileList];
        newList.splice(index, 1);
        setFileList(newList);
    }

    const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const list = [...fileList];
        list[index].description = event.target.value;
        setFileList(list);
    }

    const [editId, setId] = React.useState<number | null>();
    const isEditing = (id: number) => id === editId;
    const [form] = Form.useForm();
    const { mutate: updateMutate } = useUpdate();


    const cancelButtonProps = () => {
        return {
            onClick: () => {
                setId(null);
            },
        }
    };

    const saveButtonProps = (record: Document) => {
        return {
            onClick: async () => {
                try {
                    const row = (await form.validateFields());

                    updateMutate({
                        successNotification: () => { setId(null); return { type: "success", "message": translate('document.messages.updated') } },
                        resource: 'document',
                        values: row,
                        id: record.id,
                    });

                } catch (errInfo) {
                    console.log('Validate Failed:', errInfo);
                }
            },
        };
    }

    // const deleteButtonProps = (record: Document) => {
    //     return {
    //         resource: 'document',
    //         recordItemId: record.id,
    //     };
    // };

    const editButtonProps = (record: Document) => {
        return {
            onClick: () => {
                form.setFieldsValue({
                    description: record.description,
                });
                setId(record.id);
            }
        };
    };

    return (
        <>
            <Row justify={"end"} className='mb-8'>
                <Button type="primary" onClick={handleUploadShow}><UploadOutlined /> {translate('document.buttons.upload')}</Button>
            </Row>
            <Form form={form} component={false}>
                <Table dataSource={tableQueryResult.data?.data} rowKey="id">
                    <Table.Column
                        dataIndex="token"
                        rowScope="rowgroup"
                        title={translate("document.fields.token")}
                    />
                    <Table.Column
                        dataIndex="originalName"
                        title={translate("document.fields.originalName")}
                    />
                    <Table.Column
                        dataIndex="description"
                        title={translate("document.fields.description")}
                        render={(value: string, record: Document) => {
                            if (isEditing(record.id)) {
                                return (
                                    <Form.Item name="description" style={{ margin: 0 }} >
                                        <Input />
                                    </Form.Item>
                                );
                            }
                            return <TextField value={value} />;
                        }}
                    />
                    <Table.Column
                        dataIndex="storageHandle"
                        title={translate("document.fields.document")}
                        render={(value: string, record: Document) => <DocumentImage id={value} name={record.originalName} />}
                    />
                    <Table.Column
                        align='end'
                        width={80}
                        dataIndex="actions"
                        render={(value: unknown, record: Document) => {
                            if (isEditing(record.id)) {
                                return (
                                    <Space>
                                        <SaveButton
                                            {...saveButtonProps(record)}
                                            size="small"
                                        />
                                        <Button
                                            {...cancelButtonProps()}
                                            size="small"
                                        >
                                            {translate("buttons.cancel")}
                                        </Button>
                                    </Space>
                                );
                            }
                            return (
                                <Space>
                                    <EditButton
                                        {...editButtonProps(record)}
                                        hideText
                                        size="small"
                                    />
                                </Space>
                            );
                        }}
                    />
                </Table>
            </Form>
            <Modal {...modalProps} width={800}>
                <>
                    <Upload.Dragger
                        name="UploadFiles"
                        action={apiUrl}
                        listType="picture"
                        showUploadList={false}
                        maxCount={5}
                        multiple

                        beforeUpload={(file: File) => {
                            const obj = { file: file, description: file.name };
                            fileList.push(obj);
                            setFileList([...fileList]);
                            return false;
                        }}
                    >
                        <p className="ant-upload-text">
                            {translate("document.fields.hint")}
                        </p>
                    </Upload.Dragger>
                    <FileList fileList={fileList} onFileRemove={onFileRemove} onDescriptionChange={onDescriptionChange} />
                </>
            </Modal>
        </>
    );
};
