import { Show } from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import React from "react";
import { useTitleData } from "../../contexts/title";
import { Position } from "../../interfaces";
import { PositionShowModal } from "./showModal";

export const PositionShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Position>();
    const { data, isLoading } = queryResult;
    const translate = useTranslate();

    const record = data?.data;
    useTitleData({ ...record });

    return (
        <Show isLoading={isLoading} title={translate(`position.titles.show`, { ...record })}>
            {record && <PositionShowModal position={record} />}
        </Show>
    );
};
