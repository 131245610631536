import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import {
    Show,
    TextField,
    NumberField,
} from "@refinedev/antd";
import { MarginCallPolicy, MarginPolicyCloseoutOptionsNames, MarginPolicyCloseoutStrategyNames, MarginPolicyWorkflowTypeNames } from "../../interfaces";
import { Row, Col } from "antd";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { EnumField } from "../../components/fields";

export const MarginCallPolicyShowInternal: React.FC<{ record: MarginCallPolicy }> = ({ record }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'description',
                    label: translate("marginCallPolicy.fields.description"),
                    children: <TextField value={record?.description ?? ""} />,
                },
                {
                    key: 'levelWarning',
                    label: translate("marginCallPolicy.fields.levelWarning"),
                    children: <NumberField value={record?.levelWarning ?? ""} />,
                },
                {
                    key: 'levelCritical',
                    label: translate("marginCallPolicy.fields.levelCritical"),
                    children: <NumberField value={record?.levelCritical ?? ""} />,
                },
                {
                    key: 'levelMC',
                    label: translate("marginCallPolicy.fields.levelMC"),
                    children: <NumberField value={record?.levelMC ?? ""} />,
                },
            ]
        }
    ];

    const groupTwo = [
        {
            items: [
                {
                    key: 'autoCloseoutDelay',
                    label: translate("marginCallPolicy.fields.autoCloseoutDelay"),
                    children: <NumberField value={record?.autoCloseoutDelay ?? ""} />,
                },
                {
                    key: 'closeoutOptions',
                    label: translate("marginCallPolicy.fields.closeoutOptions"),
                    children: <EnumField enum='MarginPolicyCloseoutOptions' translate={translate} value={record.closeoutOptions} />,
                },
                {
                    key: 'closeoutStrategy',
                    label: translate("marginCallPolicy.fields.closeoutStrategy"),
                    children: <EnumField enum='MarginPolicyCloseoutStrategy' translate={translate} value={record.closeoutStrategy} />,
                },
                {
                    key: 'workflowType',
                    label: translate("marginCallPolicy.fields.workflowType"),
                    children: <EnumField enum='MarginPolicyWorkflowType' translate={translate} value={record.workflowType} />,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
};

export const MarginCallPolicyShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<MarginCallPolicy>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            {record && <MarginCallPolicyShowInternal record={record} />}
        </Show>
    );
};
