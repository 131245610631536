import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { StaffUserAccessControl } from "../../interfaces";
import { StaffUsersForm } from "./form";
import { Edit } from "../../components/crud/edit";

export const StaffUserEdit: React.FC<IResourceComponentsProps> = () => {
    
    const { formProps, saveButtonProps } = useForm<StaffUserAccessControl>();
    
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <StaffUsersForm formProps={formProps}></StaffUsersForm>
        </Edit>
    );
};
