import {
    DateField,
    NumberField,
    Show,
    TextField,
} from "@refinedev/antd";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Col, Row } from "antd";
import React from "react";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { IdentityField } from "../../components/fields";
import { Alert } from "../../interfaces";
import { TriggerStatus } from "../../interfaces/triggerable";
import { useTitleData } from "../../contexts/title";


const AlertInfo: React.FC<{ alert: Alert }> = ({ alert }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'pair',
                    label: translate("alert.fields.pair"),
                    children: <TextField value={alert.pairId} />,
                },
                {
                    key: 'side',
                    label: translate("alert.fields.side"),
                    children: <TextField value={alert.side} />,
                },
                {
                    key: 'goodUntil',
                    label: translate("alert.fields.goodUntil"),
                    children: <DateField value={alert.goodUntil} />,
                },
                {
                    key: 'persistentStatus',
                    label: translate("alert.fields.persistentStatus"),
                    children: <TextField value={alert.persistentStatus} />,
                },
            ]
        }
    ];

    if (alert.persistentStatus === TriggerStatus.Cancelled) {
        groupOne[0].items.push(
            {
                key: 'cancellationReason',
                label: translate("alert.fields.cancellationReason"),
                children: <TextField value={alert.cancellationReason} />,
            }
        )
    };

    const groupTwo = [
        {
            items: [
                {
                    key: 'triggerPrice',
                    label: translate("alert.fields.triggerPrice"),
                    children: <NumberField value={alert.triggerPrice} />,
                },
                {
                    key: 'account',
                    label: translate("alert.fields.account"),
                    children: <IdentityField value={alert.identity} />,
                },
                {
                    key: 'createdAt',
                    label: translate("alert.fields.createdAt"),
                    children: <DateField value={alert.createdAt} />,
                },
                {
                    key: 'token',
                    label: translate("alert.fields.token"),
                    children: <TextField value={alert.token} />,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
}

export const AlertShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Alert>();
    const { data, isLoading } = queryResult;
    const translate = useTranslate();

    const alert = data?.data;
    useTitleData({ ...alert });

    return (
        <Show isLoading={isLoading} title={translate(`alert.titles.show`, { ...alert })}>
            {alert !== undefined && <AlertInfo alert={alert}></AlertInfo>}
        </Show>
    );
};
