import { DownOutlined } from "@ant-design/icons";
import {
    Button,
    Dropdown, Form, Input, MenuProps,
    Select,
    Space
} from "antd";
import React, { useState, useEffect } from "react";

const mainFonts = [
    'Neue Haas Grotesk Display Pro',
    '-apple-system',
    'Segoe UI',
    'Roboto',
    'Helvetica Neue',
    'Arial',
    'Noto Sans',
]


const monoFonts = [
    'Chivo Mono',
    'Courier Prime',
    'Cousine',
    'Cutive Mono',
    'DM Mono',
    'Fira Code',
    'Fira Mono',
    'IBM Plex Mono',
    'Inconsolata',
    'JetBrains Mono',
    'M PLUS 1 Code',
    'Martian Mono',
    'Overpass Mono',
    'Oxygen Mono',
    'PT Mono',
    'Red Hat Mono',
    'Roboto Mono',
    'Source Code Pro',
    'Space Mono',
    'Ubuntu Mono',


    'Monaco',
    'Menlo',
    'DejaVu Sans Mono',
    'Liberation Mono',
    'Bitstream Vera Sans Mono',

    'NRT Reg',
    'Droid Sans Mono',
    'Maria'
]


export const CustomizationForm: React.FC<{}> = ({ }) => {

    const [styles, setStyles] = useState({
        mainFont: 'Neue Haas Grotesk Display Pro',
        monoFont: 'NRT Reg', 
        monoFontSize: 13,
        monoFontSpacing: -0.02,
        monoFontWeight: 500,
    });

    
    const setStyle = (styles: any) => {
        var r = document.querySelector(':root') as HTMLElement;
        r.style.setProperty('--small-text-1', styles.monoFont);
        r.style.setProperty('--small-text-2', styles.mainFont);
        r.style.setProperty('--small-text-1-size', styles.monoFontSize + 'px');
        r.style.setProperty('--small-text-1-spacing', styles.monoFontSpacing + 'em');
        r.style.setProperty('--small-text-1-weight', styles.monoFontWeight);
        setStyles(styles);
    }

    useEffect(() => {
        setStyle(styles);
    }, []);

    const mainFontsOptions = mainFonts.map((style: string) => ({
        label: style,
        value: style
    }));

    const monoFontsOptions = monoFonts.map((style: string) => ({
        label: style,
        value: style
    }));

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onValuesChange = (values: any) => {
        setStyle({ ...styles, ...values })
    }

    return <>
        <Form
            name="basic"
            initialValues={styles}
            onFinish={onFinish}
            autoComplete="off"
            onValuesChange={onValuesChange}
        >
            <Form.Item
                label="Main Font"
                name="mainFont"
            >
                <Select options={mainFontsOptions} />
            </Form.Item>

            <Form.Item
                label="Numbers Font"
                name="monoFont"
            >
                <Select options={monoFontsOptions} />
            </Form.Item>

            <Form.Item
                label="Numbers Font Size"
                name="monoFontSize"
            >
                <Input type="number" step={1} />
            </Form.Item>
            <Form.Item
                label="Numbers Font Spacing"
                name="monoFontSpacing"
            >
                <Input type="number" step={0.01} />
            </Form.Item>

            <Form.Item
                label="Numbers Font Weight"
                name="monoFontWeight"
            >
                <Input type="number" step={100} />
            </Form.Item>

        </Form>
    </>
};
