import React from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Show, NumberField, TextField } from "@refinedev/antd";
import { Col, Row } from "antd";
import { Override } from "../../interfaces";
import { DescriptionGroups } from "../../components/common/DescriptionGroups";
import { EnumField } from "../../components/fields";

export const OverrideShowInternal: React.FC<{ record: Override }> = ({ record }) => {
    const translate = useTranslate();
    const groupOne = [
        {
            items: [
                {
                    key: 'orderFeeFloatingBuy',
                    label: translate("override.fields.orderFeeFloatingBuy"),
                    children: <NumberField value={record?.orderFeeFloatingBuy ?? ""} />,
                },
                {
                    key: 'orderFeeFloatingSell',
                    label: translate("override.fields.orderFeeFloatingSell"),
                    children: <NumberField value={record?.orderFeeFloatingSell ?? ""} />,
                },
                {
                    key: 'orderFeeFixedBuy',
                    label: translate("override.fields.orderFeeFixedBuy"),
                    children: <NumberField value={record?.orderFeeFixedBuy ?? ""} />,
                },
                {
                    key: 'orderFeeFixedSell',
                    label: translate("override.fields.orderFeeFixedSell"),
                    children: <NumberField value={record?.orderFeeFixedSell ?? ""} />,
                },
                {
                    key: 'chargeTiming',
                    label: translate("override.fields.chargeTiming"),
                    children: <EnumField  enum="FeeChargeTiming" translate={translate} value={record?.chargeTiming ?? ""} />,
                },
            ]
        }
    ];
    const groupTwo = [
        {
            items: [
                {
                    key: 'marginLeverageLong',
                    label: translate("override.fields.marginLeverageLong"),
                    children: <NumberField value={record?.marginLeverageLong ?? ""} />,
                },
                {
                    key: 'marginLeverageShort',
                    label: translate("override.fields.marginLeverageShort"),
                    children: <NumberField value={record?.marginLeverageShort ?? ""} />,
                },
                {
                    key: 'marginRatioLong',
                    label: translate("override.fields.marginRatioLong"),
                    children: <NumberField value={record?.marginRatioLong ?? ""} />,
                },
                {
                    key: 'marginRatioShort',
                    label: translate("override.fields.marginRatioShort"),
                    children: <NumberField value={record?.marginRatioShort ?? ""} />,
                },
                {
                    key: 'permittedMode',
                    label: translate("override.fields.permittedMode"),
                    children: <EnumField enum="TradingMode" translate={translate} value={record?.permittedMode} />,
                },
                {
                    key: 'description',
                    label: translate("override.fields.description"),
                    children: <TextField value={record?.description ?? ""} />,
                },
            ]
        }
    ];

    return (
        <Row>
            <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                <DescriptionGroups groups={groupOne} size="small" layout={'horizontal'} column={1} />
            </Col>
            <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                <DescriptionGroups groups={groupTwo} size="small" layout={'horizontal'} column={1} />
            </Col>
        </Row>
    )
};

export const OverrideShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<Override>();
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <OverrideShowInternal record={record!} />
        </Show>
    );
};
