import {
  useApiUrl,
  useCustom,
  useTranslate
} from "@refinedev/core";
import { Button, Card, Col, Collapse, DatePicker, Row, Space, Spin, Typography } from "antd";
import { CollapseProps } from "antd/lib";
import { ApexOptions } from "apexcharts";
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { AggregationType, CategoryExposure, ExposureReport, ExposureType } from "../../../interfaces";
import { CategoryLabelFormatters, formatNumberFix, getRangePresets } from "../../../utils";

type ExposureHistoryPaneProps = {
  activeStat: ExposureType,
  assetType: AggregationType,
  displayDecimals: number,
  denominatedInAssetId: string
};


const nowTime = dayjs();
const backTime = nowTime.subtract(1, 'hour');

const ExposureHistoryPane: React.FC<ExposureHistoryPaneProps> = ({ assetType, displayDecimals, denominatedInAssetId, activeStat }) => {
  const translate = useTranslate();

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([backTime, nowTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateRange(dateRange => [dateRange[0].add(1, 'm'), dateRange[1].add(1, 'm')]);
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [activeStat, assetType, dateRange]);

  const apiUrl = useApiUrl();
  const dateFormat = 'YYYY-MM-DD HH:mm';
  const { data: exposureHistoryData, isLoading } = useCustom<ExposureReport[]>({
    url: `${apiUrl}/exposure/By${assetType}/history`,
    config: {
      query: {
        from: dateRange[0].utc().format(dateFormat),
        to: dateRange[1].utc().format(dateFormat),
      }
    },
    method: 'get'
  });

  const decimals = displayDecimals;

  const { Title, Text } = Typography;
  const { RangePicker } = DatePicker;

  const { series, labels } = useMemo(() => {
    const retLabels = new Array<string>();

    const seriesData = new Map<string, any>();

    const allCategories = exposureHistoryData?.data.reduce((acc, report) => {
      report.categories.forEach(category => acc.add(category.categoryId));
      return acc;
    }, new Set<string>());

    allCategories?.forEach(categortId => {
      seriesData.set(categortId, {
        long: Array.from({ length: exposureHistoryData?.data.length || 0 }, (_) => null),
        short: Array.from({ length: exposureHistoryData?.data.length || 0 }, (_) => null),
        netted: Array.from({ length: exposureHistoryData?.data.length || 0 }, (_) => null),
      })
    });

    exposureHistoryData?.data.forEach((report: ExposureReport, index: number) => {
      retLabels.push(report.preparedOn);
      report.categories.forEach((category: CategoryExposure) => {
        const data = seriesData.get(category.categoryId);
        data.long[index] = category.long[activeStat];
        data.short[index] = category.short[activeStat];
        data.netted[index] = category.netted[activeStat];
      });
    });

    const retSeries = {
      long: new Array<any>,
      short: new Array<any>,
      netted: new Array<any>,
    }

    seriesData.forEach((value, key) => {
      const categoryName = CategoryLabelFormatters[assetType](key);
      retSeries.long.push({ name: categoryName, data: [...value.long] })
      retSeries.short.push({ name: categoryName, data: [...value.short] })
      retSeries.netted.push({ name: categoryName, data: [...value.netted] })
    });

    ApexCharts.exec('history-long', 'updateSeries', retSeries.long);
    ApexCharts.exec('history-short', 'updateSeries', retSeries.short);
    ApexCharts.exec('history-netted', 'updateSeries', retSeries.netted);
    

    return { series: retSeries, labels: retLabels };
  }, [exposureHistoryData?.data, activeStat]);

  const options: ApexOptions = {
    chart: {
      background: '#202024',
      group: `history`,
      toolbar: {
        show: false
      },
    },
    grid: {
      borderColor: '#121214',
    },
    theme: { mode: "dark", palette: "palette6" },
    legend: {
      show: true,
    },
    yaxis: {
      labels: {
        formatter: (val: number, opts?: any) => {
          return formatNumberFix(val, decimals);
        }
      }
    },
    stroke: {
      width: 2,
    },
    labels: labels,
    xaxis: {
      categories: labels,
      type: 'datetime',
      labels: {
        formatter: function (value) {
          return value ? dayjs(value).local().format('HH:mm MMM DD') : '';
        }
      }
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const c = w.globals;
        const values = w.config.series.map((serie: any, index: number) => {
          return [serie.data[dataPointIndex], serie.name, index]
        }).sort((a: any, b: any) => b[0] - a[0]);

        const ss = values.map((val: any, index: number) => {
          let value = val[0];
          value = c.yLabelFormatters[0](value, w);
          const color = c.colors[val[2]];
          return `<div class="apexcharts-tooltip-series-group custom-tooltip" style="order: ${index}; display: flex;">
      <div class="apexcharts-tooltip-text">
          <div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label"><span
          class="apexcharts-tooltip-marker" style="background-color: ${color};"></span> ${val[1]}: </span><span
                  class="apexcharts-tooltip-text-y-value">${value}</span></div>
      </div>
  </div>
  `;
        });
        const dt = c.ttKeyFormatter(labels[dataPointIndex]);
        return `<div class="apexcharts-tooltip-title">${dt}</div>` + ss.join('');
      }
    }
  };
  const nettedOptions = { ...options, chart: { ...options.chart, id: `history-netted` } }
  const longOptions = { ...options, chart: { ...options.chart, id: `history-long` } }
  const shortOptions = { ...options, chart: { ...options.chart, id: `history-short` } }

  const rangePresets = getRangePresets(translate);

  const header = <Title level={4}>{translate('dashboard.exposureHistory')}</Title>

  const extra = <Row justify="space-between" className="mb-8 hide-extra" align="middle" onClick={(event) => {
    event.stopPropagation();
  }}>
    <Col>
      <Card size="small">
        <Space>
          {rangePresets.map((range, index) => <Button key={index} onClick={() => setDateRange(range.value as never)}>{range.label}</Button>)}
          <RangePicker presets={rangePresets} showTime value={dateRange} onChange={(a1, a2) => a1 && setDateRange([a1[0]!, a1[1]!])} />
        </Space>
      </Card>
    </Col>
  </Row>


  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: header,
      children: (<Row justify="space-between" gutter={[16, 24]} className="mb-24">
        <Col sm={24} md={12} lg={8}>
          <Card size="small" title={translate('dashboard.fields.total')}>
            <Spin spinning={isLoading}>
              {exposureHistoryData?.data && <ReactApexChart options={nettedOptions} series={series.netted} type="line" width="100%" height={"250px"} />}
            </Spin>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Card size="small" title={translate('dashboard.fields.long')}>
            <Spin spinning={isLoading}>
              {exposureHistoryData?.data && <ReactApexChart options={longOptions} series={series.long} type="line" width="100%" height={"250px"} />}
            </Spin>
          </Card>
        </Col>
        <Col sm={24} md={12} lg={8}>
          <Card size="small" title={translate('dashboard.fields.short')}>
            <Spin spinning={isLoading}>
              {exposureHistoryData?.data && <ReactApexChart options={shortOptions} series={series.short} type="line" width="100%" height={"250px"} />}
            </Spin>
          </Card>
        </Col>
      </Row>),
      extra: extra,
    },
  ];

  return (
    <>
      <Collapse items={items}>

      </Collapse>
    </>
  );
}

export default ExposureHistoryPane;