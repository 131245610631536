import {
    useTable
} from "@refinedev/antd";
import {
    IResourceComponentsProps
} from "@refinedev/core";
import React from "react";
import { List } from "../../components/crud/list";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { Order } from "../../interfaces";
import { OrdersTable } from "./closedOrdersTable";

export const ClosedOrderList: React.FC<IResourceComponentsProps> = () => {
    const { search, listProps } = useSearchList();

    const query: Record<string, string> = {};
    if (search) {
        query.tokenLIKE = search; // TODO: search
    }
    const { tableProps, filters, sorters } = useTable<Order>({
        initialPageSize: defaultPageSize,
        resource: 'order',
        meta: { query },
        sorters: {
            initial: [
                {
                    field: "createdAt",
                    order: "desc",
                },
            ],
        }
    });

    return (
        <List {...listProps} resource="order" >
            <OrdersTable {...tableProps} withIdentity={true} filters={filters} sorters={sorters} />
        </List>
    );
};
