import {
  DeleteButton,
  EditButton,
  ShowButton,
  getDefaultSortOrder,
  useTable,
} from "@refinedev/antd";
import {
  BaseRecord,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";
import { List } from "../../components/crud/list";
import { LogoCategoryField } from "../../components/fields";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { PairCategory, PairCategoryType } from "../../interfaces";
import { getEnumFilter } from "../../utils";

export const PairCategoryList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { search, listProps } = useSearchList();

  const query: Record<string, string> = {};
  if (search) {
    query.nameLIKE = search;
    query.descriptionLIKE = search;
  }
  const { tableProps, sorters } = useTable<PairCategory>({
    syncWithLocation: true,
    initialPageSize: defaultPageSize,
    meta: { query },
    sorters: {
      initial: [
        {
          field: "token",
          order: "asc",
        },
      ],
    }
  });
  const pairCategoryTypes = getEnumFilter(PairCategoryType, 'PairCategoryType', translate, [PairCategoryType.Unknown]);
  const clickTableProps = useTableClick({ resource: 'pairCategory' });

  return (
    <List {...listProps}>
      <Table {...tableProps} rowKey="id" {...clickTableProps}>
        <Table.Column
          rowScope="rowgroup"
          dataIndex="token"
          sorter={true}
          defaultSortOrder={getDefaultSortOrder("token", sorters)}
          title={translate("pairCategory.fields.token")}
          render={(value: any, record: PairCategory) => <LogoCategoryField token={value} type={record.type} name={value} />}
        />
        <Table.Column
          dataIndex="name"
          sorter={true}
          title={translate("pairCategory.fields.name")}
        />
        <Table.Column
          dataIndex="description"
          title={translate("pairCategory.fields.description")}
        />

        <Table.Column
          dataIndex="type"
          sorter={true}
          title={translate("pairCategory.fields.type")}
          filters={pairCategoryTypes}
        />


        <Table.Column
          dataIndex="actions"
          align="right"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
