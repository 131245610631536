import React from "react";
import { Authenticated, Refine } from "@refinedev/core";

import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes.jsx';

import {
  ErrorComponent,
  notificationProvider,
} from "@refinedev/antd";


import "@refinedev/antd/dist/reset.css";

import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier
} from "@refinedev/react-router-v6";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { AppIcon } from "./components/app-icon";
import { Header } from "./components/header";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { authProvider } from "./providers/authProvider.js";

import { ThemedLayoutV2 } from "./components/themedLayout/index.js";
import { ThemedTitleV2 } from "./components/themedLayout/title.js";
import { TitleContextProvider } from "./contexts/title";
import { accessControlProvider } from "./providers/accessControlProvider";
import dataProvider from "./providers/restDataProvider";
import { resourceRoutes } from "./resourceRoutes.js";
import { resources } from "./resources.js";
import { I18nProvider } from "@refinedev/core";


const api_url = `${import.meta.env.VITE_BACKEND_URL}api`;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <ColorModeContextProvider>

        <Refine
          dataProvider={dataProvider(api_url)}
          notificationProvider={notificationProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          routerProvider={routerBindings}
          accessControlProvider={accessControlProvider}
          resources={resources}
          options={{
            syncWithLocation: true,
            warnWhenUnsavedChanges: true,
            projectId: "SFoDsa-mu7nW0-siM5JO",
          }}
        ><TitleContextProvider>
            <Routes>
              {ApiAuthorzationRoutes.map((route: any, index: any) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}

              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to={ApplicationPaths.Login} />} key="default">
                    <ThemedLayoutV2
                      initialSiderCollapsed={true}
                      Header={() => <Header sticky />}
                      Title={({ collapsed }) => (
                        <ThemedTitleV2
                          collapsed={collapsed}
                          text="Lyra Admin"
                          icon={<AppIcon />}
                        />
                      )}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                {resourceRoutes}
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />} key="other" >
                    <NavigateToResource />
                  </Authenticated>
                }
              >
              </Route>
            </Routes>
          </TitleContextProvider>
          <UnsavedChangesNotifier />
        </Refine>

      </ColorModeContextProvider>
    </BrowserRouter>
  );
}

export default App;
