import { BaseRecord, useNavigation } from "@refinedev/core";
import { useNavigate } from "react-router-dom";

export type TableNavigateFunction = (record: BaseRecord, rowIndex?: number) => string;

type useTableClickProps = {
    resource: string,
    action?: string,
    meta?: any,
    onClick?: CallableFunction,
};

export const useTableClick = ({
    resource, action, meta, onClick
}: useTableClickProps

): { onRow: any, rowClassName: any } => {
    if (!action) {
        action = "edit";
    }
    const navigate = useNavigate();
    const { editUrl, showUrl } = useNavigation();

    const onRow = (record: BaseRecord, rowIndex?: number) => {
        return {
            onClick: (event: any) => {
                if (onClick) {
                    return onClick(record, rowIndex);
                }
                const target = (event.target as HTMLElement);
                const isLink = target.tagName === "A" || target.closest("a") || target.tagName === "BUTTON" || target.closest("button");
                if (!isLink) {
                    const url = action === "edit" ? editUrl(resource, record.id!, meta) : showUrl(resource, record.id!, meta);
                    navigate(url);
                }
            },
        };
    }

    const rowClassName = (record: BaseRecord, index: number) => `clickable-row default-action-${action}`;

    return { onRow, rowClassName }
}