import {
    EditButton,
    ShowButton, useTable
} from "@refinedev/antd";
import {
    BaseRecord,
    useTranslate
} from "@refinedev/core";
import { Col, Row, Select, Space, Table, TableColumnsType } from "antd";
import React, { useState } from "react";
import { ClosePositionButton } from "../../components/buttons/closePosition";
import { List } from "../../components/crud/list";
import { DateField, NumberField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { UnitValueField } from "../../components/fields/UnitValueField";
import { useArrayFilter } from "../../hooks/useArrayFilter";
import { useFilter } from "../../hooks/useFilter";
import { useSearchList } from "../../hooks/useSearchList";
import { Account, Position, RiskManagementRecord } from "../../interfaces";


export const MarginManagementList: React.FC = () => {

    const translate = useTranslate();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { search, listProps } = useSearchList();
    const [top, setTop] = useState<number>(10);

    const expandedRowRender = (record: RiskManagementRecord) => {
        const currency = record.account.denominatedInAsset?.symbol;
        const digits = record.account.denominatedInAsset?.decimals;

        const columns: TableColumnsType<Position> = [
            { title: 'Pair', dataIndex: 'pairId', key: 'pairId' },
            {
                title: 'Side',
                key: 'side',
                dataIndex: 'side',
            },
            {
                title: translate("position.fields.currentMargin"),
                key: 'currentMargin',
                dataIndex: 'currentMargin',
                align: "end",
                render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
            },
            {
                title: translate("position.fields.currentValue"),
                key: 'currentValue',
                dataIndex: 'currentValue',
                align: "end",
                render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
            },
            {
                title: translate("position.fields.unrealizedPnL"),
                key: 'unrealizedPnL',
                dataIndex: 'unrealizedPnL',
                align: "end",
                render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
            },
            {
                title: translate("position.fields.entranceCost"),
                key: 'entranceCost',
                dataIndex: 'entranceCost',
                align: "end",
                render: (value: number) => <UnitValueField value={value} unit={currency} digits={digits} />
            },
            {
                title: 'Notional',
                key: 'notional',
                align: "end",
                dataIndex: 'notional',
                render: (value: number) => <NumberField value={value} />
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                align: "end",
                render: (value: string) => <DateField value={value} format="lll" />
            },
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
                render: (_, record: BaseRecord) => (
                    <Space>
                        <ClosePositionButton
                            hideText
                            size="small"
                            resource="position"
                            ids={[record.id]}
                            confirmOkText={translate("management_margin.buttons.confirm_cancel")}
                        />
                    </Space>
                )
            },
        ];

        const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(newSelectedRowKeys);
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: onSelectChange,
        };
        const calculateFooterData = () => {
            let total = 0;
            record.positions.positions.forEach(item => {
                if (selectedRowKeys.includes(item.id))
                    total += item.currentValue;
            });
            return <>{translate('management_margin.total')}: <b><NumberField value={total} className="text-number" /></b> {record.account.denominatedInAsset?.symbol}</>;
        }

        return <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={record.positions.positions}
            pagination={false}
            footer={() => calculateFooterData()}
        />;
    };

    const query: Record<string, string> = {};
    if (search) {
        query.tokenLIKE = search;
    } else {
        query.top = top.toString();
    }

    const { tableProps } = useTable<RiskManagementRecord>({
        syncWithLocation: true,
        pagination: { mode: 'off' },
        meta: { query }
    });

    const hasSelected = selectedRowKeys.length > 0;

    const accountGroupFilters = useFilter({ resource: 'accountGroup' })
    const assetFilters = useArrayFilter({ resource: 'reference', meta: { endpoint: 'account/reference/denominatedInAssetId' } })

    return (
        <List title={translate('management_margin.titles.list')} {...listProps} autorefresh={true}>
            <ClosePositionButton
                resource="position"
                disabled={!hasSelected}
                ids={selectedRowKeys}
                confirmOkText={translate("management_margin.buttons.confirm_cancel")}
            >
                {translate("management_margin.buttons.delete")}
            </ClosePositionButton>
            <Table
                {...tableProps}
                rowKey={(record) => record.account.id}
                expandable={{ expandedRowRender, expandRowByClick: true }}
                className='wrap-normal'
            >
                <Table.Column
                    dataIndex={["account"]}
                    className="nowrap"
                    title={translate("account.fields.account")}
                    render={(value: Account) => <AccountField value={value} link="management" showCurrency={false} />}
                />
                <Table.Column
                    dataIndex={["account", "accountGroup"]}
                    title={translate("account.fields.accountGroup")}
                    filters={accountGroupFilters}
                    sorter={true}
                    render={(value) => <>{value?.name}</>}
                />
                <Table.Column
                    dataIndex={["account", "denominatedInAssetId"]}
                    title={translate("account.fields.denominatedInAsset")}
                    filters={assetFilters}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "availableToTrade"]}
                    title={translate("portfolio.availableToTrade")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "netEquity"]}
                    title={translate("portfolio.netEquity")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "cash"]}
                    title={translate("portfolio.cash")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "unrealizedPnl"]}
                    title={translate("portfolio.unrealizedPnl")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "totalMargin"]}
                    title={translate("portfolio.totalMargin")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "marginIndicator"]}
                    title={translate("portfolio.marginIndicator")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["positions", "totalValue"]}
                    title={translate("portfolio.totalValue")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />

                <Table.Column
                    align="end"
                    dataIndex={["account", "currentBalance"]}
                    title={translate("account.fields.currentBalance")}
                    render={(value: number, record: RiskManagementRecord) => <NumberField className="text-number" value={value} digits={record.account.denominatedInAsset?.decimals} />}
                    sorter={true}
                />
                <Table.Column
                    align="end"
                    dataIndex={["account", "reservedAmount"]}
                    title={translate("account.fields.reservedAmount")}
                    render={(value: number) => <NumberField className="text-number" value={value} />}
                    sorter={true}
                />
                <Table.Column
                    title={translate("table.actions")}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                resource="account"
                                recordItemId={record.account.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                resource="account"
                                recordItemId={record.account.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            {!search && <Row justify="end" align="middle" gutter={10}>
                <Col><label>{translate('management_margin.top')}: </label></Col>
                <Col>
                    <Select
                        value={top}
                        onChange={(value) => setTop(value)}
                        options={[{ value: 10, label: '10' }, { value: 20, label: '20' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 500, label: '500' },]}>
                    </Select>
                </Col>
            </Row>}
        </List >
    );
};
