import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { StaffUsersForm } from "./form";

export const StaffUserCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm();

    return (
        <Create saveButtonProps={saveButtonProps}>
            <StaffUsersForm formProps={formProps}></StaffUsersForm>
        </Create>
    );
};
