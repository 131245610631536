import {
    DeleteButton,
    EditButton,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import {
    BaseRecord,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import { Space, Table, Tooltip } from "antd";
import React from "react";
import { List } from "../../components/crud/list";
import { BooleanField, EnumField } from "../../components/fields";
import { EmailField } from "../../components/fields/EmailField";
import { PhoneField } from "../../components/fields/PhoneField";
import { defaultPageSize } from "../../constants";
import { useFilter } from "../../hooks/useFilter";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { User, UserStatus } from "../../interfaces";
import { getBooleanTableFilter, getEnumFilter } from "../../utils";

export const UserList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const statusFilter = getEnumFilter(UserStatus, 'UserStatus', translate, ["Unknown"]);
    const yesNo = getBooleanTableFilter(translate);

    const { search, listProps } = useSearchList();

    const query: Record<string, string> = {
        '_include': 'userGroup',
    };
    if (search) {
        query._SEARCH = search;
    }
    const { tableProps } = useTable<User>({
        initialPageSize: defaultPageSize,
        resource: 'user',
        meta: { query }
    });

    const clickTableProps = useTableClick({ resource: 'user', action: "show", meta: { tab: 'details' } });
    const userFilters = useFilter({ resource: 'userGroup', labelName: 'name' })

    return (
        <List {...listProps}>
            <Table
                {...tableProps}
                rowKey="id"
                {...clickTableProps}
                className="wrap-normal"
            >
                <Table.Column
                    dataIndex={"token"}
                    title={translate(`user.fields.token`)}
                    width={170}
                    sorter={true}
                />
                <Table.Column
                    dataIndex={"userName"}
                    title={translate(`user.fields.userName`)}
                    sorter={true}
                />
                <Table.Column
                    dataIndex={"fullName"}
                    title={translate(`user.fields.fullName`)}
                />
                <Table.Column
                    dataIndex={"fullAddress"}
                    title={translate(`user.fields.fullAddress`)}
                    className="ellipsis"
                    width={220}
                    render={(value) => <Tooltip title={value}><span>{value}</span></Tooltip>}
                />
                <Table.Column
                    dataIndex={"email"}
                    title={translate(`user.fields.email`)}
                    render={(value) => <EmailField value={value} />}
                    sorter={true}
                />
                <Table.Column
                    dataIndex={"phoneNumber"}
                    title={translate(`user.fields.phoneNumber`)}
                    render={(value: string, record: User) => <PhoneField value={value} confirmed={record.phoneNumberConfirmed} />}
                    sorter={true}
                />
                <Table.Column
                    dataIndex={"twoFactorEnabled"}
                    title={translate(`user.fields.twoFactorEnabled`)}
                    render={(value: string) => <BooleanField value={value} />}
                    sorter={true}
                    filters={yesNo}
                />
                <Table.Column
                    dataIndex={["userGroupId"]}
                    title={translate(`user.fields.userGroup`)}
                    filters={userFilters}
                    render={(value: string, record: User) => record?.userGroup?.name}
                />
                <Table.Column
                    dataIndex={"status"}
                    title={translate(`user.fields.status`)}
                    render={(value: string) => <EnumField value={value} enum="UserStatus" translate={translate} />}
                    filters={statusFilter}
                />

                <Table.Column
                    align="end"
                    width={100}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
