import {
    useTable
} from "@refinedev/antd";
import {
    useMany,
    useTranslate
} from "@refinedev/core";
import { Col, DatePicker, Form, Row, Select, Tabs } from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Account, AccountData, AccountingAggregation, AccountingReportLine, Asset, TransactionType } from "../../interfaces";
import { getEnumOptions } from "../../utils";
import AccountingTable from "./table";
import { List } from "../../components/crud/list";


export const AccountingList: React.FC = () => {

    const translate = useTranslate();

    const dimensionOptions = getEnumOptions(AccountingAggregation, 'AccountingAggregation', translate)
    const typeOptions = getEnumOptions(TransactionType, 'TransactionType', translate, [TransactionType.Unknown])

    const [form] = useForm();
    const dimensions = useWatch('dimensions', form)
    const types = useWatch('types', form)
    const fromGTE = useWatch('fromDate', form)
    const toLT = useWatch('toDate', form)

    const query = {
        dimension: dimensions,
        type: types,
        fromGTE: fromGTE?.toISOString(),
        toLT: toLT?.toISOString(),
    }

    const { tableProps } = useTable<AccountingReportLine>({
        syncWithLocation: true,
        pagination: { mode: 'off' },
        meta: { query: query }
    });

    const accountIds = new Set(tableProps?.dataSource?.map((item: AccountingReportLine) => item?.accountId).filter((id?: number) => id && id > 0) as number[] ?? []);

    const { data: accountData, isLoading: accountIsLoading } = useMany<AccountData>({
        resource: "account",
        ids: Array.from(accountIds),
        queryOptions: {
            enabled: !!accountIds.size,
        },
    });

    type stateType = { [key: string]: AccountingReportLine[]; }
    const [data, setData] = useState<stateType>({});
    const assetsIds = new Set(tableProps?.dataSource?.map((item: AccountingReportLine) => item?.denominatedIn).filter((id?: string) => !!id) as string[] ?? []);

    const { data: assetsData } = useMany<Asset>({
        resource: "asset",
        ids: Array.from(assetsIds),
        queryOptions: {
            enabled: !!assetsIds.size,
        },
    });

    useEffect(() => {
        if (tableProps?.dataSource?.length) {
            const data: stateType = {};
            tableProps?.dataSource.forEach((item: AccountingReportLine) => {
                if (!data[item.denominatedIn]) {
                    data[item.denominatedIn] = [];
                }
                const account = accountData?.data?.find((a: Account) => a.id === item.accountId);
                return data[item.denominatedIn].push({ ...item, accountName: account?.token });
            })
            setData(data);
        }
    }, [tableProps?.dataSource, accountData?.data])

    const initialValues = {
        dimensions: [
            AccountingAggregation.ByAssetClass,
            AccountingAggregation.ByTransactionType
        ],
        types: [
            TransactionType.MarginInterest,
            TransactionType.SwapInterest,
            TransactionType.Fee,
            TransactionType.StockBorrowInterest,
            TransactionType.IdleCash
        ],
        toDate: dayjs().endOf('day'),
        fromDate: dayjs().subtract(1, 'month').startOf('day'),
    }

    return (
        <List title={translate('accounting.titles.list')}>
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                layout="horizontal"
                initialValues={initialValues}
                form={form}
                style={{ maxWidth: 1024 }}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item name="dimensions" label={translate('accounting.fields.dimensions')} rules={[{ required: true }]}>
                            <Select options={dimensionOptions} maxCount={3} mode="multiple" />
                        </Form.Item>
                        <Form.Item name="types" label={translate('accounting.fields.txType')} >
                            <Select options={typeOptions} mode="multiple" />
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                        <Form.Item name="fromDate" label={translate('accounting.fields.from')} >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item name="toDate" label={translate('accounting.fields.to')} >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Tabs items={Object.keys(data).map((currency) => {
                return {
                    label: currency,
                    key: currency,
                    children: <AccountingTable
                        key={currency}
                        dimensions={dimensions}
                        types={types}
                        fromGTE={fromGTE}
                        toLT={toLT}
                        currency={currency}
                        asset={assetsData?.data?.find((a: Asset) => a.id === currency)}
                        records={data[currency]}
                    />,
                };
            })} />


        </List>
    );
};
