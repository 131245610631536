import { IResourceItem, useTranslate, useUserFriendlyName, userFriendlyResourceName } from "@refinedev/core";


export const safeTranslate = (
    translate: ReturnType<typeof useTranslate>,
    key: string,
    defaultMessage?: string,
    options?: any,
) => {
    const translated = options
        ? translate(key, options, defaultMessage)
        : translate(key, defaultMessage);

    const fallback = defaultMessage ?? key;

    if (translated === key || typeof translated === "undefined") {
        return fallback;
    }

    return translated;
};

/**
 * Generates document title for the given resource and action.
 */
export function generateDefaultDocumentTitle(
    translate: ReturnType<typeof useTranslate>,
    resource?: IResourceItem,
    action?: string,
    id?: string,
    resourceName?: string,
) {
    const actionPrefixMatcher = {
        create: "Create new ",
        clone: `#${id ?? ""} Clone `,
        edit: `#${id ?? ""} Edit `,
        show: `#${id ?? ""} Show `,
        list: "",
    };

    const identifier = resource?.identifier ?? resource?.name;

    const resourceNameFallback =
        resource?.label ??
        resource?.meta?.label ??
        userFriendlyResourceName(
            identifier,
            action === "list" ? "plural" : "singular",
        );

    const resourceNameWithFallback = resourceName ?? resourceNameFallback;

    const defaultTitle = safeTranslate(
        translate,
        "documentTitle.default",
        "refine",
    );
    const suffix = safeTranslate(translate, "documentTitle.suffix", " | refine");
    let autoGeneratedTitle = defaultTitle;

    if (action && identifier) {
        autoGeneratedTitle = safeTranslate(
            translate,
            `documentTitle.${identifier}.${action}`,
            `${actionPrefixMatcher[action as keyof typeof actionPrefixMatcher] ?? ""
            }${resourceNameWithFallback}${suffix}`,
            { id },
        );
    }

    return autoGeneratedTitle;
}
