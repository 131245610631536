import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import { MarginCallPolicyForm } from "./form";
import { Edit } from "../../components/crud/edit";

export const MarginCallPolicyEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    const marginCallPolicyData = queryResult?.data?.data;

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <MarginCallPolicyForm formProps={formProps} />
        </Edit>
    );
};
