import { useTable } from "@refinedev/antd";
import {
    IResourceComponentsProps
} from "@refinedev/core";
import React from "react";
import { List } from "../../components/crud/list";
import { ConnectionInfo } from "../../interfaces";
import { SessionsTable } from "./table";


export const SessionsList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<ConnectionInfo>({
        resource: "sessions",        
        syncWithLocation: true,
    });

    return (
        <List>
            <SessionsTable tableProps={tableProps} />
        </List>
    );
};
