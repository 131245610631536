import { Create, useForm } from "@refinedev/antd";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import React from "react";
import { Order, OrderSide, OrderType } from "../../interfaces";
import { OrderForm } from "./form";

export const OrderCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const { formProps, saveButtonProps, queryResult } = useForm<Order>(
        {
            resource: "order",
            action: "create",
            mutationMeta: {
                endpoint: `useractions/updateorder`,
            },
            warnWhenUnsavedChanges: true,
            invalidates: ["list", "detail"]
        }
    );

    if (formProps && !formProps.initialValues) {
        formProps.initialValues = { 
            type: OrderType.Market,
            side: OrderSide.Buy,
            priceTolerance: null,
        }
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <OrderForm formProps={formProps} create={true} />
        </Create>
    );


    // return (
    //     <Create saveButtonProps={saveButtonProps}>
    //         <Form {...formProps} layout="vertical">
    //             <Form.Item
    //                 label={translate("order.fields.type")}
    //                 name={["type"]}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Input />
    //             </Form.Item>
    //             <Form.Item
    //                 label={translate("order.fields.quotedPrice")}
    //                 name={["quotedPrice"]}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Input />
    //             </Form.Item>
    //             <Form.Item
    //                 label={translate("order.fields.qty")}
    //                 name={["qty"]}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Input />
    //             </Form.Item>

    //             <Form.Item
    //                 label={translate("order.fields.pairId")}
    //                 name={"pairId"}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Select {...pairSelectProps} />
    //             </Form.Item>
    //             <Form.Item
    //                 label={translate("order.fields.side")}
    //                 name={["side"]}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Input />
    //             </Form.Item>

    //             <Form.Item
    //                 label={translate("order.fields.goodUntil")}
    //                 name={["goodUntil"]}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //                 getValueProps={(value) => ({
    //                     value: value ? dayjs(value) : undefined,
    //                 })}
    //             >
    //                 <DatePicker />
    //             </Form.Item>


    //             <Form.Item
    //                 label={translate("order.fields.triggerPrice")}
    //                 name={["triggerPrice"]}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Input />
    //             </Form.Item>




    //             <Form.Item
    //                 label={translate("order.fields.accountId")}
    //                 name={"accountId"}
    //                 rules={[
    //                     {
    //                         required: true,
    //                     },
    //                 ]}
    //             >
    //                 <Select {...accountSelectProps} />
    //             </Form.Item>
    //         </Form>
    //     </Create>
    // );
};

