import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";
import { Card, Col, DatePicker, Form, FormProps, Input, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import { Asset, Lending, LendingType } from "../../interfaces";
import dayjs from "dayjs";
import { getEnumOptions } from "../../utils";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";

export const LendingForm: React.FC<
    { formProps: FormProps }
> = ({ formProps }) => {
    const translate = useTranslate();
    const lendingTypeOptions = getEnumOptions(LendingType, 'LendingType', translate, [LendingType.Unknown]);
    const { assetId, lendingId } = useParams();
    const { selectProps: assetSelectProps } = useSelect<Asset>({
        resource: "asset",
        defaultValue: assetId,
        optionLabel: "name",
    });

    return (
        <Form {...formProps} {...defaultFormLayout}>
            <Row>
                <Col xs={{ span: 6 }} lg={{ span: 6 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("lending.fields.asset")}
                            name={"assetId"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...assetSelectProps} disabled={!!lendingId} />
                        </Form.Item>
                        <Form.Item
                            label={translate("lending.fields.effectiveFrom")}
                            name={["effectiveFrom"]}
                            getValueProps={(value) => {
                                return {
                                    value: dayjs.isDayjs(value) ? value : value ? dayjs.unix(value) : undefined,
                                }
                            }}
                            normalize={(value, prevValue, prevValues) => {
                                return dayjs.isDayjs(value) ? value.unix() : value;
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker disabled={!!lendingId} />
                        </Form.Item>
                        <Form.Item
                            label={translate("lending.fields.lendingType")}
                            name={["lendingType"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select size="small" options={lendingTypeOptions} disabled={!!lendingId} />
                        </Form.Item>
                        <Form.Item
                            label={translate("lending.fields.basicRate")}
                            name={["basicRate"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("lending.fields.fromAmount1")}
                            name={["fromAmount1"]}
                        >
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item
                            label={translate("lending.fields.rate1")}
                            name={["rate1"]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("lending.fields.fromAmount2")}
                            name={["fromAmount2"]}
                        >
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item
                            label={translate("lending.fields.rate2")}
                            name={["rate2"]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Card>
                </Col>
                <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                    <Card className="mb-24" size="small">
                        <Form.Item
                            label={translate("lending.fields.fromAmount3")}
                            name={["fromAmount3"]}
                        >
                            <Input type="number" />
                        </Form.Item>
                        <Form.Item
                            label={translate("lending.fields.rate3")}
                            name={["rate3"]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    );
};
