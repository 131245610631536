import React from "react";
import dayjs from "dayjs";
import { Typography } from "antd";

import { DateFieldProps } from "@refinedev/antd";

/**
 * This field is used to display dates. It uses {@link https://day.js.org/docs/en/display/format `Day.js`} to display date format.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/fields/date} for more details.
 */
export const GoodUntilDateField: React.FC<DateFieldProps> = ({
    value,
    locales,
    format: dateFormat = "L",
    ...rest
}) => {
    const defaultLocale = dayjs.locale();
    const dateValue = dayjs(value);
    let displayValue;
    const date = value?.toString().slice(0,16);
    const eod = dayjs().endOf('day').toISOString().slice(0,16);
    if (dateValue.year() === 2100) {
        displayValue = 'GTC';
    } 
    else if (eod === date) {
        displayValue = 'GTD';
    }
    else {
        displayValue = dateValue.locale(locales || defaultLocale).format(dateFormat);
    }
    const { Text } = Typography;

    return (
        <Text {...rest}>
            {displayValue}
        </Text>
    );
};
