import {
    BaseRecord,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import React from "react";

import {
    DeleteButton,
    EditButton,
    ShowButton,
    useTable
} from "@refinedev/antd";
import { Space, Table } from "antd";
import { List } from "../../components/crud/list";
import { BooleanField, EnumField } from "../../components/fields";
import { defaultPageSize } from "../../constants";
import { useSearchList } from "../../hooks/useSearchList";
import { useTableClick } from "../../hooks/useTableClick";
import { UserCategory } from "../../interfaces";
import { getBooleanTableFilter, getEnumFilter } from "../../utils";

export const UserGroupList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { search, listProps } = useSearchList();
    const query: Record<string, string> = {};
    if (search) {
        query.nameLIKE = search;
        query.descriptionLIKE = search;
    }
    const { tableProps } = useTable({
        syncWithLocation: true,
        meta: { query },
        initialPageSize: defaultPageSize,
    });

    const clickTableProps = useTableClick({ resource: 'userGroup', action: 'edit' });
    const yesNo = getBooleanTableFilter(translate);
    const categoryFilter = getEnumFilter(UserCategory, 'UserCategory', translate, [UserCategory.Unknown]);

    return (
        <List {...listProps}>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex="name"
                    title={translate("userGroup.fields.name")}
                />
                <Table.Column
                    dataIndex="description"
                    title={translate("userGroup.fields.description")}
                />
                <Table.Column
                    dataIndex={["isDefaultGroup"]}
                    title={translate("userGroup.fields.isDefaultGroup")}
                    filters={yesNo}
                    render={(value: boolean) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["category"]}
                    title={translate("userGroup.fields.category")}
                    filters={categoryFilter}
                    render={(value: UserCategory) => <EnumField enum="UserCategory" value={value} translate={translate} />}
                />
                <Table.Column
                    align="right"
                    width={130}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
