import React, { useEffect } from "react";
import { IResourceComponentsProps, useList, useTranslate, usePermissions, useUpdate } from "@refinedev/core";
import { useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Checkbox, DatePicker, Card, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import { getEnumOptions } from "../../utils";
import { DocIdTypeEnum, StatementFrequency, TaxIdTypeEnum, User, UserRole, UserStatus } from "../../interfaces";
import { Edit } from "../../components/crud/edit";
import { defaultFormLayout } from "../../constants";
import { useTitleData } from "../../contexts/title";
import { UserPermissions } from "../../interfaces/userPermissions";
import { BaseRecord } from "@refinedev/core";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { mutate } = useUpdate();

    const { data: permissionsData } = usePermissions<UserPermissions>();

    const permissions = permissionsData;

    const canEditRoles = !!(permissions && permissions.RoleManagementPermitted && permissions.RoleManagementPermitted === 'true');

    const saveUserRoles = (newData: BaseRecord, variables: BaseRecord) => {
        if (canEditRoles) {
            mutate({
                resource: 'roles',
                values: {
                    roleName: variables.userRoles,
                },
                id: variables.id!,
                meta: {
                    form: true,
                    method: 'post',
                    endpoint: `user/${queryResult?.data?.data.id}/roles/replace`,
                }
            })
        }
    }

    const { formProps, saveButtonProps, queryResult } = useForm<User>({
        resource: "user",
        onMutationSuccess: saveUserRoles
    });
    useTitleData({ ...formProps.initialValues });

    const docTypeOptions = getEnumOptions(DocIdTypeEnum, 'DocIdTypeEnum', translate, [DocIdTypeEnum.Unknown]);
    const taxTypeOptions = getEnumOptions(TaxIdTypeEnum, 'TaxIdTypeEnum', translate, [TaxIdTypeEnum.Unknown]);
    const rolesOptions = getEnumOptions(UserRole, 'UserRole', translate, []);
    const statusOptions = getEnumOptions(UserStatus, 'UserStatus', translate, [UserStatus.Unknown]);
    const freqOptions = getEnumOptions(StatementFrequency, 'StatementFrequency', translate);


    const { selectProps } = useSelect({ resource: 'userGroup', optionLabel: 'name' });

    const { data } = useList({
        resource: 'userRoles', meta: { endpoint: `user/${queryResult?.data?.data.id}/roles` }, queryOptions: {
            enabled: !!queryResult?.data && canEditRoles
        }
    });

    useEffect(() => {
        if (formProps?.initialValues && data?.data) {
            formProps.form?.setFieldValue('userRoles', data.data);
        }
    }, [data?.data, queryResult?.data]);

    return (
        <Edit saveButtonProps={saveButtonProps} title={translate(`user.titles.edit`, { ...formProps.initialValues })}>
            <Form
                {...formProps}
                {...defaultFormLayout}
            >
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                        <Card title={translate("user.fields.basic")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("user.fields.userName")}
                                name={["userName"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.status")}
                                name={["status"]}
                            >
                                <Select options={statusOptions} />
                            </Form.Item>

                            <Form.Item
                                label={translate("user.fields.stmtFreq")}
                                name={["stmtFreq"]}
                            >
                                <Select options={freqOptions} />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.culture")}
                                name={["culture"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.timezone")}
                                name={["timezone"]}
                            >
                                <Input />
                            </Form.Item>

                        </Card>
                    </Col>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <Card title={translate("user.fields.contact")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("user.fields.email")}
                                name={["email"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                valuePropName="checked"
                                name={["emailConfirmed"]}
                            >
                                <Checkbox>{translate("user.fields.emailConfirmed")}</Checkbox>
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.phoneNumber")}
                                name={["phoneNumber"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                valuePropName="checked"
                                name={["phoneNumberConfirmed"]}
                            >
                                <Checkbox>{translate("user.fields.phoneNumberConfirmed")}</Checkbox>
                            </Form.Item>
                            <Form.Item
                                valuePropName="checked"
                                name={["twoFactorEnabled"]}
                            >
                                <Checkbox>{translate("user.fields.twoFactorEnabled")}</Checkbox>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 6 }} lg={{ span: 6 }}>
                        <Card title={translate("user.fields.address")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("user.fields.country")}
                                name={["country"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.zip")}
                                name={["zip"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.state")}
                                name={["state"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.city")}
                                name={["city"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.streetAddress1")}
                                name={["streetAddress1"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.streetAddress2")}
                                name={["streetAddress2"]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                        <Card title={translate("user.fields.personal")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("user.fields.nameFirst")}
                                name={["nameFirst"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.nameMiddle")}
                                name={["nameMiddle"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.nameLast")}
                                name={["nameLast"]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                        <Card title={translate("user.fields.docs")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("user.fields.docId")}
                                name={["docId"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.docIdType")}
                                name={["docIdType"]}
                            >
                                <Select options={docTypeOptions} allowClear={true} />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.docIdAuthority")}
                                name={["docIdAuthority"]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col xs={{ span: 5, offset: 1 }} lg={{ span: 5, offset: 1 }}>
                        <Card title={translate("user.fields.taxes")} className="mb-24" size="small">
                            <Form.Item
                                label={translate("user.fields.taxId")}
                                name={["taxId"]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.taxIdType")}
                                name={["taxIdType"]}
                            >
                                <Select options={taxTypeOptions} allowClear={true} />
                            </Form.Item>
                            <Form.Item
                                label={translate("user.fields.taxIdAuthority")}
                                name={["taxIdAuthority"]}
                            >
                                <Input />
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 12 }} lg={{ span: 12 }}>
                        <Form.Item
                            label={translate("user.fields.userGroup")}
                            name={["userGroupId"]}
                        >
                            <Select {...selectProps} />
                        </Form.Item>


                        {canEditRoles && <Form.Item
                            label={translate("user.fields.userRoles")}
                            name={["userRoles"]}
                        >
                            <Select options={rolesOptions} />
                        </Form.Item>}

                        <Form.Item
                            valuePropName="checked"
                            name={["lockoutEnabled"]}
                        >
                            <Checkbox>{translate("user.fields.lockoutEnabled")}</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <Form.Item
                            label={translate("user.fields.lockoutEnd")}
                            name={["lockoutEnd"]}
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : undefined,
                            })}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label={translate("user.fields.accessFailedCount")}
                            name={["accessFailedCount"]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};
