import { DeleteButton, EditButton, ShowButton, useTable } from "@refinedev/antd";
import {
    BaseRecord,
    IResourceComponentsProps,
    useTranslate,
} from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";
import { List } from "../../components/crud/list";
import { NumberField } from "../../components/fields";
import { EnumField } from "../../components/fields/EnumField";
import { useTableClick } from "../../hooks/useTableClick";
import { MarginPolicyCloseoutOptions, MarginPolicyCloseoutStrategy, MarginPolicyWorkflowType } from "../../interfaces";
import { getEnumFilter } from "../../utils";

export const MarginCallPolicyList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { tableProps } = useTable({
        syncWithLocation: true,
    });
    const clickTableProps = useTableClick({ resource: 'marginCallPolicy' });
    const optionsFilter = getEnumFilter(MarginPolicyCloseoutOptions, 'MarginPolicyCloseoutOptions', translate, [MarginPolicyCloseoutOptions.Unknown]);
    const strategyFilter = getEnumFilter(MarginPolicyCloseoutStrategy, 'MarginPolicyCloseoutStrategy', translate, [MarginPolicyCloseoutStrategy.Unknown]);
    const typeFilter = getEnumFilter(MarginPolicyWorkflowType, 'MarginPolicyWorkflowType', translate, [MarginPolicyWorkflowType.Unknown]);

    return (
        <List>
            <Table {...tableProps} rowKey="id" {...clickTableProps}>
                <Table.Column
                    dataIndex="description"
                    title={translate("marginCallPolicy.fields.description")}
                />
                <Table.Column
                    dataIndex="levelWarning"
                    sorter={true}
                    title={translate("marginCallPolicy.fields.levelWarning")}
                    render={(value: any) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="levelCritical"
                    sorter={true}
                    title={translate("marginCallPolicy.fields.levelCritical")}
                    render={(value: any) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="levelMC"
                    sorter={true}
                    title={translate("marginCallPolicy.fields.levelMC")}
                    render={(value: any) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="autoCloseoutDelay"
                    sorter={true}
                    title={translate(
                        "marginCallPolicy.fields.autoCloseoutDelay",
                    )}
                    render={(value: any) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="closeoutOptions"
                    title={translate("marginCallPolicy.fields.closeoutOptions")}
                    filters={optionsFilter}
                    render={(value: MarginPolicyCloseoutOptions) => <EnumField enum='MarginPolicyCloseoutOptions' translate={translate} value={value} />}
                />
                <Table.Column
                    dataIndex="closeoutStrategy"
                    filters={strategyFilter}
                    title={translate(
                        "marginCallPolicy.fields.closeoutStrategy",
                    )}
                    render={(value: MarginPolicyCloseoutStrategy) => <EnumField enum='MarginPolicyCloseoutStrategy' translate={translate} value={value} />}
                />
                <Table.Column
                    dataIndex="workflowType"
                    filters={typeFilter}
                    title={translate("marginCallPolicy.fields.workflowType")}
                    render={(value: MarginPolicyWorkflowType) => <EnumField enum='MarginPolicyWorkflowType' translate={translate} value={value} />}
                />
                <Table.Column
                    align="right"
                    width={130}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
