import { CrudFilter, CrudFilters } from "@refinedev/core";
import { mapOperator } from "./mapOperator";

export const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};

    if (filters) {
        filters.map((filter: CrudFilter) => {
            if (filter.operator === "or" || filter.operator === "and") {
                throw new Error(
                    `[@refinedev/simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`,
                );
            }

            if (filter.operator == 'between' && filter.value && filter.value.length) {
                const { field, value } = filter;
                if (value[0]) {
                    queryFilters[`${field}GTE`] = value[0];
                }
                if (value[1]) {
                    queryFilters[`${field}LTE`] = value[1];
                }
            } else if ("field" in filter) {
                const { field, operator, value } = filter;

                if (field === "q") {
                    queryFilters[field] = value;
                    return;
                }

                if (field.endsWith('BITWISEANDANY')) {
                    queryFilters[`${field}`] = value.reduce((prev: number, val: string) => {
                        return prev | parseInt(val);
                    }, 0);
                    return;
                }

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};
