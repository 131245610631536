import { EditButton, ShowButton, useModalForm } from "@refinedev/antd";
import {
    BaseRecord,
    useTranslate
} from "@refinedev/core";
import { Modal, Space, Table, TableProps, Tooltip } from "antd";
import { ColumnFilterItem } from "antd/lib/table/interface";
import React, { useMemo } from "react";
import { ClosePositionButton } from '../../components/buttons/closePosition';
import { DateField, EnumField, LogoPairField, NumberField, UnitValueField } from "../../components/fields";
import { AccountField } from "../../components/fields/AccountField";
import { useReferenceData } from "../../hooks/useReferenceFilter";
import { useTableClick } from '../../hooks/useTableClick';
import { Account, ExecutionMethod, Position, PositionSide, PositionStatus } from "../../interfaces";
import { getEnumFilter } from "../../utils";
import { PositionForm } from './form';
import { DollarOutlined } from "@ant-design/icons";

interface PositionsTableProps {
    internal: boolean;
    withIdentity: boolean;
    tableProps: TableProps;
    resource: string;
}

export const OpenPositionsTable: React.FC<PositionsTableProps> = ({ internal, withIdentity, tableProps, resource }) => {
    const translate = useTranslate();

    const sideFilter = getEnumFilter(PositionSide, 'PositionSide', translate, ["Unknown"]);
    const statusFilter = getEnumFilter(PositionStatus, 'PositionStatus', translate, ["Unknown"]);
    const methodFilter = getEnumFilter(ExecutionMethod, 'ExecutionMethod', translate, ["Unknown"]);

    const pairFilters = useMemo(() => {
        return tableProps.dataSource?.map((item: Position) => { return { text: item.pairId, value: item.pairId } as ColumnFilterItem })
    }, [tableProps.dataSource]);

    const accountIds = tableProps.dataSource?.map((item: Position) => item.accountId) || [];

    const accountsHash = useReferenceData({ resource: 'account', ids: tableProps.dataSource?.map((item: Position) => item.accountId) });
    const pairsHash = useReferenceData({ resource: 'pair/info', ids: tableProps.dataSource?.map((item: Position) => item.pairId) });
    const accountAssetHash = useReferenceData({ resource: 'asset', ids: accountIds.map((accountId: number) => accountsHash.get(accountId)?.denominatedInAssetId) });

    const getRowClassName = (record: Position) => {
        return 'default-action-show clickable-row position-' + (record.side === PositionSide.Long ? 'long' : 'short');
    }

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<Position>({
        resource: "open_position",
        action: "edit",
        warnWhenUnsavedChanges: true,
        redirect: false,
        invalidates: ["list", "detail"]
    });
    const clickTableProps = useTableClick({ resource: resource, action: 'show' });

    const accountUnit = (record: Position) => {
        const assetId = record.accountId ? accountsHash.get(record.accountId)?.denominatedInAssetId : undefined;
        return assetId ? accountAssetHash.get(assetId)?.symbol : undefined;
    }

    const accountDigits = (record: Position) => {
        const assetId = record.accountId ? accountsHash.get(record.accountId)?.denominatedInAssetId : 2;
        return assetId ? accountAssetHash.get(assetId)?.displayDecimals : 2;
    }

    return <>
        <Table
            {...tableProps}
            rowKey="id"
            className="wrap-normal"
            {...clickTableProps}
            rowClassName={(record: Position) => getRowClassName(record)}
        >
            <Table.Column
                dataIndex="token"
                sorter={(a: Position, b: Position) => a.token.localeCompare(b.token)}
                title={translate("position.fields.token")}
            />
            <Table.Column
                dataIndex="status"
                filters={statusFilter}
                title={translate("position.fields.status")}
                render={(value: PositionStatus) => <EnumField value={value} enum="PositionStatus" translate={translate} />}
            />
            {!internal && <Table.Column
                dataIndex={["pairId"]}
                title={translate("position.fields.pair")}
                render={(value) => <LogoPairField pairId={value} />}
                filters={pairFilters}
                sorter={(a: Position, b: Position) => a.pairId.localeCompare(b.pairId)}
                onFilter={(value, record: Position) => record.pairId === value}
            />}
            <Table.Column
                align="end"
                dataIndex="notional"
                sorter={(a: Position, b: Position) => a.notional > b.notional ? 1 : -1}
                title={translate("position.fields.notional")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={pairsHash.get(record.pairId)?.baseSymbol} />}
            />
            {!internal && <Table.Column
                dataIndex="side"
                title={translate("position.fields.side")}
                filters={sideFilter}
                onFilter={(value, record: Position) => record.side === value}
            />}
            <Table.Column
                align="end"
                dataIndex="currentMargin"
                sorter={(a: Position, b: Position) => a.currentMargin > b.currentMargin ? 1 : -1}
                title={translate("position.fields.currentMargin")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountUnit(record)} digits={accountDigits(record)} />}
            />
            <Table.Column
                align="end"
                dataIndex="marginRatio"
                title={translate("position.fields.marginRatio")}
                render={(value: number) => <UnitValueField value={value} unit={value == 1 ? <Tooltip title={translate("position.fields.cash")}><DollarOutlined /></Tooltip> : undefined} />}
            />
            <Table.Column
                align="end"
                dataIndex="currentValue"
                sorter={(a: Position, b: Position) => a.currentValue > b.currentValue ? 1 : -1}
                title={translate("position.fields.currentValue")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountUnit(record)} digits={accountDigits(record)} />}
            />
            <Table.Column
                align="end"
                dataIndex="unrealizedPnL"
                sorter={(a: Position, b: Position) => a.unrealizedPnL > b.unrealizedPnL ? 1 : -1}
                title={translate("position.fields.unrealizedPnL")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountUnit(record)} digits={accountDigits(record)} />}
            />
            <Table.Column
                align="end"
                dataIndex="entranceCost"
                sorter={(a: Position, b: Position) => a.entranceCost > b.entranceCost ? 1 : -1}
                title={translate("position.fields.entranceCost")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountUnit(record)} digits={accountDigits(record)} />}
            />
            <Table.Column
                align="end"
                dataIndex="mae"
                sorter={(a: Position, b: Position) => a.mae > b.mae ? 1 : -1}
                title={translate("position.fields.mae")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountUnit(record)} digits={accountDigits(record)} />}
            />
            <Table.Column
                align="end"
                dataIndex="mfe"
                sorter={(a: Position, b: Position) => a.mfe > b.mfe ? 1 : -1}
                title={translate("position.fields.mfe")}
                render={(value: number, record: Position) => <UnitValueField value={value} unit={accountUnit(record)} digits={accountDigits(record)} />}
            />
            {withIdentity && <Table.Column
                dataIndex={["accountId"]}
                title={translate("position.fields.account")}
                render={(value) => <AccountField value={accountsHash.get(value) as Account} showIdentity={false} link="open_positions" />}
            />}
            <Table.Column
                align="end"
                dataIndex="method"
                filters={methodFilter}
                onFilter={(value, record: Position) => record.method === value}
                sorter={(a: Position, b: Position) => a.method > b.method ? 1 : -1}
                title={translate("position.fields.method")}
                render={(value: number) => <EnumField value={value} enum="ExecutionMethod" translate={translate} />}
            />
            <Table.Column
                align="end"
                dataIndex="connectorId"
                sorter={(a: Position, b: Position) => a.connectorId > b.connectorId ? 1 : -1}
                title={translate("position.fields.connectorId")}
            />
            <Table.Column
                dataIndex={["createdAt"]}
                title={translate("position.fields.createdAt")}
                sorter={(a: Position, b: Position) => a.createdAt > b.createdAt ? 1 : -1}
                render={(value: string) => <DateField value={value} format="YYYY-MM-DD HH:mm" />}
            />
            <Table.Column
                align="end"
                dataIndex="actions"
                render={(_, record: BaseRecord) => (
                    <Space>
                        <EditButton
                            hideText
                            size="small"
                            resource="position"
                            recordItemId={record.id}
                            onClick={() => {
                                editModalShow(record.id);
                            }}
                        />
                        <ShowButton
                            hideText
                            size="small"
                            resource="position"
                            recordItemId={record.id}
                        />
                        <ClosePositionButton
                            hideText
                            size="small"
                            resource={resource}
                            ids={[record.id]}
                            confirmOkText={translate("management_margin.buttons.confirm_cancel")}
                        />
                    </Space>
                )}
            />
        </Table>
        <Modal {...editModalProps} width={400} >
            <PositionForm formProps={editFormProps} />
        </Modal>
    </>
};
