import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { useForm } from "@refinedev/antd";
import { UserGroup } from "../../interfaces";
import { UserGroupsForm } from "./form";
import { Edit } from "../../components/crud/edit";

export const UserGroupEdit: React.FC<IResourceComponentsProps> = () => {
    
    const { formProps, saveButtonProps } = useForm<UserGroup>();
    
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <UserGroupsForm formProps={formProps}></UserGroupsForm>
        </Edit>
    );
};
